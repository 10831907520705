import React, { useContext, useEffect, useState } from "react";
import translate from "../../i18n/translate";
import Button from "../general/Button";
import addDark from "../../assets/addark.svg";
import add from "../../assets/add.svg";
import { Box, Fade, Modal, Typography } from "@mui/material";
import "../../components/css/Modal.scoped.css";
import CloseDark from "../../assets/closedark.svg";
import Close from "../../assets/close.svg";
import Input from "../general/Input";
import GetTranslateString from "../../i18n/translateString";
import SelectLabels from "../general/Select";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import TicketChecks from "./TicketChecks";
import AddTicketDetailsGrid from "./AddTicketDetailsGrid";
import Loader from "../general/Loader";
import useMultiPriceInput from "../../functions/utils/useMultiPriceInput";
import addNewTicketItem from "../../functions/tickets/addNewTicketItem";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import infoSvg from "../../assets/info.svg";
import Tooltip from "@mui/material/Tooltip";
import { CURRENCY_CODES, TicketSplittingOptions } from "../../Constants";
import getEnvValue from "../utils/getEnvValue";
import { ReactSVG } from "react-svg";
import { CurrencyContext } from "../../context/CurrencyContext";

const initialValues = {
  ticketType: "",
  quantity: "",
  splittingOption: "",
  seatType: "",
  section: "",
  row: "",
  retailPrice: "",
  startSeat: "",
};

export default function AddTicketModal({
  dark,
  toggleSearchModal,
  isOpen,
  toggleState,
  searchData,
}) {
  const style = {
    position: "absolute",
    top: "2rem",
    left: "50%",
    transform: "translate(-50%, 0%)",
    maxWidth: "64.125rem",
    overflow: "auto",
    maxHeight: "52.125rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.875rem",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [data, setData] = useState(initialValues);
  const [standingState, setStandingState] = useState(false);

  const [ticketDetailsData, setTicketDetailsData] = useState([]);

  const {
    platformPrice,
    payoutPrice,
    onChangePlatformPrice,
    onChangePayoutPrice,
  } = useMultiPriceInput(
    (data?.price || 0).toFixed(2),
    Number(getEnvValue("REACT_APP_SELLER_FEES")) / 100
  );

  const [ticketRestriction, setTicketRestriction] = useState([
    {
      label: "Concession ticket - child",
      value: false,
    },
    {
      label: "Concession ticket - student",
      value: false,
    },
    {
      label: "Concession ticket - senior citizen",
      value: false,
    },
    {
      label: "Wheelchair user only",
      value: false,
    },
    {
      label: "Under 21 Ticket",
      value: false,
    },
    {
      label: "Under 18 Ticket",
      value: false,
    },
    {
      label: "Over 18 Ticket",
      value: false,
    },
    {
      label: "No Under 14s",
      value: false,
    },
    {
      label: "Original Purchaser’s ID must be shown",
      value: false,
    },
    {
      label: "Meet up with seller",
      value: false,
    },
    {
      label: "Resale not allowed",
      value: false,
    },
    {
      label: "Original Purchaser’s ID must be shown",
      value: false,
    },
    {
      label: "Under 15s accompanied by an adult",
      value: false,
    },
  ]);

  const [ticketLimitation, setTicketLimitation] = useState([
    {
      label: "Limited or restricted view",
      value: false,
    },
    {
      label: "Ticket and meal package",
      value: false,
    },
    {
      label: "Aisle seat",
      value: false,
    },
    {
      label: "Include VIP pass",
      value: false,
    },
    {
      label: "Includes parking",
      value: false,
    },
    {
      label: "Side or rear view",
      value: false,
    },
    {
      label: "Alcohol free area",
      value: false,
    },
    {
      label: "Standing  Only",
      value: false,
    },
    {
      label: "Restricted legroom",
      value: false,
    },
    {
      label: "1 Night at 4 Star Hotel",
      value: false,
    },
    {
      label: "Restricted legroom",
      value: false,
    },
    {
      label: "1 Night at 3 Star Hotel",
      value: false,
    },
    {
      label: "1 Night at 4 Star Hotel",
      value: false,
    },
    {
      label: "1 Night at 5 Star Hotel",
      value: false,
    },
    {
      label: "2 Night at 3 Star Hotel",
      value: false,
    },
    {
      label: "2 Night at 4 Star Hotel",
      value: false,
    },
    {
      label: "2 Night at 5 Star Hotel",
      value: false,
    },
    {
      label: "Access to VIP Lounge",
      value: false,
    },
  ]);

  const [uploads, setUploads] = useState([{ file: "", path: "" }]);

  let token = localStorage.getItem("token");

  const [loading, toggleLoading] = useState(false);

  // Max quantity
  const max = 20;

  const handleClose = () => {
    toggleState(false);
    toggleLoading(false);
    setUploads([{ file: "", path: "" }]);
    setData({
      ...initialValues,
    });
    setTicketDetailsData([]);
  };

  const handleChange = (e) => {
    if (e.target.name === "quantity") {
      setData({
        ...data,
        [e.target.name]:
          e.target.value && e.target.value > 0
            ? parseInt(e.target.value)
            : null,
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "seatType") {
        if (e.target.value === "Standing") {
          setStandingState(true);
        } else {
          setStandingState(false);
        }
      }
    }
  };

  const nonSelectedColor = "#ECEDF2";
  const selectedColor = "#767BFA";

  const handleSectionSelect = (section) => {
    if (data.section !== section) {
      setData({ ...data, section });
    }
  };

  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  return (
    <div className="addTicketModal">
      <Button
        onClick={() => toggleSearchModal(true)}
        text={translate("Add New")}
        imgSrc={<img src={dark ? addDark : add} alt="import icon" />}
        customClass={"h-40"}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className={"addTicketModal"}>
              <div className={"header"}>
                {searchData?.name}
                <img
                  onClick={handleClose}
                  src={dark ? CloseDark : Close}
                  alt=""
                />
              </div>
              <div className="subText">
                {new Date(searchData?.dateStart).toLocaleString("en-US", {
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </div>
              <div className="smText">
                {searchData?.venueName}, {searchData?.venueLocation}
              </div>
              <div className="twoGrid">
                {/* <ReactSVG
                  afterInjection={(svg) => {
                    if (data.section) {
                      let selectedSection = document.getElementById(
                        data.section
                      );
                      if (selectedSection) {
                        selectedSection.setAttribute("fill", selectedColor);
                      }
                    }
                  }}
                  renumerateIRIElements={false}
                  src={searchData?.venueImg}
                  className="mapImg"
                  onClick={(e) => {
                    console.log(e);
                    if (searchData?.categories.includes(e.target.id)) {
                      handleSectionSelect(e.target.id);
                    }
                  }}
                  loading={() => (
                    <div>
                      <Loader loading={true} />
                    </div>
                  )}
                  onError={(error) => {
                    console.error(error);
                  }}
                /> */}
                {/* {searchData?.venueImg} */}
                <img className="mapImg" src={searchData?.venueImg} />
                <div>
                  <div className="twoGrid">
                    <div>
                      <div className="label">
                        {translate("Ticket Type")}
                        {/* <Tooltip placement="top" title="123">
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip> */}
                      </div>
                      <div>
                        <SelectLabels
                          dark={dark}
                          name="ticketType"
                          value={data.ticketType}
                          updateValue={handleChange}
                          options={[
                            {
                              label: "E-Ticket",
                              value: "E-Ticket",
                            },
                          ]}
                          placeholderText={GetTranslateString(
                            "Select ticket type"
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="label">
                        {translate("Quantity")}
                        {/* <Tooltip placement="top" title="123">
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip> */}
                      </div>
                      <Input
                        name={"quantity"}
                        dark={dark}
                        type="number"
                        value={data.quantity}
                        onKeyDown={preventEAndSymbols}
                        updateValue={handleChange}
                        label={translate("Quantity")}
                        placeholder={GetTranslateString("Enter quantity")}
                      />
                    </div>
                  </div>
                  <div className="twoGrid">
                    <div>
                      <div className="label">
                        {translate("Ticket Separation")}
                        <Tooltip
                          placement="top"
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                <p style={{ fontSize: "0.6875rem" }}>
                                  Any -{" "}
                                  <span style={{ fontWeight: 300 }}>
                                    This option means that any number of tickets
                                    can be purchased, regardless of whether it
                                    leaves an odd number or avoids leaving a
                                    certain number of tickets.
                                  </span>
                                  <br />
                                  <br />
                                  None -{" "}
                                  <span
                                    style={{
                                      fontWeight: 300,
                                    }}
                                  >
                                    This option means that tickets must be
                                    purchased as a whole
                                  </span>
                                  <br />
                                  <br />
                                  Avoid leaving 1 ticket -{" "}
                                  <span
                                    style={{
                                      fontWeight: 300,
                                    }}
                                  >
                                    This option means that tickets can be
                                    purchased in any quantity, but the purchase
                                    must not leave only one ticket remaining.
                                  </span>
                                  <br />
                                  <br />
                                  Avoid leaving 1 or 3 tickets -{" "}
                                  <span
                                    style={{
                                      fontWeight: 300,
                                    }}
                                  >
                                    This option means that tickets can be
                                    purchased in any quantity, but the purchase
                                    must not leave only one or three tickets
                                    remaining.
                                  </span>
                                  <br />
                                  <br />
                                  Avoid leaving odd number -{" "}
                                  <span
                                    style={{
                                      fontWeight: 300,
                                    }}
                                  >
                                    This option means that tickets can be
                                    purchased in any quantity, but the purchase
                                    must not leave an odd number of tickets
                                    remaining.
                                  </span>
                                </p>
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip>
                      </div>
                      <div>
                        <SelectLabels
                          dark={dark}
                          name="splittingOption"
                          value={data.splittingOption}
                          updateValue={handleChange}
                          options={Object.keys(TicketSplittingOptions).map(
                            (e) => ({
                              label: TicketSplittingOptions[e],
                              value: e,
                            })
                          )}
                          placeholderText={GetTranslateString(
                            "Select separation"
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="label">
                        {translate("Standing/Seated")}
                        <Tooltip
                          placement="top"
                          title="Select standing for attractions, clubs, food, sports and wellness"
                        >
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip>
                      </div>
                      <div>
                        <SelectLabels
                          dark={dark}
                          name="seatType"
                          value={data.seatType}
                          updateValue={handleChange}
                          options={[
                            {
                              label: "Standing",
                              value: "Standing",
                            },
                            {
                              label: "Seated",
                              value: "Seated",
                            },
                          ]}
                          placeholderText={GetTranslateString(
                            "Standing/Seated"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="twoGrid">
                    <div>
                      <div className="label">
                        {translate("Section")}
                        {/* <Tooltip placement="top" title="123">
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip> */}
                      </div>
                      <div>
                        <SelectLabels
                          dark={dark}
                          name="section"
                          value={data.section}
                          updateValue={(e) =>
                            handleSectionSelect(e.target.value)
                          }
                          options={searchData?.categories.map((cat) => ({
                            label: cat,
                            value: cat,
                          }))}
                          placeholderText={GetTranslateString("Select section")}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="label"
                        style={{ opacity: standingState ? 0.6 : 1 }}
                      >
                        {translate("Row")}
                        <Tooltip
                          placement="top"
                          title="If you selected standing, please put either General Admission, VIP Access or - "
                        >
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip>
                      </div>
                      <Input
                        name="row"
                        type={"text"}
                        readOnly={standingState}
                        dark={dark}
                        customClass={standingState ? "shadow-offset" : ""}
                        value={standingState ? "-" : data.row}
                        updateValue={handleChange}
                        placeholder={GetTranslateString("Enter row")}
                      />
                    </div>
                  </div>
                  <div className="twoGrid">
                    <div>
                      <div className="label">
                        {translate("Retail Price")}
                        <Tooltip
                          placement="top"
                          title="Enter the price you paid for the ticket (including booking fee)"
                        >
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip>
                      </div>
                      <Input
                        suffix={CURRENCY_CODES[(searchData?.country)] ?? "SGD"}
                        name="retailPrice"
                        type={"number"}
                        dark={dark}
                        value={data.retailPrice}
                        onKeyDown={preventEAndSymbols}
                        updateValue={handleChange}
                        placeholder={GetTranslateString("Enter retail price")}
                      />
                    </div>
                    <div>
                      <div
                        className="label"
                        style={{ opacity: standingState ? 0.6 : 1 }}
                      >
                        {translate("Starting Seat/Queue No.")}
                        {/* <Tooltip placement="top" title="123">
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip> */}
                      </div>
                      <Input
                        name="startSeat"
                        type={standingState ? "text" : "number"}
                        dark={dark}
                        readOnly={standingState}
                        customClass={standingState ? "shadow-offset" : ""}
                        onKeyDown={preventEAndSymbols}
                        value={standingState ? "-" : data.startSeat}
                        updateValue={handleChange}
                        placeholder={GetTranslateString(
                          "Enter starting seat/queue no."
                        )}
                      />
                    </div>
                  </div>
                  <div className="twoGrid">
                    <div>
                      <div className="label">
                        {translate("Platform Price (SGD)")}
                        <Tooltip
                          placement="top"
                          title="The price of ticket listed on the platform"
                        >
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip>
                      </div>
                      <Input
                        name="platformPrice"
                        type={"text"}
                        dark={dark}
                        value={platformPrice}
                        updateValue={onChangePlatformPrice}
                        placeholder={GetTranslateString("enter platform price")}
                      />
                    </div>
                    <div>
                      <div className="label">
                        {translate("Payout (SGD)")}
                        <Tooltip
                          placement="top"
                          title="The price you will get back once your ticket is sold"
                        >
                          <span>
                            <img src={infoSvg} />
                          </span>
                        </Tooltip>
                      </div>
                      <Input
                        name="payoutPrice"
                        type={"text"}
                        dark={dark}
                        value={payoutPrice}
                        updateValue={onChangePayoutPrice}
                        placeholder={GetTranslateString("Enter payout price")}
                      />
                    </div>
                  </div>

                  <div className="flex jc-fe al-ce mt-20">
                    <div>
                      <Loader loading={loading} />
                    </div>
                    <Button
                      customClass={"mr-14"}
                      imgSrc={<img src={dark ? DeleteDark : Delete} alt="" />}
                      text={"Clear All"}
                      onClick={() => {
                        setData({
                          ticketType: "",
                          quantity: "",
                          splittingOption: "",
                          seatType: "",
                          section: "",
                          row: "",
                          retailPrice: "",
                          startSeat: "",
                          currency: "USD",
                        });
                        onChangePayoutPrice({ target: { value: "" } });
                        onChangePlatformPrice({ target: { value: "" } });
                        setTicketDetailsData([]);
                        setUploads([{ file: "", path: "" }]);
                      }}
                    />
                    <Button
                      imgSrc={<img src={dark ? AddDark : Add} alt="" />}
                      text={translate("Confirm")}
                      onClick={() => {
                        try {
                          if (!data.ticketType) {
                            throw new Error("Please select a ticket type");
                          }
                          if (!data.quantity) {
                            throw new Error("Please provide a quantity");
                          }
                          if (isNaN(data.quantity) || data.quantity < 1) {
                            throw new Error("provided quantity is not valid");
                          }
                          if (data.quantity > 20) {
                            throw new Error("Max quantity allowed is 20");
                          }
                          if (!data.splittingOption) {
                            throw new Error(
                              "Please choose a ticket separation"
                            );
                          }
                          if (!data.startSeat && !standingState) {
                            throw new Error(
                              "Please provide a starting seat number"
                            );
                          }
                          if (data.startSeat < 0) {
                            throw new Error("Starting seat cannot be negative");
                          }
                          if (!data.section) {
                            throw new Error("Section not selected");
                          }
                          if (!data.row && !standingState) {
                            throw new Error("Row not provided");
                          }
                          if (!data.seatType) {
                            throw new Error("Please select a seat type");
                          }
                          if (!data.retailPrice) {
                            throw new Error("Please provide a retail price");
                          }
                          const temp = Array(parseInt(data.quantity)).fill({
                            ticketType: data.ticketType,
                            splittingOption: data.splittingOption,
                            seatType: data.seatType,
                            section: data.section,
                            row: standingState ? "-" : data.row,
                            retailPrice: data.retailPrice,
                            startSeat: standingState ? 1 : data.startSeat,
                            platformPrice: payoutPrice,
                            payoutPrice: platformPrice,
                          });
                          setUploads([{ file: "", path: "" }]);
                          setTicketDetailsData([...temp]);
                        } catch (e) {
                          ErrorToast("Error", e.message);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <AddTicketDetailsGrid
                dark={dark}
                data={ticketDetailsData}
                uploads={uploads}
                setUploads={setUploads}
                max={max}
                loading={loading}
                toggleLoading={toggleLoading}
              />
              <div className="smHeader">Select Restrictions on Use</div>
              <div className="smText">
                If any of the following conditions apply to your tickets, please
                select them from the list below. If there is a restriction on
                the use of your ticket not shown here, please stop listing and
                contact us.
              </div>
              <TicketChecks
                dark={dark}
                data={ticketRestriction}
                setData={setTicketRestriction}
              />
              <div className="smHeader">Listing Notes</div>
              <TicketChecks
                dark={dark}
                data={ticketLimitation}
                setData={setTicketLimitation}
              />
              <div className="btn-container">
                <div>
                  <Loader loading={loading} />
                </div>
                <Button
                  text={translate("Add Batch")}
                  imgSrc={<img src={dark ? addDark : add} alt="import icon" />}
                  onClick={async () => {
                    try {
                      if (!loading) {
                        toggleLoading(true);
                        await addNewTicketItem(token, {
                          showId: searchData.id,
                          category: data.section,

                          purchasedPrice: Number(
                            (
                              data.retailPrice /
                              rates?.[
                                CURRENCY_CODES[(searchData?.country)] ?? "SGD"
                              ]
                            ).toFixed(2)
                          ),
                          purchasedDate: new Date(),
                          price: Number(parseFloat(platformPrice).toFixed(2)),
                          quantity: parseInt(data.quantity),
                          splittingOption: data.splittingOption,
                          uploadedTickets: uploads,
                          seatNumbers: standingState
                            ? Array.from(
                                Array(parseInt(data.quantity)).keys()
                              ).map((e, i) => (parseInt(1) + i).toString())
                            : Array.from(
                                Array(parseInt(data.quantity)).keys()
                              ).map((e, i) =>
                                (parseInt(data.startSeat) + i).toString()
                              ),
                          row: standingState ? "-" : data.row,
                          section: data.seatType,
                          ticketType: data.ticketType,
                          restrictions: ticketRestriction
                            .filter((r) => r.value)
                            .map((r) => r.label),
                          notes: ticketLimitation
                            .filter((r) => r.value)
                            .map((r) => r.label),
                        });

                        handleClose();
                        SuccessToast("Success", "New ticket item added");
                      }
                    } catch (e) {
                      console.log(e);
                      if (e.name === "AxiosError") {
                        ErrorToast("Error", e?.response?.data.error);
                      } else {
                        ErrorToast("Error", e?.message);
                      }
                    } finally {
                      toggleLoading(false);
                    }
                  }}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

function preventEAndSymbols(event) {
  const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const key = event.key;

  if (!allowedKeys.includes(key) && key !== "Backspace") {
    event.preventDefault();
  }

  // Prevent 'e' character in scientific notation
  if (key.toLowerCase() === "e" && event.target.value.includes("e")) {
    event.preventDefault();
  }
}
