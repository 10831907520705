import { collection, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import "../css/Graph.scoped.css";
import "../css/ProductInfo.scoped.css";
import translate from "../../i18n/translate";
const TransactionHistory = () => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  const [data, setData] = useState([]);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "SoldItems"),
      async (snapShot) => {
        const conData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
        setData(conData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  return (
    <>
      <div className="flex al-ce jc-sp priceheader transaction-history">
        <div className="headersection">
          <div className="smtext">{translate("transactionhistory")}</div>
        </div>
      </div>
      <div>
        <div className="headersgrid">
          <div />
          <div>{translate("items")}</div>
          <div>{translate("price")}</div>
          <div>{translate("profitsLosses")}</div>
        </div>
        <div className="items">
          {data.map((e, i) => (
            <div key={i} className="item">
              <div>
                <img src={e.image} alt="item" />
              </div>
              <div>{e.name}</div>
              <div>
                {sign[localCurrency]}
                {parseFloat(
                  (e.costPrice * rates?.[localCurrency?.toUpperCase()]).toFixed(
                    2
                  )
                )}
              </div>
              <div
                className={`${e.soldPrice - e.costPrice > 0 ? "green" : "red"}`}
              >
                {sign[localCurrency]}
                {parseFloat(
                  (
                    (e.soldPrice - e.costPrice) *
                    rates?.[localCurrency?.toUpperCase()]
                  ).toFixed(2)
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
