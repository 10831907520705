import React from "react";
import "../css/Wallet.scoped.css";
import { format } from "date-fns";
import { Tooltip } from "@mui/material";
import copy from "../../assets/copy.svg";

export default function RecentActivities({ recentActivities }) {
  return (
    <div className="recentActivities">
      <div className="headersgrid">
        <div>Purchase ID</div>
        <div>Event</div>
        <div>Date</div>
        <div className="center">Section</div>
        <div className="center">Row</div>
        <div className="center">Seat</div>
        <div className="center">Payout</div>
      </div>
      <div className="items">
        {recentActivities.map((recentActivity) => {
          // const formattedDate = format(
          //   new Date(recentActivity.date.seconds * 1000),
          //   "yyyy-MM-dd HH:mm:ss"
          // );
          const date = new Date(
            recentActivity?.date.seconds * 1000
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return (
            <div className="item" key={recentActivity?.id}>
              <Tooltip placement="top" title={recentActivity?.purchaseId}>
                <div className="flex">
                  <span className={"purchaseId"}>
                    {recentActivity?.purchaseId}
                  </span>
                  <div
                    onClick={() => {
                      navigator.clipboard.writeText(recentActivity?.purchaseId);
                      SuccessToast("Purchase ID copied Successfully");
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 0.425rem",
                    }}
                  >
                    <img src={copy} />
                  </div>
                </div>
              </Tooltip>

              <Tooltip placement="top" title={recentActivity?.eventName}>
                <div>{recentActivity?.eventName}</div>
              </Tooltip>
              <Tooltip placement="top" title={date}>
                <div>{date}</div>
              </Tooltip>
              <div className="center">{recentActivity?.section}</div>
              <div className="center">{recentActivity?.row}</div>
              <Tooltip placement="top" title={recentActivity?.seat}>
                <div className="center">{recentActivity?.seat}</div>
              </Tooltip>
              <div className="center">
                ${(recentActivity?.creditAmount / 100).toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
