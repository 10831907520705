import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import "../css/Modal.scoped.css";
import CloseDark from "../../assets/closedark.svg";
import Close from "../../assets/close.svg";
import axios from "axios";
import getEnvValue from "../utils/getEnvValue";
import { ErrorToast } from "../general/ToastNotification";
import SelectLabels from "../general/Select";
import GetTranslateString from "../../i18n/translateString";

// import PDFViewer from "pdf-viewer-reactjs";

export default function UploadedPDFViewer({
  dark,
  isOpen,
  handleClose,
  seatNumbers,
  data,
}) {
  const [pdf, setPdf] = useState(null);
  const [viewingIndex, updateViewingIndex] = useState(0);
  const token = localStorage.getItem("token");

  // console.log(data);

  // console.log("in ticket pdf");
  // console.log(data, token);

  useEffect(() => {
    (async () => {
      if (data && data[viewingIndex]) {
        try {
          const resp = await axios.post(
            `${getEnvValue("REACT_APP_TICKETS_API")}/tickets/self/${
              data[viewingIndex]
            }`,
            // "http://localhost:8080/tickets/inventory",
            {
              token,
            },
            {
              responseType: "blob",
            }
          );

          const file = new Blob([resp.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          setPdf(fileURL);

          // console.log(resp.data);
          // if (resp.data.error) {
          //   throw new Error(resp.data.error);
          // }
          // setPdf(resp.data);
          // console.log(resp.data.filter((e) => e.status !== TicketStatus.SOLD));
        } catch (e) {
          console.log(e);
          ErrorToast("Error", "Some error occurred while fetching tickets");
        }
      }
    })();
  }, [data, viewingIndex]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    maxHeight: "90vh",
    overflow: "auto",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // const handleClose = () => {
  //   toggleState(false);
  //   setData(null);
  // };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>Viewing Uploaded PDF</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            <div>
              <div>
                {/* {data &&
                  data.map((item, i) => (
                    <div key={i} className={"three-grid"}>
                      <p key={i}>Seat {item.split("_").pop()}</p>
                      {i === viewingIndex ? (
                        <p>Viewing</p>
                      ) : (
                        <Button
                          text={"View"}
                          onClick={() => {
                            updateViewingIndex(i);
                          }}
                        />
                      )}
                    </div>
                  ))} */}

                {data ? (
                  <div className="px-24 mt-10 mb-20">
                    <div className="label">Seat Number</div>
                    <SelectLabels
                      dark={dark}
                      name="seat"
                      value={viewingIndex}
                      updateValue={(e) => updateViewingIndex(e.target.value)}
                      options={data.map((ticketData, i) => {
                        return {
                          value: i,
                          label: `Seat/Queue No. ${
                            ticketData.split("_")[1].split(".")[0]
                          }`,
                        };
                      })}
                      placeholderText={GetTranslateString("choose")}
                      none={false}
                    />
                  </div>
                ) : null}
              </div>

              {pdf && (
                <div className="jc-ce">
                  <embed
                    src={pdf}
                    navpanes="0"
                    height="700"
                    width="600"
                    title="ticket pdf"
                  />
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const getBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    const blob = new Blob(file, { type: "application/pdf" });
    reader.readAsDataURL(blob);
    // Read file content on file loaded event
    reader.onload = function(event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
