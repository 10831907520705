import {
  deleteDoc,
  doc,
  getDoc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";

export default async function deleteBankAccount(storeId, acId) {
  const docRef = doc(db, "Stores", storeId, "BankAccounts", acId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    throw new Error("Bank not found.");
  }

  await deleteDoc(docRef);
}
