import axios from "axios";
import getEnvValue from "../../components/utils/getEnvValue";

export default async function uploadTicket(token, file, seatNumber) {
  if (file.size > 800 * 1024) {
    throw new Error("Upload Error", "Size of the pdf should be under 800KB");
  }

  let formData = new FormData();
  formData.append("file", file);
  const resp = await axios.post(
    `${getEnvValue(
      "REACT_APP_TICKETS_API"
    )}/product/upload/?seat=${seatNumber}`,
    // "http://localhost:80/product/upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": token,
      },
    }
  );

  // console.log(resp);
  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }
  return resp.data.url;
}
