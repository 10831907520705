import { collection, getDocs, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import "../css/ProductInfo.scoped.css";
import translate from "../../i18n/translate";
import getEnvValue from "../utils/getEnvValue";
const TransactionHistory = ({ ticketsData }) => {
  // console.log("TransactionHistory", ticketsData);
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  const [data, setData] = useState([]);

  useEffect(() => {
    const transactionListener = onSnapshot(
      collection(
        db,
        "Stores",
        StoreId,
        "Wallet",
        getEnvValue("REACT_APP_MODE"),
        "Transactions"
      ),
      async (snapShot) => {
        let tx = [];
        if (!snapShot.empty) {
          for (let doc of snapShot.docs) {
            if (doc.id.indexOf("pi_") === -1) {
              const showTxRef = collection(
                db,
                "Stores",
                StoreId,
                "Wallet",
                getEnvValue("REACT_APP_MODE"),
                "Transactions",
                doc.id,
                "Transactions"
              );
              const showTxSnap = await getDocs(showTxRef);

              let showTx = [];
              // console.log(showTxSnap.empty);
              if (!showTxSnap.empty) {
                showTx = showTxSnap.docs.map((e) => {
                  let ticket = ticketsData.find(
                    (tkt) => tkt._id === e.data().ticketId
                  );
                  return {
                    ...e.data(),
                    id: e.id,
                    venueImg: ticket?.venueImg,
                    price: getPrice(
                      e.data().totalAmount / 100,
                      Number(getEnvValue("REACT_APP_SELLER_FEES"))
                    ),
                    creditAmount: e.data().creditAmount / 100,
                    purchasedPrice: ticket?.purchasedPrice,
                  };
                });
              }
              tx.push(...showTx);
            }
          }
        }

        // console.log(tx);
        setData(tx);
      }
    );
    return () => {
      transactionListener();
    };
  }, [ticketsData]);

  return (
    <div className="transaction-history ">
      <div className="flex al-ce jc-sp priceheader">
        <div className="headersection">
          <div className="smtext">{translate("transactionHistory")}</div>
        </div>
      </div>
      <div>
        <div className="headersgrid">
          <div />
          <div>{translate("items")}</div>
          <div>{translate("price")}</div>
          <div>{translate("profitsLosses")}</div>
        </div>
        <div className="items">
          {data?.map((e, i) => {
            const quantity = e.seat ? e?.seat?.split(",").length : 1;

            return (
              <div key={i} className="item">
                <img src={e.venueImg} alt="item" />
                <div>{e.eventName}</div>
                <div>
                  {/* {sign[localCurrency]} */}
                  {quantity} × {sign[localCurrency]}
                  {parseFloat((e.price / quantity).toFixed(2))}
                </div>
                <div
                  className={`center ${
                    e.creditAmount - e.purchasedPrice * quantity > 0
                      ? "green"
                      : "red"
                  }`}
                >
                  {sign[localCurrency]}
                  {parseFloat(
                    (e.creditAmount - e.purchasedPrice * quantity).toFixed(2)
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getPrice = (amount, fees) => {
  return Number((amount / (1 + fees / 100)).toFixed(2));
};

export default TransactionHistory;
