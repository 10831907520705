import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import translate from "../i18n/translate";
import TicketInventoryContainer from "../components/inventory/TicketInventoryContainer";
import AddTicketModal from "../components/tickets/AddTicketModal";
import SearchModal from "../components/tickets/searchModal";
import { TicketStatus } from "../Constants";

const TicketInventory = ({ user, loadingScreen, setLoadingScreen, dark }) => {
  const [search, setSearch] = useState("");
  const [searchString, updateSearchString] = useState("");
  const [active, setActive] = useState(0);
  const isOwner = localStorage.getItem("owner");
  const [selectedData, setSelectedData] = useState(null);
  const [addModal, setAddModal] = useState(false);

  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth();

  // console.log(auth);

  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log("token refreshed");
      if (user) {
        const token = await user.getIdToken(true);
        // console.log(token, "token updated");

        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let hidden = localStorage.getItem("isInventoryHidden");
    if (!isOwner && hidden) {
      navigate("/dash");
    }
  }, [isOwner, navigate]);

  return (
    <div className="page">
      <Topbar
        dark={dark}
        pagename={translate("Inventory (Tickets)")}
        links={[
          {
            name: translate("Listings"),
          },
          {
            name: translate("Sold"),
          },
        ]}
        active={active}
        onClick={setActive}
        user={user}
      />

      <div>
        <SearchModal
          isOpen={searchModalOpen}
          toggleState={setSearchModalOpen}
          dark={dark}
          setSelectedData={setSelectedData}
          setAddModal={setAddModal}
        />
        <div className="add-btns px">
          <div className="mr-14">
            <AddTicketModal
              dark={dark}
              isOpen={addModal}
              toggleState={(state) => {
                setAddModal(state);
                setRefetch(!refetch);
              }}
              toggleSearchModal={setSearchModalOpen}
              searchData={selectedData}
            />
          </div>
          <div>
            <SearchBtn
              dark={dark}
              value={search}
              customClass={"h-40"}
              updateValue={setSearch}
              onEnter={async () => {
                updateSearchString(search);
              }}
            />
          </div>
        </div>
        <div className="content px">
          {(active === 0 || active === 1) && (
            <TicketInventoryContainer
              dark={dark}
              isOpen={searchModalOpen}
              searchString={searchString}
              toggleState={setSearchModalOpen}
              refresh={refetch}
              setRefresh={setRefetch}
              soldFilter={active === 1}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketInventory;
