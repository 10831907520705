import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import getEnvValue from "../../components/utils/getEnvValue";
import { StoreId } from "../../Constants";
import { db } from "../../firebase";

export default async function deleteTicket(token, id) {
  if (!token) {
    throw new Error("Token not provided");
  }

  if (!id) {
    throw new Error("Ticket id not provided");
  }

  const resp = await axios.post(
    `${getEnvValue("REACT_APP_TICKETS_API")}/tickets/delete/${id}`,
    // const resp = await axios.post(
    //   "  http://localhost:8080/tickets/delete/" + id,
    {
      token,
    }
  );

  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp.data;
}
