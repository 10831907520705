import { doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import refreshIcon from "../../assets/refresh.svg";
import refreshIconDark from "../../assets/refreshdark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import updateExpenseCount from "../../firebase/util/updateExpenseCount";
import translate from "../../i18n/translate";
import "../css/InenvtoryContainer.scoped.css";
import Button from "../general/Button";
import numberWithCommas from "../utils/numberWithCommas";
import ExpensesGrid from "./ExpensesGrid";
 

const ExpensesContainer = ({ searchString, dark }) => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");

  const [total, setTotal] = useState({
    expenseItems: 0,
    expenseValue: 0,
  });

  useEffect(() => {
    const cleanup = onSnapshot(doc(db, "Stores", StoreId), (doc) => {
      setTotal(doc.data());
    });
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className="inventory-container ">
      <div className="flex al-ce jc-sp headersection">
        <div className="smtext">{translate("summary")}</div>
        <div className="flex al-ce">
          <div className="query flex al-ce">
            <span className="purple">{translate("totalValue")}:</span>
            <span className="queryvalue">
              {sign[localCurrency]}
              {numberWithCommas(
                (
                  total.expenseValue * rates?.[localCurrency?.toUpperCase()]
                ).toFixed(2)
              )}
            </span>
          </div>
          <div className="query">
            <span className="purple">{translate("totalItems")}:</span>
            <span className="queryvalue">
              {numberWithCommas(total.expenseItems)}
            </span>
          </div>
        </div>
        <Button
          customClass={"refresh"}
          text={translate("refresh")}
          imgSrc={
            <img
              src={dark ? refreshIconDark : refreshIcon}
              alt="refresh Icon"
            />
          }
          onClick={async () => {
            setTotal(await updateExpenseCount(StoreId));
          }}
        />
      </div>
      <ExpensesGrid searchString={searchString} dark={dark} />
    </div>
  );
};

export default ExpensesContainer;
