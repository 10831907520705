import {
  addDoc,
  collection,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";
export default async function addBankAccount(storeId, data) {
  //data.name
  if (!data.paymentMethod) {
    throw new Error("Please provide a payout method");
  }

  if (data.paymentMethod !== "Paypal" && data.paymentMethod !== "Wise") {
    throw new Error("Payout method should be Paypal or Wise");
  }

  if (!data.email) {
    throw new Error("Please provide an email");
  }

  if (!data.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
    throw new Error("Please provide a valid email");
  }

  await addDoc(collection(db, "Stores", storeId, "BankAccounts"), {
    ...data,
    storeId: storeId,
  });
}
