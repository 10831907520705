import { useState } from "react";

const useMultiPriceInput = (price, buyerFee) => {
  // utils
  const validPriceInput = (str) => str === "" || /^\d+(\.\d{0,2})?$/.test(str);
  const parseInput = (str) => {
    let rawNumber = parseFloat(str);
    if (isNaN(rawNumber)) return 0;
    return rawNumber;
  };
  const formatRawNumber = (rawNumber) => Math.round(rawNumber * 100) / 100;
  // internal state
  const [platformPrice, updatePlatformPrice] = useState(parseFloat(price));
  const [payoutPrice, updatePayoutPrice] = useState(
    formatRawNumber(platformPrice * (1 - buyerFee))
  );

  const onChangePlatformPrice = (e) => {
    if (validPriceInput(e.target.value)) {
      let rawNumber = parseInput(e.target.value);
      updatePlatformPrice(e.target.value);
      updatePayoutPrice(formatRawNumber(rawNumber * (1 - buyerFee)));
    }
  };

  // payP = platP * (1 - buyerFee)  => platP = payP / (1 - buyerFee)

  const onChangePayoutPrice = (e) => {
    if (validPriceInput(e.target.value)) {
      let rawNumber = parseInput(e.target.value);
      updatePayoutPrice(e.target.value);
      updatePlatformPrice(formatRawNumber(rawNumber / (1 - buyerFee)));
    }
  };

  return {
    platformPrice,
    payoutPrice,
    onChangePlatformPrice,
    onChangePayoutPrice,
  };
};

export default useMultiPriceInput;
