import { useIntl } from "react-intl";
import "../css/SearchBtn.scoped.css";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

const SearchBtn = ({
  value,
  updateValue,
  placeholder,
  onEnter,
  dark,
  customClass,
}) => {
  return (
    <div className={`search-btn ${customClass ? customClass : ""}`}>
      <input
        placeholder={placeholder ? placeholder : GetTranslateString("search")}
        type="text"
        value={value}
        onKeyDown={async (event) => {
          if (event.key === "Enter") {
            onEnter();
          }
        }}
        onChange={(e) => {
          updateValue(e.target.value);
        }}
      />
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={async () => {
          onEnter();
        }}
      >
        <path
          d="M13.1274 12.1714L12.1712 13.1277L14.5654 15.5219C14.8294 15.7859 15.2576 15.7859 15.5216 15.5219C15.7857 15.2578 15.7857 14.8297 15.5216 14.5656L13.1274 12.1714Z"
          fill="#767BFA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 7.33334C0 13 2 14.6667 7.33334 14.6667C12.6667 14.6667 14.6667 13 14.6667 7.33334C14.6667 1.66667 12.6667 0 7.33334 0C2 0 0 1.66667 0 7.33334ZM1.33334 7.33334C1.33334 10.0808 1.83388 11.434 2.59218 12.1652C3.36196 12.9075 4.72782 13.3334 7.33334 13.3334C9.93887 13.3334 11.3047 12.9075 12.0745 12.1652C12.8328 11.434 13.3334 10.0808 13.3334 7.33334C13.3334 4.58591 12.8328 3.23269 12.0745 2.50146C11.3047 1.75917 9.93887 1.33334 7.33334 1.33334C4.72782 1.33334 3.36196 1.75917 2.59218 2.50146C1.83388 3.23269 1.33334 4.58591 1.33334 7.33334Z"
          fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
        />
      </svg>
    </div>
  );
};

export default SearchBtn;
