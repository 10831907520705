import { LOCALES } from "../locales";

let fr = {
  [LOCALES.FRENCH]: {
    hello: "Bonjour",
    owner: "Propriétaire",
    country: "Pays",
    storeName: "Nom du magasin",
    storeId: "Store ID",
    email: "Email",
    dateJoined: " Date d'entrée",
    categories: "Catégories",
    profitsLosses: "Profits/Pertes",
    totalExpenses: "Total des dépenses",
    totalInventory: "Inventaire total",
    discountSummary: "Résumé de la remise",
    staffSummary: "Résumé du personnel",
    staffDetails: "Détails du personnel",
    workingPerformance: "Performance de travail",
    clockInAndOutDetails: "Détails des pointages d’entrées et sorties",
    storeInformations: "Informations sur les magasins",
    staffs: "personnel",
    dashboard: "Tableau de bord",
    marketLookUp: "Consultation du marché",
    inventory: "Inventaire",
    productLabels: "Étiquettes de produits",
    consignment: "Consignation",
    sales: "Ventes",
    expenses: "Dépenses",
    settings: "Paramètres",
    marketSales: "Ventes sur le marché",
    total: "Total",
    name: "Nom",
    enterName: "Entrez le nom",
    enterId: "Entrez ID",
    enterDate: "Entrez la date",
    enterAddress: "Entrez l'adresse",
    enterCountry: "Entrez le pays",
    enterCity: "Entrez la ville",
    enterEmail: "Entrez l'adresse électronique",
    enterContactNo: "Entrez le numéro de contact",
    enterStyleCode: "Entrez le code du style",
    enterAmount: "Entrez le montant",
    enterStocks: "Entrer les stocks",
    enterPercentage: "Entrez le %",
    enterReq: "Entrer les exigences",
    enterStartDate: "Entrez la date de début",
    enterEndDate: "Entrez la date de fin",
    enterCode: "Entrez le code",
    enterStartTime: "Entrez l'heure de début",
    enterEndTime: "Entrez l'heure de fin",
    autoDetect: "Détection automatique",
    enterLast4Digits: "Entrez les 4 derniers chiffres",
    enterNumber: "Entrez le numéro",
    enterIfInstore: "Entrez - si vous êtes en magasin",
    shippingInfo: "Info d'expédition",
    enterInfo: "Entrez l'information",
    enterNotes: "Entrer les notes",
    enterTitle: "Entrez le titre",
    enterUrl: "Entrer l'URL",
    id: "ID",
    totalSales: "Ventes totales",
    transactionHistory: "Historique des transactions",
    date: "Date",
    delete: "Supprimer",
    items: "Articles",
    price: "Prix",
    requirements: "Exigences",
    eligibility: "Eligibilité",
    status: "Statut",
    used: "Utilisé",
    date: "Date",
    code: "Code",
    value: "Valeur",
    clockedInTime: "Heure d'arrivée",
    clockedOutTime: "Heure de sortie",
    totalHeures: "Heures totales",
    numberOfSales: "Nombre de ventes",
    figures: "Chiffres",
    timeIn: "Temps d’entrée",
    timeOut: "Temps de sortie",
    staffName: "Nom du personnel",
    staffId: "Staff ID",
    totalFigures: "Chiffres totaux",
    totalNumberOfSales: "Nombre total de ventes",
    workingPeriodAssigned: "Période de travail attribuée",
    dailyWorkingHours: "Heures de travail quotidiennes",
    weeklyWorkingHours: "Heures de travail hebdomadaires",
    monthlyWorkingHours: "Heures de travail mensuelles",
    yearlyWorkingHours: "Heures de travail annuelles",
    website: "Site web",
    priceHistory: "Historique des prix",
    productDetails: "Détails du produit",
    viewMarketSales: "Voir les ventes du marché",
    market: "Marché",
    comparison: "Comparaison",
    productInfo: "Informations sur le produit",
    newSiteComingSoon: "Nouveau site à venir",
    comparing: "Comparaison",
    style: "Style",
    size: "Taille",
    condition: " État",
    purchasedPrice: "Prix acheté",
    purchasedDate: "Date d'achat",
    platform: "Plate-forme",
    lastSale: "Dernière vente",
    markUp: "Augmentation",
    finalPrice: "Prix final",
    unrealized: "Non réalisé",
    totalValue: "Valeur totale",
    summary: "Résumé",
    totalItems: "Total des articles",
    totalUnrealizedProfit: "Profit total non réalisé",
    inventoryTrackingCode: "Code de suivi de l'inventaire",
    qrCode: "QR Code",
    qrCodeSettings: "Paramètres du code QR",
    footerBackground: "Arrière-plan du pied de page",
    logo: "Logo",
    background: "Arrière-plan",
    accentColour: "Couleur d'accent",
    boxBackgroundColor: "Couleur de fond de la boîte",
    fontColor: "Couleur de la police",
    sizing: "Sizing",
    currency: "Devise",
    save: "Sauvegarder ",
    barcodeTags: "Balises de code-barres",
    lastScannedDate: "Date du dernier scan ",
    scanned: "Scanné",
    sold: "Vendu",
    resetScan: "Réinitialisation du scan ",
    missingItem: "Elément manquant",
    scanItem: "Scanner l'élément",
    search: "Recherche",
    refresh: "Refresh",
    printAll: "Imprimer tout",
    default: "Défaut",
    totalConsignmentSales: "Total des ventes en consignation",
    consignmentSalesSummary: "Résumé des ventes en consignation",
    consignmentPopularCategories: "Consignation des catégories populaires",
    consigneeOverviews: "Aperçu des consignations",
    contactNo: "Numéro de contact",
    city: "Ville",
    payoutDate: "Date de paiement",
    payoutPrice: "Prix de paiement",
    dateIn: "Date d'entrée",
    listingPrice: "Prix catalogue",
    fee: "Frais",
    consigneeId: "ID du consignataire",
    itemName: "Nom de l'élément", 
    firstNameAndLastName: "Prénom et Nom",
    codeName: "Nom du code",
    notes: "Notes",
    spentDate: "Date de dépense",
    amountSpent: "Montant dépensé",
    duration: "Durée",
    shipping: "Expédition",
    tax: "Tax",
    quantity: "Quantité",
    expenseName: "Nom de la dépense",
    nomClient: "Nom du client",
    staffNavigationBarSettings: "Paramètres de la barre de navigation du personnel",
    mode: "Mode",
    specificStaffPermission: "Permissions spécifique du personnel",
    generalStaffsPermissions: "Permissions du personnel général",
    material1: "Matériau 1",
    material2: "Matériau 2",
    salesSummary: "Résumé des ventes",
    growth: "Croissance",
    pos: "POS",
    receiptSettings: "Paramètres du reçu",
    purchase: "ACHAT",
    preview: "Aperçu",
    cart: "Panier",
    orderId: "ID de commande",
    sku: "SKU",
    searchsku: "Recherche de Sku",
    item: "Article",
    totalPayout: "Paiement total",
    totalProfit: "Profit total",
    customerSideScreen: "Écran latéral du client",
    addDiscountCode: "Ajouter un code de remise",
    discountAmount: "Montant de la remise",
    subTotal: "Sous-total",
    discount: "Remise",
    saleTax: "Taxe de vente",
    thankyouForYourPurchase: "MERCI POUR VOTRE ACHAT",
    welcomeBack: "Bienvenue à nouveau",
    customerViewingScreenSettings: "Paramètres de l'écran de visualisation du client",
    dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton: "Tableau de bord - Informations sur la boutique - Masquer le bouton Créer/Modifier/Supprimer le code de remise",
    inventoryHideImportButton: "Inventaire - Masquer le bouton d'importation",
    inventoryHideDownloadButton: "Inventaire - Masquer le bouton de téléchargement",
    inventoryHideAddNewEditDeleteButton: "Inventaire - Masquer le bouton Ajouter Nouveau/Editer/Supprimer",
    productLabelsHideqrCodeSettingsPage: "Étiquettes de produits - Masquer la page de paramètres du code QR",
    consignmentsSummaryHidePayoutConfirmationButton: "Consignations - Résumé - Masquer le bouton de confirmation de paiement",
    consignmentsConsigneeHideAddNewEditDeleteButton: "Consignations - Destinataire - Masquer le bouton Ajouter nouveau/Éditer/Supprimer",
    consignmentsInventoryHideAddNewEditDeleteButton: "Consignations - Inventaire - Masquer le bouton Ajouter Nouveau/Modifier/Supprimer",
    salesHideRecieptSettingsPage: "Ventes - Masquer la page des paramètres de facturation",
    hideExpensesPage: "Masquer la page des dépenses",
    title: "Titre",
    saveItems: "Sauvegarder les éléments",
    settingOptions: "Options de réglage",
    addNew: "Ajouter un nouveau",
    enterCode: "Entrez le code",
    pay: "Pay",
    addToCart: "Ajouter au panier",
    import: "Importer",
    data: "Données",
    searchSku: "Recherche de SKU",
    recommendedSize: "Taille recommandée",
    lastSalePrice: "Dernier prix de vente",
    currency: "Devise",
    retailPrice: "Prix de détail",
    language: "Langue",
    pricePremium: "Prix avantageux",
    addingNewConsignmentItems: "Ajout de nouveaux articles de consignation...",
    editingConsignmentItems: "Modification des articles en consignation...",
    consignmentSalesDetails: "Détails des ventes en consignation",
    soldDate: "Date de vente",
    soldBy: "Vendu par",
    sizes: "Tailles",
    bestSizes: "Meilleures tailles",
    dateJoin: "Date d'entrée",
    startTime: "Heure de début",
    endTime: "Heure de fin",
    addingNewConsignee: "Ajout d'un nouveau consignataire...",
    totalStores: "Total des magasins",
    staffOverviews: "Aperçus du personnel",
    edit: "Editer",
    addStaff: "Ajouter du personnel",
    inLastMonth: "au cours du dernier mois",
    logOut: "Déconnexion",
    itemsSold: "articles vendus",
    stockTags: "Balises de stock",
    daily: "Quotidien",
    weekly: "Hebdomadaire",
    monthly: "Mensuel",
    yearly: "Annuel",
    active: "Actif",
    onLeave: "En congé",
    resigned: "Démissionné",
    addingNewStaff: "Ajout d'un nouveau personnel...",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
    creatingDiscountCode: "Création d'un code de remise",
    type: "Type",
    stocks: "Stocks",
    specificproduct: "Produit spécifique",
    eligibility: "Eligibilité",
    startDate: "Date de début",
    endDate: "Date de fin",
    startTime: "Heure de début",
    endTime: "Heure de fin",
    onePerCustomer: "un par client",
    twoPerCustomer: "deux par client",
    threePerCustomer: "trois par client",
    onAllProducts: "Sur tous les produits",
    onSpecificCategories: "Sur les catégories spécifiques",
    onSpecificProducts: "Sur des produits spécifiques",
    sneakers:"Sneakers",
    collectibles:"Collectibles",
    arts: "Arts",
    streetwears: "Vêtements de rue",
    addingNewInventoryItems: "Ajout de nouveaux articles d'inventaire",
    custom: "Personnalisé",
    editingItem: "Élément d'édition",
    address: "Adresse",
    contact: "Contact",
    firstAndLastName: "Prénom et nom",
    customerPreviews: "Aperçus des clients",
    recommendedProducts: "Produits recommandés",
    findOutMore: "En savoir plus",
    editingExpenseItems: "Modification des éléments de dépenses",
    transactionId: "ID de Transaction",
    oneTime: "Une fois",
    light: "Lumière",
    dark: "Sombre",
    popularCategories: "Catégories populaires",
    paymentMethod: "Mode de paiement",
    payout: "Paiement",
    trending: "Tendance",
    clearAll: "Effacer tout",
    editItem: "Editer l'élément",
    editStore: "Editer le magasin",
    success: "Succès",
    consigneeCreated: "Consignataire cré.",
    error: "Erreur",
    itemEdited: "Élément édité.",          
    itemCreated: "Elément créé.",
    clockedIn: "Pointage d’entrée.",
    clockedOut: " Pointage de sortie.",
    storeLockedSuccessfully: "Magasin verrouillé avec succès.",
    storeUnlockedSuccessfully: "Magasin déverrouillé avec succès.",
    storeEdited: "Magasin édité.",
    storeCreated: "Magasin créé.",
    discountCodeCreated: "Code de remise créé.",
    staffAdded: "Personnel ajouté",
    codeEdited: "Code edité.",
    staffEdited: "Personnel edité",
    expenseCreated: "Dépense créée.",
    logoUpdated: "Logo mis à jour",
    defaultSettings: "Paramètres par défaut.",
    updated: "Mis à jour",
    customSettings: "Paramètres personnalisés.",
    detailsUpdated: "Détails mis à jour",
    verified: "Vérifié",
    discountApplied: "Remise appliquée.",
    failed: "Failed",
    invalidDiscountCode: "Code de remise non valide.",
    paymentSuccessful: "Paiement réussi.",
    successfullyUpdated: "Mise à jour réussie",
    settingsSaved: "Paramètres enregistrés.",
    alreadyClockedIn: "Déjà entrée pointée.",
    alreadyClockedOut: "Déjà sortie pointée",
    pleaseSelectAStore: "Veuillez sélectionner un magasin.",
    cartCannotBeEmpty: "Le panier ne peut être vide.",
    emptyDiscountField: "Champ de remise vide.",
    cartEmpty: "Panier vide",
    paymentUnsuccessful: "Paiement non réussi",
    addToCartFailed: "L'ajout au panier a échoué",
    storeDoesNotExist: "Le magasin n'existe pas",
    selectAStaff: "Sélectionnez un personnel.",
    pleaseSelectAStaff: "Veuillez sélectionner un personnel.",
    pleaseTickCheckbox: "Veuillez cocher la case",
    vacantInput: "Entrée vacante",
    consignee: "Consignataire",
    salesDetails: "Détails des ventes",
    fulfilled: "Réalisé",
    editingDiscountCode: "Modification du code de réduction",
    editingStaff: "Personnel d'édition",
    editingStore: "Magasin d'édition",
    download: "Télécharger",
    inCart: " Dans panier",
    choose: "Choisir",
    chooseSize: "Choisir la taille",
    chooseCategories: "Choisissez des catégories",
    choosePlatform: "Choisissez la plateforme",
    chooseCondition: "Choisissez l'état",
    chooseCurrency: "Choisir la devise",
    chooseMethod: "Choisissez la méthode",
    chooseLanguage: "Choisissez la langue",
    chooseProducts: "Choisissez des produits",
    chooseEligibility: "Choisissez l'éligibilité",
    chooseDuration: "Choisissez la durée",
    chooseMode: "Choisissez le mode",
    selectColour: "Sélectionnez la couleur",
    all: "Tous",
    expired: "Expiré",
    scheduled: "Programmé",
    createCode: "Créer du code",
    highestBid: "Offre la plus élevée",
    lowestAsk: "Demande la plus basse",
    missingItems: "Eléments manquant",
    print: "Imprimer",
    consigneesSummary: "Résumé des consignataire",
    editingConsignee : "Modification du consignataire",
    dateSold: "Date de vente",
    addingNewExpense: "Ajouter une nouvelle dépense",
    details: "Des détails",
    verify: "Vérifier",
    staff: "Personnel",
    time: "Temps",
    oneMonth: "1 mois",
    threeMonths: "3 mois",
    sixMonths: "6 mois",
    marketIdentifier: "Identifiant du marché",
    new: "Nouveau",
    confirmingPayment: "Confirmation du paiement",
    customerEmail: "Email client",
    cash: "Espèces",
    cheque: "Chèque",
    creditCard: "Carte de crédit",
    payNow: "Payez maintenant",
    ccLast4Digits: "CC 4 derniers chiffres",
    amountReceived: "Amount Received",
    change: "monnaie",
    inStore: "En magasin",
    delivery: "Livraison",
    shippingAddress: "adresse de livraison",
    ofTotal: "du total",
    deleting: "Suppression",
    confirmToDeleteThis: "Confirmez pour supprimer ce",
    yesConfirm: "OUI, Confirmer",
    noThinkAgain: "Non, détrompez-vous",
    purchasedBy: "Acheté par"
  },
};

export default fr;
