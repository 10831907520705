import axios from "axios";
import getEnvValue from "../../components/utils/getEnvValue";
export default async function requestCashout(token, showId, accountId, type) {
  if (!token) {
    throw new Error("Token not provided");
  }

  if (!accountId) {
    throw new Error("Please select a bank account");
  }

  if (!(type === "1" || type === "14")) {
    throw new Error("Request period must be 1 or 14 working day");
  }

  console.log({
    token,
    accountId,
    type,
    showId,
  });

  const resp = await axios.post(
    `${getEnvValue("REACT_APP_TICKETS_API")}/payout/request`,
    {
      // const resp = await axios.post("http://localhost:80/payout/request", {
      token,
      accountId,
      type,
      showId,
    }
  );

  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp;
}
