import { db } from "../index";
import { getDoc, doc } from "firebase/firestore";

//Take in an array of fireBase ids and return back an array of data

// doc(db, "Collections", "doc")
// /users/xyz doc
export default async function batchFetch(refs) {
  let itemSet = {};

  for (let ref of refs) {
    itemSet[ref] = true;
  }

  const refMap = Object.keys(itemSet);

  let itemRefs = refMap.map((id) => doc(db, id));
  let getData = itemRefs.map((ref) => getDoc(ref));
  let resultArray = await Promise.all(getData);
  const result = resultArray.map((doc) => doc.data());

  for (let refIndex in refMap) {
    const ref = refMap[refIndex];
    itemSet[ref] = result[refIndex];
  }
  return refs.map((ref) => itemSet[ref]);
}
