import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Sidebar from "./components/general/Sidebar";
import { CurrencyProvider } from "./context/CurrencyContext";
// import Consignment from "./pages/Consignment";
import Expenses from "./pages/Expenses";
import Home from "./pages/Home";
import Inventory from "./pages/TicketInventory";
// import Market from "./pages/Market";
// import ProductLabels from "./pages/ProductLabels";
// import Sales from "./pages/Sales";
import Settings from "./pages/Settings";
import DashOwner from "./pages/DashOwner";
import PrintAllNoQr from "./pages/PrintNoQr";
import PrintQr from "./pages/PrintQr";
import Login from "./pages/Login";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { StoreId } from "./Constants";
import LockedImg from "./assets/locked.png";
import Logout from "./pages/Logout";
import Preview from "./pages/Preview";
// import BarcodeScan from "./pages/BarcodeScan";
import I18nProvider from "./i18n/provider";
import { LOCALES } from "./i18n/locales";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import Wallet from "./pages/Wallet";

function App() {
  const [currency, setCurrency] = useState("sgd");
  const [language, setLanguage] = useState(LOCALES.ENGLISH);
  const [mode, setMode] = useState("light");
  const [sizing, setSizing] = useState("us");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [genStaffSettings, setGenStaffSettings] = useState();
  const [staffSettings, setStaffSettings] = useState({});

  useEffect(() => {
    localStorage.setItem("currency", "sgd");
  }, []);

  useEffect(() => {
    const authListener = onIdTokenChanged(getAuth(), async (user) => {
      // console.log("token refreshed");
      if (user) {
        const token = await user.getIdToken(true);
        // console.log("token updated");
        // localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    console.log(staffSettings);
  }, [staffSettings]);

  const [user, setUser] = useState({
    userName: "",
    userImg: "",
  });

  const isOwner = localStorage.getItem("owner");

  const THEME = createTheme({
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    },
  });

  useEffect(() => {
    if (loggedIn) {
      const storeListener = onSnapshot(
        doc(db, "Stores", StoreId),
        async (snapShot) => {
          setIsLocked({
            ...snapShot.data(),
            id: snapShot.id,
          });

          let data = snapShot.data();

          setUser({
            userName: data.fullName,
            userImg: data.userImg,
          });
        }
      );
      return () => {
        storeListener();
      };
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && !isOwner) {
      const storeListener = onSnapshot(
        doc(db, "Stores", StoreId, "Customization", "GenStaffSettings"),
        async (snapShot) => {
          let data = { ...snapShot.data() };
          setGenStaffSettings(data);
        }
      );
      return () => {
        storeListener();
      };
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && !isOwner) {
      const storeListener = onSnapshot(
        doc(
          db,
          "Stores",
          StoreId,
          "Staff",
          "Staffs",
          "Accounts",
          localStorage.getItem("staffId")
        ),
        async (snapShot) => {
          if (snapShot.data() !== {}) {
            let data = { ...snapShot.data() };
            setStaffSettings(data.settings);
          }
        }
      );
      return () => {
        storeListener();
      };
    }
  }, [loggedIn]);

  useEffect(() => {
    if (staffSettings && genStaffSettings) {
      Object.keys({
        hideDashBtns: false,
        hideInvImport: false,
        hideInvDownload: false,
        hideInvBtns: false,
        hideProductQr: false,
        hidePayoutConfimation: false,
        hideConsigneeBtns: false,
        hideConsigneeInvBtns: false,
        hideReceiptSettings: false,
        hideExpensePage: false,
      }).forEach((e) => {
        if (staffSettings?.[e] || genStaffSettings?.[e]) {
          localStorage.setItem(e, true);
        } else {
          localStorage.removeItem(e);
        }
      });
    }
  }, [staffSettings, genStaffSettings]);

  // useEffect(() => {
  //   if (!isOwner) {
  //     if (staffSettings?.hideConsigneeBtns) {
  //       localStorage.setItem(
  //         "hideConsigneeBtns",
  //         staffSettings?.hideConsigneeBtns
  //       );
  //     }
  //     if (staffSettings?.hideConsigneeInvBtns) {
  //       localStorage.setItem(
  //         "hideConsigneeInvBtns",
  //         staffSettings?.hideConsigneeInvBtns
  //       );
  //     }

  //     if (staffSettings?.hideDashBtns) {
  //       localStorage.setItem("hideDashBtns", staffSettings?.hideDashBtns);
  //     }

  //     if (staffSettings?.hideExpensePage) {
  //       localStorage.setItem("hideExpensePage", staffSettings?.hideExpensePage);
  //     }

  //     if (staffSettings?.hideInvBtns) {
  //       localStorage.setItem("hideInvBtns", staffSettings?.hideInvBtns);
  //     }

  //     if (staffSettings?.hideInvDownload) {
  //       localStorage.setItem("hideInvDownload", staffSettings?.hideInvDownload);
  //     }

  //     if (staffSettings?.hideInvImport) {
  //       localStorage.setItem("hideInvImport", staffSettings?.hideInvImport);
  //     }

  //     if (staffSettings?.hidePayoutConfimation) {
  //       localStorage.setItem(
  //         "hidePayoutConfimation",
  //         staffSettings?.hidePayoutConfimation
  //       );
  //     }

  //     if (staffSettings?.hideProductQr) {
  //       localStorage.setItem("hideProductQr", staffSettings?.hideProductQr);
  //     }

  //     if (staffSettings?.hideReceiptSettings) {
  //       localStorage.setItem(
  //         "hideReceiptSettings",
  //         staffSettings?.hideReceiptSettings
  //       );
  //     }
  //   }
  // }, [loggedIn, staffSettings]);

  useEffect(() => {
    if (loggedIn) {
      const navListener = onSnapshot(
        doc(db, "Stores", StoreId, "Customization", "NavSettings"),
        async (snapShot) => {
          const settingsData = {
            ...snapShot.data(),
          };
          if (settingsData) {
            Object.keys(settingsData).forEach((e) => {
              if (settingsData[e]) {
                localStorage.setItem(e, settingsData[e]);
              } else {
                localStorage.removeItem(e);
              }
            });
          }
        }
      );
      return () => {
        navListener();
      };
    }
  }, [loggedIn]);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedIn");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setLoggedIn(foundUser);
    }

    let localCurrency = localStorage.getItem("currency");
    if (!localCurrency) {
      localStorage.setItem("currency", currency);
    } else {
      setCurrency(localCurrency);
    }

    let localLanguage = localStorage.getItem("language");
    if (!localLanguage) {
      localStorage.setItem("language", language);
    } else {
      setLanguage(localLanguage);
    }

    let localSizing = localStorage.getItem("sizing");
    if (!localSizing) {
      localStorage.setItem("sizing", sizing);
    } else {
      setSizing(localSizing);
    }

    let localMode = localStorage.getItem("mode");
    if (!localMode) {
      localStorage.setItem("mode", mode);
    } else {
      setMode(localMode);
    }
  }, []);

  if (loggedIn && isLocked.locked) {
    return (
      <div>
        <img className="lockedimg" src={LockedImg} alt="locked" />
      </div>
    );
  }

  return (
    <I18nProvider locale={language}>
      <CurrencyProvider>
        {loading && (
          <div className="loader-container">
            <div className="lds-ripple">
              <div />
              <div />
            </div>
          </div>
        )}
        <ThemeProvider theme={THEME}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="colored"
            pauseOnHover
          />
          {/* <Routes>
          <Route
            path="/barcode"
            element={<BarcodeScan  />}
          />
        </Routes> */}
          {loggedIn ? (
            <div className={`App ${mode === "dark" ? "dark" : ""}`}>
              <div className="main-container">
                <Routes>
                  <Route path="/login" />
                  <Route path="/print" />
                  <Route path="/printqr" />
                  <Route path="/preview" />
                  <Route path="/logout" />
                  <Route
                    path="/dash"
                    element={<Sidebar dark={mode === "dark"} />}
                  />
                  {/* <Route
                    path="/market"
                    element={<Sidebar dark={mode === "dark"} />}
                  /> */}
                  <Route
                    path="/inventory"
                    element={<Sidebar dark={mode === "dark"} />}
                  />
                  {/* <Route
                    path="/product"
                    element={<Sidebar dark={mode === "dark"} />}
                  />
                  <Route
                    path="/consignment"
                    element={<Sidebar dark={mode === "dark"} />}
                  /> */}
                  <Route
                    path="/settings"
                    element={<Sidebar dark={mode === "dark"} />}
                  />
                  {/* <Route
                    path="/sales"
                    element={<Sidebar dark={mode === "dark"} />}
                  /> */}
                  <Route
                    path="/wallet"
                    element={<Sidebar dark={mode === "dark"} />}
                  />
                  <Route
                    path="/expenses"
                    element={<Sidebar dark={mode === "dark"} />}
                  />
                  <Route path="*" element={<Navigate to="/dash" replace />} />
                </Routes>

                <Routes>
                  {!isOwner ? (
                    <Route
                      index
                      path="/dash"
                      element={<Home user={user} dark={mode === "dark"} />}
                    />
                  ) : (
                    <Route
                      index
                      path="/dash"
                      element={
                        <DashOwner
                          setLoading={setLoading}
                          user={user}
                          dark={mode === "dark"}
                        />
                      }
                    />
                  )}

                  <Route
                    path="/login"
                    element={
                      <Login setLoading={setLoading} dark={mode === "dark"} />
                    }
                  />
                  {/* 
                  <Route
                    path="/market"
                    element={
                      <Market
                        user={user}
                        setLoading={setLoading}
                        dark={mode === "dark"}
                      />
                    }
                  /> */}

                  <Route
                    path="/preview"
                    element={<Preview dark={mode === "dark"} />}
                  />

                  <Route
                    path="/inventory"
                    element={
                      <Inventory
                        loadingScreen={loading}
                        setLoadingScreen={setLoading}
                        user={user}
                        dark={mode === "dark"}
                      />
                    }
                  />
                  {/* <Route
                    path="/product"
                    element={
                      <ProductLabels user={user} dark={mode === "dark"} />
                    }
                  />
                  <Route
                    path="/consignment"
                    element={<Consignment user={user} dark={mode === "dark"} />}
                  /> */}
                  <Route
                    path="/print"
                    element={
                      <PrintAllNoQr user={user} dark={mode === "dark"} />
                    }
                  />
                  <Route
                    path="/printqr"
                    element={<PrintQr user={user} dark={mode === "dark"} />}
                  />

                  <Route
                    path="/expenses"
                    element={<Expenses user={user} dark={mode === "dark"} />}
                  />
                  {/* <Route
                    path="/sales"
                    element={<Sales user={user} dark={mode === "dark"} />}
                  /> */}
                  <Route
                    path="/wallet"
                    element={<Wallet user={user} dark={mode === "dark"} />}
                  />
                  <Route
                    path="/settings"
                    element={
                      <Settings
                        currency={currency}
                        setCurrency={setCurrency}
                        language={language}
                        setLanguage={setLanguage}
                        mode={mode}
                        setMode={setMode}
                        sizing={sizing}
                        setSizing={setSizing}
                        user={user}
                        dark={mode === "dark"}
                      />
                    }
                  />
                  <Route
                    path="/logout"
                    element={<Logout dark={mode === "dark"} />}
                  />
                </Routes>
              </div>
            </div>
          ) : (
            <Login setLoading={setLoading} />
          )}
        </ThemeProvider>
      </CurrencyProvider>
    </I18nProvider>
  );
}

export default App;
