import { doc, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import refreshIcon from "../../assets/refresh.svg";
import refreshIconDark from "../../assets/refreshdark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import { db } from "../../firebase/index";
import updateNormalCount from "../../firebase/util/updateNormalCount";
import "../css/InenvtoryContainer.scoped.css";
import Button from "../general/Button";
import numberWithCommas from "../utils/numberWithCommas";
import PriceAlertModal from "./PriceAlertModal";
import InventoryGrid from "./InventoryGrid";
import translate from "../../i18n/translate";
import SearchModal from "../tickets/searchModal";
import TicketInventoryGrid from "./TicketInventoryGrid";

const TicketInventoryContainer = ({
  isOpen,
  toggleState,
  dark,
  searchString,
  refresh,
  soldFilter,
  setRefresh,
}) => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  const [total, setTotal] = useState({
    tv: 0,
    ti: 0,
    tup: 0,
  });

  const [addModal, setAddModal] = useState(false);

  return (
    <div>
      <div className="inventory-container ">
        <div className="flex al-ce jc-sp px-24">
          <div />
          <div className="flex al-ce">
            <div className="query flex al-ce">
              <span className="purple">{translate("totalValue")}:</span>
              <span className="queryvalue">${(total.tv || 0).toFixed(2)}</span>
            </div>
            <div className="query">
              <span className="purple">{translate("totalItems")}:</span>
              <span className="queryvalue">{total.ti || 0}</span>
            </div>
          </div>

          <div className="flex">
            <Button
              text={translate("refresh")}
              onClick={() => setRefresh(!refresh)}
              imgSrc={
                <img
                  src={dark ? refreshIconDark : refreshIcon}
                  alt="refresh Icon"
                />
              }
            />
          </div>
        </div>
        <TicketInventoryGrid
          dark={dark}
          searchString={searchString}
          setTotal={setTotal}
          refetch={refresh}
          setRefetch={setRefresh}
          addModal={addModal}
          soldFilter={soldFilter}
          setAddModal={setAddModal}
        />
      </div>
    </div>
  );
};

export default TicketInventoryContainer;
