import algoliasearch from "algoliasearch/lite";
import { StoreId } from "../Constants";

const client = algoliasearch("A3ZR8GPESC", "1424f32c83fed9bd461ae5b84c2119e5");
const index = client.initIndex("test-store");
console.log(`store:"${StoreId}"`);
export async function searchInventory(searchString) {
  const resp = await index.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits;
}

export async function searchConsigneeInventory(searchString) {
  const resp = await index.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits;
}

export async function searchAllInventory(searchString) {
  const resp = await index.search(searchString, {
    filters: `store:"${StoreId}"`,
  });
  return resp.hits;
}
