import React, { useEffect, useState } from "react";
import "../css/Wallet.scoped.css";
import redClose from "../../assets/red-close.svg";

export default function BankAccounts({
  bankAccounts,
  setDeleteId,
  setDeleteOpen,
}) {
  return (
    <div className="bankAccounts">
      <div className="headersgrid">
        <div>Payout Method</div>
        <div className="center">Email</div>
      </div>
      <div className="items">
        {bankAccounts.map((bankAccount) => (
          <div className="item" key={bankAccount.id}>
            <div>{bankAccount.paymentMethod}</div>
            <div className="center">{bankAccount.email}</div>
            <div className="flex al-ce jc-fe activitiesImg">
              <img
                onClick={(e) => {
                  setDeleteId(bankAccount.id);
                  setDeleteOpen(true);
                }}
                src={redClose}
                className="del-icon"
                alt="red-close"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
