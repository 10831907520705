import { getDocs, query, collection, orderBy } from "firebase/firestore";
import { db } from "../index";

export default async function expensesGraph(storeId) {
  const expensesRef = collection(db, "Stores", storeId, "Expenses");

  let result = {};
  const q = query(expensesRef, orderBy("spentDate", "desc"));

  const graphSnapshot = await getDocs(q);
  if (graphSnapshot.size === 0) {
    return new Error("No expenses yet!");
  }

  let count = 0,
    total = 0;
  for (let data of graphSnapshot.docs) {
    const reqData = data.data();
    if (result[reqData.spentDate] === undefined) {
      result[reqData.spentDate] = reqData.amountSpent;
    } else {
      result[reqData.spentDate] += reqData.amountSpent;
    }
    count++;
    total += result[reqData.spentDate];
  }

  return { count, total, result };
}
