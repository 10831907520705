import en from "./en-us";
import fr from "./fr-ca";
import es from "./es-us";
import zh from "./zh-Hans"
import ko from "./ko"
let Messages = {
  ...en,
  ...fr,
  ...es,
  ...zh,
  ...ko,
};

export default Messages;
