import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const GetTranslateString = (id) => {
  const intl = useIntl();
  const plainText = intl.formatMessage({id: id});
  return plainText;
}

export default GetTranslateString;
