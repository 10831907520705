import { doc, updateDoc, getDoc, increment } from "firebase/firestore";
import { db } from "../../index";

export default async function clockingOut(storeId, staffId, email, date) {
  const staffRef = doc(
    db,
    "Stores",
    storeId,
    "Staff",
    "Staffs",
    "Accounts",
    staffId
  );
  const staffSnap = await getDoc(staffRef);

  if (!staffId || !staffSnap.exists()) {
    throw new Error("Invalid Staff Id");
  }
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!email.match(validRegex)) {
    throw new Error("Email is not a valid value.");
  }

  const key = date + staffId;
  const logRef = doc(db, "Stores", storeId, "Staff", "Shifts", "Logs", key);

  const logSnap = await getDoc(logRef);

  const time = Number(((Date.now() - logSnap.data().clockingIn) / 3600000).toFixed(2));
  await updateDoc(doc(db, "Stores", storeId, "Staff", "Shifts", "Logs", key), {
    clockingOut: Date.now(),
    totalTime: time,
  });

  // update staff total hours
  const incrementHours = increment(time);
  const updater = {
    totalHours: incrementHours,
    clockingOut: Date.now(),
  };

  await updateDoc(
    doc(db, "Stores", storeId, "Staff", "Staffs", "Accounts", staffId),
    updater
  );
}
