import { useContext, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import Categories from "../components/dashAdmin/Categories";
import CheckInModal from "../components/dashAdmin/CheckInModal";
import CheckOutModal from "../components/dashAdmin/CheckOutModal";
import DiscountSummary from "../components/dashAdmin/DiscountSummary";
import TransactionHistory from "../components/dashAdmin/TransactionHistory.js";
import Graph from "../components/general/Graph";
import SearchBtn from "../components/general/SearchBtn";
import SmallSelect from "../components/general/SmallSelect";
import Topbar from "../components/general/Topbar";
import { CurrencyContext } from "../context/CurrencyContext";
import "./css/Home.scoped.css";
import "./css/Market.scoped.css";
import profitLossGraph from "../firebase/graphs/profitLossGraph";
import { StoreId } from "../Constants";
import numberWithCommas from "../components/utils/numberWithCommas";
import translate from "../i18n/translate";
import GetTranslateString from "../i18n/translateString"

const Home = ({ user, dark }) => {
  const [time, setTime] = useState("monthly");
  const [search, setSearch] = useState("");
  const { rates, sign } = useContext(CurrencyContext);
  const [green, setGreen] = useState("false");
  const [type, setType] = useState("All");
  const [graphData, setGraphData] = useState();
  const [totalSales, setTotalSales] = useState(0);

  const auth = getAuth();
  console.log(auth)
  
  let types = ["All", "Active", "Expired", "Scheduled"];
  let localCurrency = localStorage.getItem("currency");
  console.log(dark);
  useEffect(() => {
    if (StoreId) {
      (async () => {
        let { count, total, result: data } = await profitLossGraph(StoreId);
        if (data) {
          let filterData =
            Object.keys(data)?.length > 5
              ? Object.keys(data)
                  .slice(0, 5)
                  .reverse()
                  .map((e) => ({
                    date: e,
                    value: data[e],
                  }))
              : Object.keys(data)
                  .reverse()
                  .map((e) => ({
                    date: e,
                    value: data[e],
                  }));
          setTotalSales(Object.values(data).reduce((a, b) => a + b), 0);
          setGraphData(filterData);
        } else {
          setGraphData([]);
          setTotalSales(0);
        }
      })();
    }
  }, []);

  return (
    <div className="page">
      <Topbar dark={dark} pagename={translate("dashboard")} user={user} />
      <div>
        <div className="add-btns px">
          <div className="mr-14">
            <CheckInModal green={green} setGreen={setGreen} dark={dark} />
          </div>
          <div className="mr-14">
            <CheckOutModal green={green} setGreen={setGreen} dark={dark} />
          </div>
          <SmallSelect
            dark={dark}
            name="time"
            value={time}
            updateValue={(e) => setTime(e.target.value)}
            options={[
              {
                label: translate("daily"),
                value: "daily",
              },
              {
                label: translate("weekly"),
                value: "weekly",
              },
              {
                label: translate("monthly"),
                value: "monthly",
              },
              {
                label: translate("yearly"),
                value: "yearly",
              },
            ]}
            placeholderText={GetTranslateString("categories")}
            none={false}
            month
          />
        </div>
        <div className="content ">
          <div className="px">
            <div className="threegrid">
              <div className="box">
                <Categories blur={"blur"} />
              </div>
              <div className="graph box">
                <div className="headersection">{translate("profitsLosses")}</div>
                <div className="flex al-fe headers px-20">
                  <span className="bold black">USD:</span>
                  <span className="green bold">
                    +{sign[localCurrency]}
                    {numberWithCommas(
                      totalSales
                        ? (
                            totalSales * rates?.[localCurrency?.toUpperCase()]
                          ).toFixed(0)
                        : 0
                    )}
                  </span>
                  <span className="itemssold">{translate("inLastMonth")}...</span>
                </div>
                {graphData && <Graph graphData={graphData} dark={dark} />}
              </div>
              <div className="box transaction-history">
                <TransactionHistory />
              </div>
              <div className="box span-2">
                <div className="headersection flex al-ce jc-sp">
                  <div className="smtext">{translate("discountSummary")}</div>
                  <div className="flex al-ce jc-sp">
                    {types.map((e) => (
                      <div
                        onClick={() => setType(e)}
                        className={`type ${type === e ? "active" : ""}`}
                        key={e}
                      >
                        {translate(e.toLowerCase())}
                      </div>
                    ))}
                  </div>
                  <SearchBtn
                    dark={dark}
                    value={search}
                    updateValue={setSearch}
                  />
                </div>
                <DiscountSummary type={type} dark={dark} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
