import { format } from "date-fns";
import React, { useContext } from "react";
import "../css/Wallet.scoped.css";
import { CurrencyContext } from "../../context/CurrencyContext";

export default function Cashout({ cashoutData, bankAccountNames }) {
  const statusColors = {
    verifying: "purple",
    pending: "orange",
    failed: "red",
    successful: "green",
  };
  let localCurrency = localStorage.getItem("currency");
  const { sign, rates } = useContext(CurrencyContext);
  // console.log(cashoutData);
  return (
    <div className="cashoutgrid">
      <div className="headersgrid">
        <div>Transcation ID</div>
        <div>Amount</div>
        <div>Date</div>
        <div className="center">Status</div>
      </div>
      <div className="items">
        {cashoutData.map((data) => {
          const formattedDate = format(
            new Date(data.date.seconds * 1000),
            "yyyy-MM-dd HH:mm:ss"
          );
          return (
            <div className="item" key={data.id}>
              <div>{data?.id}</div>
              <div>
                {sign[localCurrency]}
                {(data.amount / 100).toFixed(2)}
              </div>
              <div>
                {new Date(data.date.seconds * 1000).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </div>
              <div className={`center ${statusColors[(data?.status)]}`}>
                {data.status}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
