import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import _, { isNull } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import "../css/Modal.scoped.css";
import CloseDark from "../../assets/closedark.svg";
import Close from "../../assets/close.svg";

// import PDFViewer from "pdf-viewer-reactjs";

export default function PDFViewer({
  dark,
  isOpen,
  toggleState,
  data,
  setData,
}) {
  // console.log(data);

  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    (async () => {
      console.log("inside cleanup, data:", data);
      if (data) {
        console.log("in side uf");
        // let bs64 = await getBase64(data);
        var reader = new FileReader();
        reader.readAsDataURL(data);
        // Read file content on file loaded event
        reader.onload = function (event) {
          setPdf(event.target.result);
          // resolve(event.target.result);
        };

        // Convert data to base64
        //   reader.readAsDataURL(file);
        //   console.log("converted to base64");
        //   console.log(bs64);
        //   setPdf(bs64);
      }
    })();
  }, [data]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    maxHeight: "90vh",
    overflow: "auto",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleClose = () => {
    toggleState(false);
    setData(null);
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}

      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>Viewing Uploaded PDF</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>
            {pdf && (
              <div className="jc-ce">
                <embed
                  src={pdf}
                  navpanes="0"
                  height="700"
                  width="600"
                  title="ticket pdf"
                />
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const getBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
