import { toast } from "react-toastify";
import "../css/ToastNotification.scoped.css";

export const SuccessToast = (label, msg) =>
  toast.success(
    <div>
      <div className="label">{label}</div>
      <div className="msg">{msg}</div>
    </div>
  );

export const ErrorToast = (label, msg) =>
  toast.error(
    <div>
      <div className="label">{label}</div>
      <div className="msg">{msg}</div>
    </div>
  );
