import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "../index";

export default async function editExpense(storeId, expenseId, data) {
  //data.name
  //data.name
  if (!data.name || data.name.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  if (!data.spentDate) {
    throw new Error("Spent Date is not a valid value.");
  }

  if (isNaN(data.amountSpent) || !data.amountSpent || data.amountSpent < 0) {
    throw new Error("Invalid amount spent provided.");
  }

  if (!data.quantity) {
    throw new Error("Please provide quantity");
  }
  if (data.quantity) {
    if (isNaN(data.quantity) || data.quantity < 0) {
      throw new Error("Invalid quantity value.");
    }
  }

  if (!data.date) {
    throw new Error("Date is not a valid value.");
  }

  if (isNaN(Date.parse(data.date))) {
    throw new Error("Not a valid date");
  }

  if (new Date(data.date).getTime() < new Date("1999-12-31")) {
    throw new Error("Not a valid date");
  }

  if (isNaN(Date.parse(data.spentDate))) {
    throw new Error("Not a valid spent date");
  }

  if (new Date(data.spentDate).getTime() < new Date("1999-12-31")) {
    throw new Error("Not a valid spent date");
  }

  const docRef = doc(db, "Stores", storeId, "Expenses", expenseId);
  const docSnap = await getDoc(docRef);

  const counterRef = doc(db, "Stores", storeId);

  const decrementValue = increment(
    // data.amountSpent - docSnap.data().amountSpent
    data.amountSpent
  );

  await updateDoc(counterRef, { expenseValue: decrementValue });

  return updateDoc(docRef, { ...data }, { merge: true });
}
