import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { parseInt } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import Delete from "../../assets/delete.svg";
import DeleteDark from "../../assets/deletedark.svg";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import createExpense from "../../firebase/expense/createExpense";
import "../css/Modal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import Select from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";
import Popup from "../general/Popup";
import requestCashout from "../../functions/wallet/requestCashout";
import { tr } from "date-fns/locale";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import getEnvValue from "../utils/getEnvValue";

export default function CashOutModal({
  dark,
  bankAccounts,
  totalBalance,
  availableCashout,
  penalty,
  shows,
}) {
  let localCurrency = localStorage.getItem("currency");
  const { sign, rates } = useContext(CurrencyContext);

  const initialValues = {
    paymentMethod: "",
    cashOutType: "",
    show: "",
  };
  const [fields, setFields] = useState(initialValues);

  const [totalCreditAmount, setTotalCreditAmount] = useState(0);

  useEffect(() => {
    (async () => {
      const txSnap = await getDocs(
        collection(
          db,
          "Stores",
          StoreId,
          "Wallet",
          getEnvValue("REACT_APP_MODE"),
          "Transactions",
          fields.show,
          "Transactions"
        )
      );

      const data = txSnap.docs.map((doc) => doc.data());

      const sum = data.reduce((acc, cur) => acc + cur.creditAmount, 0);

      setTotalCreditAmount(sum / 100);
    })();
  }, [fields.show]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "48rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  const [isDisabled, updateDisabled] = useState(false);
  const [loading, toggleLoading] = useState(false);

  const handleChange = (e) => {
    // console.log(fields);
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const clearValues = () => {
    setFields(initialValues);
  };

  const [open, setOpen] = React.useState(false);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupText, setPopupText] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTotalCreditAmount(0);
    setFields(initialValues);
  };

  let token = localStorage.getItem("token");

  const cashOutOptions = [
    {
      label: translate("1 Working Day Cash Out"),
      value: "1 Working Day",
      fees: 2,
    },
    {
      label: translate("14 Working Day Cash Out"),
      value: "14 Working Day",
      fees: 0,
    },
  ];

  const bankAccountsList = bankAccounts.map((bankAccount) => {
    return {
      label: `${bankAccount?.paymentMethod} - ${bankAccount?.email}`,
      value: bankAccount?.id,
    };
  });

  let fees =
    totalCreditAmount *
    (Number(
      cashOutOptions.find((e) => e.value === fields.cashOutType)?.fees ?? 2
    ) /
      100);

  return (
    <div>
      <Button
        customClass={"cashout"}
        onClick={() => {
          if (bankAccounts.length <= 0) {
            setPopupText("Please add a payout method");
            setPopupOpen(true);
            return;
          }

          // if (availableCashout <= 0) {
          //   setPopupText("Not enoungh balance available to cash out");
          //   setPopupOpen(true);
          //   return;
          // }
          handleOpen();
        }}
        text={translate("Cash Out")}
      />
      <Popup
        text={popupText}
        show={popupOpen}
        setShow={setPopupOpen}
        dark={dark}
      />
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="header">
              <div>{translate("Requesting Cash Out")}...</div>
              <img
                onClick={handleClose}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="three-grid">
              <div className={"span2"}>
                <div className="label">{translate("Select Show")}</div>
                <Select
                  dark={dark}
                  name="show"
                  value={fields.show}
                  updateValue={handleChange}
                  options={shows}
                  placeholderText={"Choose show"}
                  none={false}
                />
              </div>
            </div>

            <div className="three-grid">
              <div className={"span2"}>
                <div className="label">{translate("Payout Method")}</div>
                <Select
                  dark={dark}
                  name="paymentMethod"
                  value={fields.paymentMethod}
                  updateValue={handleChange}
                  options={bankAccountsList}
                  placeholderText={GetTranslateString("Choose payout method")}
                  none={false}
                />
              </div>

              <div>
                <div className="note purple">
                  Note: Payout for tickets will occur after 3 working days of
                  concert being over for it to be in your payout.
                </div>
              </div>
            </div>

            <div className="three-grid">
              <div className={"span2"}>
                <div className="label">{translate("Cash Out Type")}</div>
                <Select
                  dark={dark}
                  name="cashOutType"
                  value={fields.cashOutType}
                  updateValue={handleChange}
                  options={cashOutOptions}
                  placeholderText={GetTranslateString("Choose cash out type")}
                  none={false}
                />
                <div>
                  <div className=" label note red">
                    <span>1 working day = 2% Fee of Cash Out Amount</span>
                    <span>14 working days = No Fee</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="breakup">
              <div className="fees">
                <div>Amount</div>
                <div className="green">
                  {sign[localCurrency]}
                  {totalCreditAmount}
                </div>
              </div>
              <div className="fees">
                <div>
                  {fields.cashOutType || "1 working day"} - Service Fees (
                  {Number(
                    cashOutOptions.find((e) => e.value === fields.cashOutType)
                      ?.fees ?? 2
                  ).toFixed(1)}
                  %)
                </div>
                <div className="red" style={{ fontWeight: "300" }}>
                  -{sign[localCurrency]}
                  {fees.toFixed(2)}
                </div>
              </div>

              <div className="fees">
                <div>Penalty amount</div>
                <div className="red" style={{ fontWeight: "300" }}>
                  {sign[localCurrency]}
                  {penalty.toFixed(2)}
                </div>
              </div>

              <div className="fees">
                <div>New wallet balance</div>
                <div className="purple" style={{ fontWeight: "300" }}>
                  {sign[localCurrency]}
                  {(totalBalance - totalCreditAmount).toFixed(2)}
                </div>
              </div>

              <div className="fees total mt-10">
                <div className="total">Cash Out Amount</div>
                <div className="green">
                  {sign[localCurrency]}
                  {(totalCreditAmount - (fees + penalty)).toFixed(2)}
                </div>
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>

              <div className="ml-10">
                <Button
                  customClass={"conf-cashout"}
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    try {
                      toggleLoading(true);
                      updateDisabled(true);
                      if (totalCreditAmount <= 0) {
                        throw new Error(
                          "Not enough balance available to cashout"
                        );
                      }

                      const resp = await requestCashout(
                        token,
                        fields.show,
                        fields.paymentMethod,
                        fields.cashOutType
                          ? fields.cashOutType?.split(" ")[0]
                          : "",

                        totalCreditAmount - fees
                      );
                    } catch (e) {
                      // alert(e.name);
                      console.log(e);
                      if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error
                      ) {
                        ErrorToast("Error", e.response.data.error);
                      } else {
                        ErrorToast(
                          "Error",
                          "Some error occurred while requesting cash out"
                        );
                      }
                      toggleLoading(false);
                      updateDisabled(false);
                      return;
                    }
                    SuccessToast(
                      translate("success"),
                      translate("Cash out request sent")
                    );
                    handleClose();
                    clearValues();
                    updateDisabled(false);
                    toggleLoading(false);
                  }}
                  text={translate("Confirm Cash Out")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
