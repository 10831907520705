import { getDocs, query, collection, orderBy } from "firebase/firestore";
import getEnvValue from "../../components/utils/getEnvValue";

export default async function profitLossGraph(ticketsData) {
  // console.log("in prl graph", ticketsData);
  let result = {};

  let count = 0,
    total = 0;
  for (let data of ticketsData.sort(
    (a, b) => new Date(b.soldDate).getTime() - new Date(a.soldDate).getTime()
  )) {
    let date = new Date(data.purchasedDate);
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = date.getFullYear();

    date = yyyy + "-" + mm + "-" + dd;

    if (result[date] === undefined) {
      result[date] =
        (data.price * (1 - getEnvValue("REACT_APP_SELLER_FEES") / 100) -
          data.purchasedPrice) *
        data.quantity;
    } else {
      result[date] +=
        (data.price * (1 - getEnvValue("REACT_APP_SELLER_FEES") / 100) -
          data.purchasedPrice) *
        data.quantity;
    }
    result[date] = parseFloat(result[date].toFixed(2));
    count++;
    total += result[date];
  }
  return { count, total, result };
}
