import { getDocs, query, collection, orderBy, where } from "firebase/firestore";
import getEnvValue from "../../components/utils/getEnvValue";
import axios from "axios";
import { TicketStatus } from "../../Constants";

export default async function inventoryGraph(ticketsData) {
  //   console.log(ticketsData);
  let result = {};
  let count = 0,
    total = 0;
  for (let data of ticketsData
    // .filter((e) => e.status !== TicketStatus.SOLD)
    .sort(
      (a, b) =>
        new Date(b.purchasedDate).getTime() -
        new Date(a.purchasedDate).getTime()
    )) {
    let purDate = data.purchasedDate.split("T")[0];
    if (result[purDate] === undefined) {
      result[purDate] = data.purchasedPrice * data.seatNumbers?.length;
    } else {
      result[purDate] += data.purchasedPrice * data.seatNumbers?.length;
    }
    result[purDate] = parseFloat(result[purDate].toFixed(2));
    count += data.seatNumbers?.length;
    total += result[purDate];
  }

  return { count, total, result };
}
