import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import "../css/Popup.scoped.css";
import Fade from "@mui/material/Fade";

export default function Popup({ text, show, setShow, dark }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "30.25rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",

    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  return (
    <Modal
      className={`modal ${dark ? "dark" : ""}`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={show}
      onClose={() => setShow(false)}
      closeAfterTransition
    >
      <Fade in={show}>
        <Box sx={style}>
          <div className={"popup"}>
            <div className={"text"}>{text}</div>
            <div className={"ok-btn"} onClick={() => setShow(false)}>
              Ok
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
