import "../css/ProductInfo.scoped.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/index";
import { StoreId } from "../../Constants";
import { useEffect, useState } from "react";
import translate from "../../i18n/translate";
const Categories = ({ blur, contact }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const inventoryListener = onSnapshot(
      doc(db, "Stores", StoreId),
      async (snapShot) => {
        setData({
          ...snapShot.data(),
          id: snapShot.id,
        });

        // const storeData = snapShot.docs.map((e) => {
        //   return {
        //     ...e.data(),
        //     id: e.id,
        //   };
        // });
        // setData(storeData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  return (
    <div className="categories home-categories">
      <div>
        <div className="twogrid-section al-ce jc-sp mt-30">
          <div className="flex al-ce">
            <div className="query bold">{translate("storeName")} :</div>
            <div className="value">{data?.name}</div>
          </div>
          {/* <div className="flex al-ce">
            <div className="query bold">{translate("storeId")}:</div>
            <div className="value">{StoreId}</div>
          </div> */}
        </div>
        <div className="twogrid-section al-ce jc-sp mt-30">
          {/* <div className="flex al-ce">
            <div className="query bold">{translate("storeName")} :</div>
            <div className="value">{data?.name}</div>
          </div> */}
          <div className="flex al-ce">
            <div className="query bold">{translate("storeId")}:</div>
            <div className="value">{StoreId}</div>
          </div>
        </div>

        {contact && (
          <div className="twogrid-section al-ce jc-sp mt-30">
            <div className="flex al-ce ">
              <div className="query bold">{translate("owner")} :</div>
              <div className={`value ${blur}`}>{data?.fullName}</div>
            </div>
            <div className="flex al-ce">
              <div className="query bold">Contact:</div>
              <div className="value">{contact}</div>
            </div>
          </div>
        )}

        {!contact && (
          <div className="twogrid-section al-ce jc-sp mt-30">
            <div className="flex al-ce">
              <div className="query bold">{translate("owner")} :</div>
              <div className={`value ${blur}`}>{data?.fullName}</div>
            </div>
            {/* <div className="flex al-ce">
              <div className="query bold">Plan Type:</div>
              <div className="value">Resale Plan</div>
            </div> */}
          </div>
        )}

        <div className="flex al-ce mt-30">
          <div className="query bold">{translate("country")} :</div>
          <div className={`value ${blur}`}>
            {data?.country ? data?.country : "-"}
          </div>
        </div>
        <div className="flex al-ce mt-30">
          <div className="query bold">{translate("email")}:</div>
          <div className={`value ${blur}`}>{data?.email}</div>
        </div>
        <div className="flex al-ce mt-30">
          <div className="query bold">{translate("dateJoined")}:</div>
          <div className={`value ${blur}`}>
            {new Date(data.dateJoined)
              .toDateString()
              .split(" ")
              .slice(1, 4)
              .join(" ")}
          </div>
        </div>
      </div>

      <div className="categories-section mt-18">
        <div className="bold text-center">{translate("categories")}:</div>
        <div className="flex al-ce jc-ce">
          {/* <div className="smbox flex al-ce jc-ce blue">sneakers</div> */}
          <div className="smbox flex al-ce jc-ce brown cat">tickets</div>
          {/* <div className="smbox flex al-ce jc-ce brown">collectibles</div>
          <div className="smbox flex al-ce jc-ce light-brown">electronics</div>
          <div className="smbox flex al-ce jc-ce pink">arts</div>
          <div className="smbox flex al-ce jc-ce yellow">handbags</div>
          <div className="smbox flex al-ce jc-ce light-green">watches</div>
          <div className="smbox flex al-ce jc-ce light-blue">streetwear</div>
          <div className="smbox flex al-ce jc-ce dark-brown">vehicles</div> */}
        </div>
      </div>
    </div>
  );
};

export default Categories;
