import { LOCALES } from "../locales";

let ko = {
  [LOCALES.KOREAN]: {
    hello: "안녕하세요",
    owner: "소유자",
    country: "국가",
    storeName: "판매장명",
    storeId: "판매장 ID",
    email: "이메일",
    dateJoined: "가입 날짜",
    categories: "카테고리",
    profitsLosses: "이익/손실",
    totalExpenses: "총 비용",
    totalInventory: "총 인벤토리",
    discountSummary: "할인 요약",
    staffSummary: "직원 요약",
    staffDetails: "직원 세부 정보",
    workingPerformance: "근무성적",
    clockInAndOutDetails: "출퇴근기록 세부 정보",
    storeInformations: "판매장 정보",
    staffs: "직원",
    dashboard: "대시보드",
    marketLookUp: "마켓 조회",
    inventory: "인벤토리",
    productLabels: "제품 라벨",
    consignment: "위탁",
    sales: "매상",
    expenses: "비용",
    settings: "설정",
    marketSales: "마텟 세일",
    total: "합계",
    name: "이름",
    enterName: "이름을 입력하세요",
    enterId: "ID를 입력하세요",
    enterDate: "날짜 입력",
    enterAddress: "주소를 입력하세요",
    enterCountry: "국가를 입력하세요",
    enterCity: "도시를 입력하세요",
    enterEmail: "이메일울 입력하세요",
    enterContactNo: "연락처를 입력하세요",
    enterStyleCode: "스타일 코드를 입력하세요",
    enterAmount: "E수량을 입력하세요",
    enterStocks: "재고를 입력하세요",
    enterPercentage: "%를 입력하세요",
    enterReq: "요구 사항을 입력하세요",
    enterStartDate: "시작 날짜 입력",
    enterEndDate: "종료 날짜 입력",
    enterCode: "코드를 입력하세요",
    enterStartTime: "시작 시간을 입력하세요",
    enterEndTime: "종료 시간을 입력하세요",
    autoDetect: "자동 감지",
    enterLast4Digits: "마지막 4자리를 입력하세요",
    enterNumber: "번호를 입력하세요",
    enterIfInstore: "매장 보유 중인 경우 입력하세요",
    shippingInfo: "베송 정보",
    enterInfo: "정보를 입력하세요",
    enterNotes: "비고를 입력하세요",
    enterTitle: "제목을 입력하세요",
    enterUrl: "URL을 입력하세요",
    id: "ID",
    totalSales: "총 매상",
    transactionHistory: "거래 내역",
    date: "날짜",
    delete: "삭제",
    items: "아이템",
    price: "판매가",
    requirements: "요구 사항",
    eligibility: "적격",
    status: "상태",
    used: "사용됨",
    date: "날짜",
    code: "코드",
    value: "가치",
    clockedInTime: "클락인 시간",
    clockedOutTime: "클락 아웃 시간",
    totalHours: "총 시간",
    numberOfSales: "판매량",
    figures: "피겨스",
    timeIn: "타임인",
    timeOut: "타임아웃",
    staffName: "타임아웃",
    staffId: "직원 ID",
    totalFigures: "총 피겨스",
    totalNumberOfSales: "총 판매량",
    workingPeriodAssigned: "배정된 근무시간",
    dailyWorkingHours: "일일 근무 시간",
    weeklyWorkingHours: "주간 근무 시간",
    monthlyWorkingHours: "월간 근무 시간",
    yearlyWorkingHours: "연간 근무 시간",
    website: "웹사이트",
    priceHistory: "판매가 이력",
    productDetails: "제품 상세 정보",
    viewMarketSales: "마켓 매상 보기",
    market: "마켓",
    comparison: "비교",
    productInfo: "제품 정보",
    newSiteComingSoon: "새로운 사이트가 곧 출시될 예정입니다.",
    comparing: "비교 중",
    style: "스타일",
    size: "사이즈",
    condition: "조건",
    purchasedPrice: "구입 가격",
    purchasedDate: "구입 날짜",
    platform: "플랫폼",
    lastSale: "최종 세일",
    markUp: "마크 업",
    finalPrice: "최종 판매가",
    unrealized: "미실현",
    totalValue: "총 가치",
    summary: "요약",
    totalItems: "총 아이템",
    totalUnrealizedProfit: "총 미실현 이익",
    inventoryTrackingCode: "인벤토리 추적코드",
    qrCode: "QR 코드",
    qrCodeSettings: "QR 코드 설정",
    footerBackground: "바닥글 배경",
    logo: "로고",
    background: "배경",
    accentColour: "강조색",
    boxBackgroundColor: "상자 배경색",
    fontColor: "글자색",
    sizing: "사이징",
    currency: "통화",
    save: "저장",
    barcodeTags: "바코드 택",
    lastScannedDate: "최종 스캔 날짜",
    scanned: "스캔되었음",
    sold: "판매되었음",
    resetScan: "스캔 재설정",
    missingItem: "누락 아이템",
    scanItem: "스캔 아이템",
    search: "검색",
    refresh: "새로 고침",
    printAll: "전체 인쇄",
    default: "기본",
    totalConsignmentSales: "총 위탁매출",
    consignmentSalesSummary: "위탁매출 요약",
    consignmentPopularCategories: "위탁 인기 카테고리",
    consigneeOverviews: "컨사이니 개요",
    contactNo: "연락처",
    city: "도시",
    payoutDate: "지불 날짜",
    payoutPrice: "지불 가격",
    dateIn: "데이트 인",
    listingPrice: "정가",
    fee: "수수료",
    consigneeId: "컨사이니 ID",
    itemName: "아이템명", 
    firstNameAndLastName: "이름과 성",
    codeName: "코드명",
    notes: "비고",
    spentDate: "송부 날짜",
    amountSpent: "송부 수량",
    duration: "기간",
    shipping: "배송",
    tax: "택스",
    quantity: "수량",
    expenseName: "비용명",
    customerName: "고객 이름",
    staffNavigationBarSettings: "직원의 내비게이션 바 설정",
    mode: "모드",
    specificStaffPermission: "특정 직원의 권한",
    generalStaffsPermissions: "일반 직원의 권한",
    material1: "1 머티리얼",
    material2: "2 머티리얼",
    salesSummary: "매상 요약",
    growth: "성장",
    pos: "POS",
    receiptSettings: "영수증 설정",
    purchase: "구매",
    preview: "미리보기",
    cart: "장바구니",
    orderId: "주문 ID",
    sku: "SKU",
    searchsku: "Sku 검색",
    item: "아이템",
    totalPayout: "총 지불금",
    totalProfit: "총 이익",
    customerSideScreen: "고객편의 화면",
    addDiscountCode: "할인 코드 추가하기",
    discountAmount: "할인 수량",
    subTotal: "소계",
    discount: "할인",
    saleTax: "판매세",
    thankyouForYourPurchase: "구매해주셔서 감사합니다",
    welcomeBack: "다시 오신 것을 환영합니다",
    customerViewingScreenSettings: "고객 보기 화면 설정",
    dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton: "대시보드 - 매장 정보 - 할인코드 생성/수정/삭제 버튼 숨기기",
    inventoryHideImportButton: "I인벤토리 - 가져오기 버튼 숨기기",
    inventoryHideDownloadButton: "I인벤토리 - 다운로드 버튼 숨기기",
    inventoryHideAddNewEditDeleteButton: "인벤토리 - 신규 추가/수정/삭제 버튼 숨기기",
    productLabelsHideqrCodeSettingsPage: "제품 라벨 - QR 코드 설정 페이지 숨기기",
    consignmentsSummaryHidePayoutConfirmationButton: "위탁 - 요약 - 지불 확인 버튼 숨기기",
    consignmentsConsigneeHideAddNewEditDeleteButton: "위탁 - 컨사이니 - 신규 추가 / 수정 / 삭제 버튼 숨기기",
    consignmentsInventoryHideAddNewEditDeleteButton: "위탁 - 인벤터리 -  신규 추가/수정/삭제 버튼 숨기기",
    salesHideRecieptSettingsPage: "세일 - 영수증 설정 페이지 숨기기",
    hideExpensesPage: "비용 페이지 숨기기",
    title: "제목",
    saveItems: "아이템 수정하기",
    settingOptions: "설정 옵션",
    addNew: "신규 추가하기",
    enterCode: "코드를 입력하세요",
    pay: "결제하기",
    addToCart: "장바구니",
    import: "가져오기",
    data: "데이터",
    searchSku: "SKU 검색",
    recommendedSize: "추천 사이즈",
    lastSalePrice: "최종 세일 가격",
    currency: "통화",
    retailPrice: "소매 가격",
    language: "언어",
    pricePremium: "프리미엄 가격",
    addingNewConsignmentItems: "신규 위탁 아이템 추가 중...",
    editingConsignmentItems: "위탁 아이템 수정 중...",
    consignmentSalesDetails: "위탁 판매 내역",
    soldDate: "판매 날짜",
    soldBy: "에 의해 판매되었습니다",
    sizes: "사이즈",
    bestSizes: "최고의 사이즈",
    dateJoin: "가입 날짜",
    startTime: "시작 시간",
    endTime: "종료 시간",
    addingNewConsignee: "신규 컨사이니 추가 중......",
    totalStores: "총 판매장",
    staffOverviews: "직원 개요",
    edit: "수정",
    addStaff: "직원 추가",
    inLastMonth: "지난 달에",
    logOut: "로그아웃t",
    itemsSold: "아이템이 판매되었습니다",
    stockTags: "재고 태그",
    daily: "일일",
    weekly: "주간",
    monthly: "월간",
    yearly: "연간",
    active: "활동적인",
    onLeave: "부재중",
    resigned: "퇴직했음",
    addingNewStaff: "새 직원 추가 중...",
    monday: "월요일",
    tuesday: "화요일",
    wednesday: "수요일",
    thursday: "목요일",
    friday: "금요일",
    saturday: "토요일",
    sunday: "일요일",
    creatingDiscountCode: "할인 코드 생성 중",
    type: "유형",
    stocks: "재고",
    specificproduct: "특정 상품",
    eligibility: "적격",
    startDate: "시작 날짜",
    endDate: "종료 날짜",
    startTime: "시작 시간",
    endTime: "종료 시간",
    onePerCustomer: "한 고객당 하나",
    twoPerCustomer: "한 고객당 둘",
    threePerCustomer: "한 고객당 셋",
    onAllProducts: "전체 상품에 대하여",
    onSpecificCategories: "특정 카테고리에 대하여",
    onSpecificProducts: "특정 제품에 대하여",
    carousell: "Carousell",
    goat: "Goat",
    sneakers: "운동화",
    collectibles: "수집품",
    arts: "미술",
    streetwears: "스트릿 패션",
    addingNewInventoryItems: "신규 인벤토리 아이템 추가 중",
    custom: "커스텀",
    editingItem: "아이템 수정 중",
    address: "주소",
    contact: "연락처",
    firstAndLastName: "이름과 성",
    editingConsignee: "E컨사이니 수정 중",
    customerPreviews: "고객 미리보기",
    recommendedProducts: "추천 상품",
    findOutMore: "더 알아보기",
    editingExpenseItems: "비용 아이템 수정 중",
    transactionId: "거래 ID",
    oneTime: "원타임",
    light: "밝음",
    dark: "어두움",
    popularCategories: "인기 카테고리",
    paymentMethod: "결제 방법",
    payout: "지불하기",
    trending: "트렌딩",
    clearAll: "전체 지우기",
    editItem: "아이템 수정하기",
    editStore: "판매장 수정하기",
    success: "완료되었습니다",
    consigneeCreated: "컨사이니가 생성되었습니다.",
    error: "에러",
    itemEdited:"아이템이 수정되었습니다.",                         
    itemCreated:"아이템이 생성되었습니다.",
    clockedIn:"클럭인되었습니다.",
    clockedOut:"클럭아웃되었습니다.",
    storeLockedSuccessfully:"판매장이 잠금되었습니다.",
    storeUnlockedSuccessfully:"판매장이 잠금 해제되었습니다.",
    storeEdited:"판매장이 수정되었습니다.",
    storeCreated:"판매장이 생성되었습니다.",
    discountCodeCreated:"할인 코드가 생성되었습니다.",
    staffAdded:"직원이 추가되었습니다",
    codeEdited:"코드가 수정되었습니다.",
    staffEdited:"직원이 수정되었습니다",
    expenseCreated:"비용이 생성되었습니다.",
    logoUpdated:"로고가 업데이트되었습니다",
    defaultSettings:"기본 설정.",
    updated:"업데이트되었습니다",
    customSettings:"커스텀 설정.",
    detailsUpdated:"상세 내용이 업데이트되었습니다",
    verified:"확인되었습니다",
    discountApplied:"할인이 적용되었습니다.",
    failed:"실패하였습니다",
    invalidDiscountCode:"유효하지 않은 할인코드입니다.",
    paymentSuccessful:"결제가 완료되었습니다.",
    successfullyUpdated: "업데이트되었습니다",
    settingsSaved: "설정이 저장되었습니다.",
    alreadyClockedIn: "미리 클럭인했습니다.",
    alreadyClockedOut: "미리 클럭아웃했습니다.",
    pleaseSelectAStore: "한 매장을 선택하세요.",
    invalidDiscountCode: "유효하지 않은 할인코드입니다.",
    cartCannotBeEmpty: "장바구니에 담긴 상품이 있어야 합니다.",
    emptyDiscountField: "할인 필드가 비어 있습니다.",
    cartempty : "장바구니가 비어 있습니다.",
    paymentUnsuccessful: "결제가 완료되었습니다",
    addToCartFailed: "장바구니담기가 실패했습니다 ",
    storeDoesNotExist: "해당 매장이 존재하지 않습니다",
    selectAStaff: "한 직원을 선택하세요.",
    pleaseSelectAStaff: "한 직원을 선택하세요.",
    pleaseTickCheckbox: "체크해주세요.",
    vacantInput: "비어 있습니다.",
    consignee: "수취인",
    salesDetails: "판매내역",
    fulfilled: "이행",
    editingDiscountCode: "할인 코드 편집",
    editingStaff: "편집 직원",
    editingStore: "편집 스토어",
    download: "다운로드",
    inCart: "장바구니에 담기",
    choose: "선택하다",
    chooseSize: "사이즈 선택",
    chooseCategories: "카테고리 선택",
    choosePlatform: "플랫폼 선택",
    chooseCondition: "조건 선택",
    chooseCurrency: "통화 선택",
    chooseMethod: "방법 선택",
    chooseLanguage: "언어 선택",
    chooseProducts: "제품 선택",
    chooseEligibility: "적격성 선택",
    chooseDuration: "기간 선택",
    chooseMode: "모드 선택",
    selectColour: "색상 선택",
    all: "모두",
    expired: "만료됨",
    scheduled: "예정",
    createCode: "코드 생성",
    highestBid: "최고 입찰가",
    lowestAsk: "최저 질문",
    missingItems: "누락된 항목",
    print: "인쇄",
    consigneesSummary: "수취인 요약",
    editingConsignee : "수취인 편집",
    dateSold: "판매 날짜",
    addingNewExpense: "신규 비용 추가",
    details: "세부",
    verify: "확인",
    staff: "직원",
    time: "시간",
    oneMonth: "1 개월",
    threeMonths: "3 개월",
    sixMonths: "6 개월",
    marketIdentifier: "시장 식별자",
    new: "새로운",
    confirmingPayment: "결제 확인",
    customerEmail: "고객 이메일",
    cash: "현금",
    cheque: "수표",
    creditCard: "신용 카드",
    payNow: "지금 지불하세요",
    ccLast4Digits: "CC 마지막 4자리",
    amountReceived: "받은 금액",
    change: "변화",
    inStore: "매장에서",
    delivery: "배달",
    shippingAddress: "배송 주소",
    ofTotal: "총계",
    deleting: "삭제 중",
    confirmToDeleteThis: "예, 확인",
    yesConfirm: "예, 확인",
    noThinkAgain: "아니, 다시 생각해봐",
    purchasedBy: "구매한 사람",
  },
};

export default ko;
