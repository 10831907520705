import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import DownIcon from "../../assets/down.svg";
import DownIconDark from "../../assets/downdark.svg";
import "../css/Select.scoped.css";

export default function SelectLabels({
  name,
  disabled,
  value,
  updateValue,
  options,
  render,
  placeholderText,
  none,
  dark,
}) {
  const [isOpen, updateIsOpen] = useState(false);

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: dark ? "#5A5D74" : "#abafd1",
      fontWeight: 300,
      fontSize: "0.9375rem",
      lineHeight: "1.375rem",
      fontFamily: "Poppins",
      maxWidth: "74%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }));

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    menuPaper: {
      backgroundColor: dark ? "#3B435E" : "#fff",
      maxHeight: "200px !important",
    },
  }));

  const classes = useStyles();
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  return (
    <div>
      <FormControl
        sx={{ m: 1, minWidth: "100%" }}
        className="formControl"
        id="simple-select-form"
      >
        <Select
          style={{ color: dark ? "#f6f7ff" : "#323A52" }}
          disabled={disabled}
          id="simple-select"
          name={name}
          displayEmpty
          value={value}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
          onChange={(e) => updateValue(e)}
          inputProps={{
            "aria-label": "Without label",
          }}
          renderValue={
            value !== ""
              ? render
              : () => <Placeholder>{placeholderText}</Placeholder>
          }
          onOpen={() => updateIsOpen(true)}
          onClose={() => updateIsOpen(false)}
          IconComponent={() => (
            <img
              style={{
                position: "absolute",
                pointerEvents: "none",
                right: "0.9375rem",
                transition: `all ease .2s`,
                transform: `rotate(${isOpen ? 180 : 0}deg)`,
              }}
              alt="down"
              src={!dark ? DownIcon : DownIconDark}
            />
          )}
        >
          {none && (
            <MenuItem value="" className="menuitem">
              <span>None</span>
            </MenuItem>
          )}
          {options.map((option, i) => (
            <MenuItem
              key={i}
              style={{ color: dark ? "#f6f7ff" : "#abafd1" }}
              value={option.value}
              className="menuitem"
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
