import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React from "react";
import { StoreId } from "../../Constants";
import "../css/DeleteConfirm.scoped.css";
import translate from "../../i18n/translate";
import { ErrorToast, SuccessToast } from "./ToastNotification";

export default function DeleteConfirm({
  isOpen,
  toggleOpen,
  deleteId,
  setDeleteId,
  func,
  msg,
  customFunc,
  addFunc,
  dark,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "31.875rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };
  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => toggleOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="container">
              <div className="red bold">
                {translate("deleting")} {translate(msg.toLowerCase())}...
              </div>
              <div className="bold center">
                {translate("confirmToDeleteThis")}{" "}
                {translate(msg.toLowerCase())}?
              </div>
              <div className="twogrid">
                <div
                  className="green btn confirm"
                  onClick={() => {
                    try {
                      if (customFunc) {
                        customFunc();
                      } else {
                        func(StoreId, deleteId);
                        if (addFunc) {
                          addFunc();
                        }
                        setDeleteId(null);
                        toggleOpen(false);
                      }
                      SuccessToast(
                        "Success",
                        `${msg} has been deleted successfully`
                      );
                    } catch (e) {
                      console.log(e);
                      if (e.name === "AxiosError") {
                        ErrorToast("Error", e?.response?.data.error);
                      } else {
                        ErrorToast(
                          "Error",
                          "Some error occurred while deleting the " + msg
                        );
                      }
                    }
                  }}
                >
                  {translate("yesConfirm")}
                </div>
                <div
                  className="red btn cancel"
                  onClick={() => {
                    toggleOpen(false);
                    setDeleteId(null);
                  }}
                >
                  {translate("noThinkAgain")}
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
