import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import getEnvValue from "../../components/utils/getEnvValue";
import { StoreId } from "../../Constants";
import { db } from "../../firebase";

export default async function placeTicketAsk(token, ticketId, price) {
  if (!token) {
    throw new Error("Token not provided");
  }

  if (!ticketId) {
    throw new Error("Ticket id not provided");
  }

  if (!price || price < 0) {
    throw new Error("Invalid platform price given");
  }

  const resp = await axios.post(
    `${getEnvValue("REACT_APP_TICKETS_API")}/tickets/list`,
    {
      // const resp = await axios.post("http://localhost:8080/tickets/list", {
      token,
      ticketId,
      price,
    }
  );

  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp.data;
}
