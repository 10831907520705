import { LOCALES } from "../locales";

let en = {
  [LOCALES.ENGLISH]: {
    hello: "Hello",
    owner: "Owner",
    country: "Country",
    storeName: "Store Name",
    storeId: "Store ID",
    email: "Email",
    dateJoined: "Date Joined",
    categories: "Categories",
    profitsLosses: "Profits/Losses",
    totalExpenses: "Total Expenses",
    totalInventory: "Total Inventory",
    discountSummary: "Discount Summary",
    staffSummary: "Staff Summary",
    staffDetails: "Staff Details",
    workingPerformance: "Working Performance",
    clockInAndOutDetails: "Clock In and Out Details",
    storeInformations: "Store Informations",
    staffs: "Staffs",
    dashboard: "Dashboard",
    marketLookUp: "Market Look Up",
    inventory: "Inventory",
    productLabels: "Product Labels",
    consignment: "Consignment",
    sales: "Sales",
    expenses: "Expenses",
    settings: "Settings",
    marketSales: "Market Sales",
    total: "Total",
    name: "Name",
    enterName: "Enter name",
    enterId: "Enter ID",
    enterDate: "Enter Date",
    enterAddress: "Enter address",
    enterCountry: "Enter country",
    enterCity: "Enter city",
    enterEmail: "Enter email",
    enterContactNo: "Enter contact no.",
    enterStyleCode: "Enter style code",
    enterAmount: "Enter Amount",
    enterStocks: "Enter stocks",
    enterPercentage: "Enter %",
    enterReq: "Enter requirements",
    enterStartDate: "Enter Start Date",
    enterEndDate: "Enter End Date",
    enterCode: "Enter code",
    enterStartTime: "Enter Start Time",
    enterEndTime: "Enter End Time",
    autoDetect: "Auto Detect",
    enterLast4Digits: "Enter last 4 digits",
    enterNumber: "Enter number",
    enterIfInstore: "Enter - if instore",
    shippingInfo: "Shipping Info",
    enterInfo: "Enter info",
    enterNotes: "Enter notes",
    enterTitle: "Enter Title",
    enterUrl: "Enter Url",
    id: "ID",
    totalSales: "Total Sales",
    transactionHistory: "Transaction History",
    date: "Date",
    delete: "Delete",
    items: "Items",
    price: "Price",
    requirements: "Requirements",
    eligibility: "Eligibility",
    status: "Status",
    used: "Used",
    date: "Date",
    code: "Code",
    value: "Value",
    clockedInTime: "Clocked In Time",
    clockedOutTime: "Clocked Out Time",
    totalHours: "Total Hours",
    numberOfSales: "Number of Sales",
    figures: "Figures",
    timeIn: "Time In",
    timeOut: "Time Out",
    staffName: "Staff Name",
    staffId: "Staff ID",
    totalFigures: "Total Figures",
    totalNumberOfSales: "Total Number of Sales",
    workingPeriodAssigned: "Working Period Assigned",
    dailyWorkingHours: "Daily Working Hours",
    weeklyWorkingHours: "Weekly Working Hours",
    monthlyWorkingHours: "Monthly Working Hours",
    yearlyWorkingHours: "Yearly Working Hours",
    website: "Website",
    priceHistory: "Price History",
    productDetails: "Product Details",
    viewMarketSales: "View Market Sales",
    market: "Market",
    comparison: "Comparison",
    productInfo: "Product Info",
    newSiteComingSoon: "New Site Coming Soon",
    comparing: "Comparing",
    style: "Style",
    size: "Size",
    condition: "Condition",
    purchasedPrice: "Purchased Price",
    purchasedDate: "Purchased Date",
    platform: "Platform",
    lastSale: "Last Sale",
    markUp: "Mark Up",
    finalPrice: "Final Price",
    unrealized: "Unrealized",
    totalValue: "Total Value",
    summary: "Summary",
    totalItems: "Total Items",
    totalUnrealizedProfit: "Total Unrealized Profit",
    inventoryTrackingCode: "Inventory Tracking Code",
    qrCode: "QR Code",
    qrCodeSettings: "QR Code Settings",
    footerBackground: "Footer Background",
    logo: "Logo",
    background: "Background",
    accentColour: "Accent Colour",
    boxBackgroundColor: "Box Background Color",
    fontColor: "Font Color",
    sizing: "Sizing",
    currency: "Currency",
    save: "Save",
    barcodeTags: "Barcode Tags",
    lastScannedDate: "Last Scanned Date",
    scanned: "Scanned",
    sold: "Sold",
    resetScan: "Reset Scan",
    missingItem: "Missing Item",
    scanItem: "Scan Item",
    search: "Search",
    refresh: "Refresh",
    printAll: "Print All",
    default: "Default",
    totalConsignmentSales: "Total Consignment Sales",
    consignmentSalesSummary: "Consignment Sales Summary",
    consignmentPopularCategories: "Consignment Popular Categories",
    consigneeOverviews: "Consignee Overviews",
    contactNo: "Contact No.",
    city: "City",
    payoutDate: "Payout Date",
    payoutPrice: "Payout Price",
    dateIn: "Date In",
    listingPrice: "Listing Price",
    fee: "Fee",
    consigneeId: "Consignee ID",
    itemName: "Item Name", 
    firstNameAndLastName: "First and Last Name",
    codeName: "Code Name",
    notes: "Notes",
    spentDate: "Spent Date",
    amountSpent: "Amount Spent",
    duration: "Duration",
    shipping: "Shipping",
    tax: "Tax",
    quantity: "Quantity",
    expenseName: "Expense Name",
    customerName: "Customer Name",
    staffNavigationBarSettings: "Staff Navigation Bar Settings",
    mode: "Mode",
    specificStaffPermission: "Specific Staff Permission",
    generalStaffsPermissions: "General Staffs Permissions",
    material1: "Material 1",
    material2: "Material 2",
    salesSummary: "Sales Summary",
    growth: "Growth",
    pos: "POS",
    receiptSettings: "Receipt Settings",
    purchase: "PURCHASE",
    preview: "Preview",
    cart: "Cart",
    orderId: "Order ID",
    sku: "SKU",
    searchsku: "Search Sku",
    item: "Item",
    totalPayout: "Total Payout",
    totalProfit: "Total Profit",
    customerSideScreen: "Customer Side Screen",
    addDiscountCode: "Add Discount Code",
    discountAmount: "Discount Amount",
    subTotal: "Sub-Total",
    discount: "Discount",
    saleTax: "Sale Tax",
    thankyouForYourPurchase: "THANK FOR YOUR PURCHASE",
    welcomeBack: "Welcome Back",
    customerViewingScreenSettings: "Customer Viewing Screen Settings",
    dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton: "Dashboard - Store Information - Hide Create/Edit/Delete Discount Code Button",
    inventoryHideImportButton: "Inventory - Hide Import Button",
    inventoryHideDownloadButton: "Inventory - Hide Download Button",
    inventoryHideAddNewEditDeleteButton: "Inventory - Hide Add New/Edit/Delete Button",
    productLabelsHideqrCodeSettingsPage: "Product Labels - Hide QR Code Settings Page",
    consignmentsSummaryHidePayoutConfirmationButton: "Consignments - Summary - Hide Payout Confirmation Button",
    consignmentsConsigneeHideAddNewEditDeleteButton: "Consignments - Consignee - Hide Add New/Edit/Delete Button",
    consignmentsInventoryHideAddNewEditDeleteButton: "Consignments - Inventory - Hide Add New/Edit/Delete Button",
    salesHideRecieptSettingsPage: "Sales - Hide Reciept Settings Page",
    hideExpensesPage: "Hide Expenses Page",
    title: "Title",
    saveItems: "Save Items",
    settingOptions: "Setting Options",
    addNew: "Add New",
    enterCode: "Enter code",
    pay: "Pay",
    addToCart: "Add to Cart",
    import: "Import",
    data: "Data",
    searchSku: "Search SKU",
    recommendedSize: "Recommended Size",
    lastSalePrice: "Last Sale Price",
    currency: "Currency",
    retailPrice: "Retail Price",
    language: "Language",
    pricePremium: "Price Premium",
    addingNewConsignmentItems: "Adding New Consignment Items...",
    editingConsignmentItems: "Editing Consignment Items...",
    consignmentSalesDetails: "Consignment Sales Details",
    soldDate: "Sold Date",
    soldBy: "Sold By",
    sizes: "Sizes",
    bestSizes: "Best Sizes",
    dateJoin: "Date Join",
    startTime: "Start Time",
    endTime: "End Time",
    addingNewConsignee: "Adding New Consignee...",
    totalStores: "Total Stores",
    staffOverviews: "Staff Overviews",
    edit: "Edit",
    addStaff: "Add Staff",
    inLastMonth: "in last month",
    logOut: "Log Out",
    itemsSold: "items sold",
    stockTags: "Stock Tags",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    active: "Active",
    onLeave: "On Leave",
    resigned: "Resigned",
    addingNewStaff: "Adding New Staff...",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    creatingDiscountCode: "Creating Discount Code",
    type: "Type",
    stocks: "Stocks",
    specificproduct: "Specific Product",
    eligibility: "Eligibility",
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time",
    onePerCustomer: "one per customer",
    twoPerCustomer: "two per customer",
    threePerCustomer: "three per customer",
    onAllProducts: "On All Products",
    onSpecificCategories: "On Specific Categories",
    onSpecificProducts: "On Specific Products",
    sneakers: "Sneakers",
    collectibles: "Collectibles",
    arts: "Arts",
    streetwears: "Streetwears",
    addingNewInventoryItems: "Adding New Inventory Items",
    custom: "Custom",
    editingItem: "Editing Item",
    address: "Address",
    contact: "Contact",
    firstAndLastName: "First and Last Name",
    customerPreviews: "Customer Previews",
    recommendedProducts: "Recommended Products",
    findOutMore: "Find Out More",
    editingExpenseItems: "Editing Expense Items",
    transactionId: "Transaction ID",
    oneTime: "One Time",
    light: "Light",
    dark: "Dark",
    popularCategories: "Popular Categories",
    paymentMethod: "Payment Method",
    payout: "Payout",
    trending: "Trending",
    clearAll: "Clear All",
    editItem: "Edit Item",
    editStore: "Edit Store",
    success: "Success",
    consigneeCreated: "Consignee Created.",
    error: "Error",
    itemEdited:"Item Edited.",                         
    itemCreated:"Item Created.",
    clockedIn:"Clocked In.",
    clockedOut:"Clocked Out.",
    storeLockedSuccessfully:"Store locked successfully.",
    storeUnlockedSuccessfully:"Store unlocked successfully.",
    storeEdited:"Store Edited.",
    storeCreated:"Store Created.",
    discountCodeCreated:"Discount Code Created.",
    staffAdded:"Staff Added",
    codeEdited:"Code Edited.",
    staffEdited:"Staff Edited",
    expenseCreated:"Expense Created.",
    logoUpdated:"Logo Updated",
    defaultSettings:"Default Settings.",
    updated:"Updated",
    customSettings:"Custom Settings.",
    detailsUpdated:"Details Updated",
    verified:"Verified",
    discountApplied:"Discount Applied.",
    failed:"Failed",
    invalidDiscountCode:"Invalid Discount Code.",
    paymentSuccessful:"Payment Successful.",
    successfullyUpdated: "Successfully Updated",
    settingsSaved: "Settings saved.",
    alreadyClockedIn: "Already Clocked In.",
    alreadyClockedOut: "Already Clocked Out.",
    pleaseSelectAStore: "Please select a store.",
    cartCannotBeEmpty: "Cart Cannot be empty.",
    emptyDiscountField: "Empty discount field.",
    cartEmpty : "Cart empty.",
    paymentUnsuccessful: "Payment Unsuccessful",
    addToCartFailed: "Add to cart failed",
    storeDoesNotExist: "Store does not exist",
    selectAStaff: "Select a staff.",
    pleaseSelectAStaff: "Please select a staff.",
    pleaseTickCheckbox: "Please tick checkbox.",
    vacantInput: "Vacant Input.",
    consignee: "Consignee",
    salesDetails: "Sales Details",
    fulfilled: "Fulfilled",
    editingDiscountCode: "Editing Discount Code",
    editingStaff: "Editing Staff",
    editingStore: "Editing Store",
    download: "Download",
    inCart: "In Cart",
    choose: "Choose",
    chooseSize: "Choose size",
    chooseCategories: "Choose categories",
    choosePlatform: "Choose platform",
    chooseCondition: "Choose condition",
    chooseCurrency: "Choose currency",
    chooseMethod: "Choose method",
    chooseLanguage: "Choose language",
    chooseProducts: "Choose products",
    chooseEligibility: "Choose eligibility",
    chooseDuration: "Choose duration",
    chooseMode: "Choose mode",
    selectColour: "Select Colour",
    all: "All",
    expired: "Expired",
    scheduled: "Scheduled",
    createCode: "Create Code",
    highestBid: "Highest Bid",
    lowestAsk: "Lowest Ask",
    missingItems: "Missing Items",
    print: "Print",
    consigneesSummary: "Consignees Summary",
    editingConsignee : "Editing Consignee",
    dateSold: "Date sold",
    addingNewExpense: "Adding New Expense",
    details: "Details",
    verify: "Verify",
    staff: "Staff",
    time: "Time",
    oneMonth: "1 Month",
    threeMonths: "3 Months",
    sixMonths: "6 Months",
    marketIdentifier: "Market Identifier",
    new: "New",
    confirmingPayment: "Confirming Payment",
    customerEmail: "Customer Email",
    cash: "Cash",
    cheque: "Cheque",
    creditCard: "Credit Card",
    payNow: "Pay Now",
    ccLast4Digits: "CC last 4 digits",
    amountReceived: "Amount Received",
    change: "Change",
    inStore: "In-Store",
    delivery: "Delivery",
    shippingAddress: "Shipping Address",
    ofTotal: "of Total",
    deleting: "Deleting",
    confirmToDeleteThis: "Confirm to delete this",
    yesConfirm: "YES Confirm",
    noThinkAgain: "No, Think Again",
    purchasedBy: "Purchased by",
  },
};

export default en;
