import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import Calendar from "../../assets/calendar.svg";
import DownIcon from "../../assets/down.svg";
import "../css/SmallSelect.scoped.css";
import DownIconDark from "../../assets/downdark.svg";

export default function SmallSelect({
  name,
  value,
  updateValue,
  options,
  placeholderText,
  month,
  none,
  render,
  dark,
  disabled,
  customIcon,
}) {
  const [isOpen, updateIsOpen] = useState(false);
  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: dark ? "#5A5D74" : "#abafd1",
      fontWeight: 300,
      fontSize: "0.9375rem",
      lineHeight: "1.375rem",
      fontFamily: "Poppins",
      width: "60%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }));

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    menuPaper: {
      backgroundColor: dark ? "#353C53" : "#fff",
      maxHeight: "200px !important",
    },
  }));

  const classes = useStyles();
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  return (
    <FormControl
      sx={{ m: 1, width: "100%", maxWidth: "9.0625rem" }}
      className="formControl"
      id="simple-select-form-1"
    >
      <Select
        style={{ color: dark ? "#f6f7ff" : "#323A52" }}
        disabled={disabled}
        id="simple-select-1"
        className="smselect"
        name={name}
        displayEmpty
        value={value}
        MenuProps={{ classes: { paper: classes.menuPaper } }}
        onChange={(e) => updateValue(e)}
        inputProps={{
          "aria-label": "Without label",
        }}
        renderValue={
          value !== ""
            ? render
            : () => <Placeholder>{placeholderText}</Placeholder>
        }
        onOpen={() => updateIsOpen(true)}
        onClose={() => updateIsOpen(false)}
        IconComponent={() => (
          <img
            style={{
              position: "absolute",
              pointerEvents: "none",
              right: "0.625rem",
              width: "1.125rem",
              transition: `all ease .2s`,
              transform: `rotate(${isOpen && !month ? 180 : 0}deg)`,
            }}
            alt="down"
            src={customIcon ? customIcon : dark ? DownIconDark : DownIcon}
          />
        )}
      >
        {none && (
          <MenuItem value="" className="menuitem">
            <span>None</span>
          </MenuItem>
        )}
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value}
            style={{ color: dark ? "#f6f7ff" : "#abafd1" }}
            className="menuitem"
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
