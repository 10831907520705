import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import getEnvValue from "../../components/utils/getEnvValue";
import { StoreId } from "../../Constants";
import { db } from "../../firebase";

export default async function addNewTicketItem(token, data) {
  // console.log(data);
  if (!token) {
    throw new Error("Token not provided");
  }

  if (!data.showId) {
    throw new Error("Show Id not provided");
  }

  if (!data.category) {
    throw new Error("Please choose a section");
  }

  if (!data.purchasedDate) {
    throw new Error("Please provide a purchasedDate");
  }

  // if (new Date(data.purchasedDate) > new Date()) {
  //   throw new Error("purchased date can't be in the future");
  // }

  if (!data.purchasedPrice || data.purchasedPrice < 0) {
    throw new Error("Invalid purchased price value");
  }

  if (!data.quantity) {
    throw new Error("Quantity not given");
  }

  if (data.quantity < 1 || data.quantity > 20) {
    throw new Error("Quantity must be within 1 and 20");
  }

  if (!data.splittingOption) {
    throw new Error("Please choose a ticket separation");
  }

  if (!data.seatNumbers || data.seatNumbers.some((e) => !e || e < 0)) {
    throw new Error("Invalid seat numbers provided");
  }

  if (!data.row) {
    throw new Error("Please provide a row number");
  }

  if (!data.section) {
    throw new Error("Please provide a seat type");
  }

  if (data.uploadedTickets.some((e) => !e)) {
    throw new Error("Please upload all tickets");
  }

  if (data.uploadedTickets.some((e) => !e.file || !e.path)) {
    throw new Error("There is some issue with tickets uploaded");
  }

  // const storeSnap = await getDoc(doc(db, "Stores", StoreId));
  // const storeData = storeSnap.data();

  // console.log({
  //   token,
  //   ...data,
  //   uploadedTickets: data.uploadedTickets
  //     .slice(0, data.quantity)
  //     .map((e) => e.path),
  //   sellerStripeId: storeData.stripe_seller_id,
  // });

  const resp = await axios.post(
    `${getEnvValue("REACT_APP_TICKETS_API")}/tickets/create`,
    {
      // const resp = await axios.post("http://localhost:8080/tickets/create", {
      token,
      ...data,
      uploadedTickets: data.uploadedTickets // to restrict sending more tickets than quantity
        .slice(0, data.quantity)
        .map((e) => e.path),
      sellerStripeId: "RANDOM_STRING",
    }
  );

  if (!resp.data) {
    throw new Error(resp.error.message);
  }
  if (resp.error) {
    throw new Error(resp.error);
  }

  return resp.data;
}
