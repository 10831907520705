import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/Login";
import Test from "./Test";
const MODE = "";

ReactDOM.render(
  <React.StrictMode>
    {MODE === "TEST" ? (
      <Test />
    ) : (
      <Router>
        <App />
      </Router>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// editConsignment("P5QC31S2nuLXYwRSTTvl", "buXT4kQhQ0xjggf4xa0P", {
//   category: "qw",
//   condition: "new",
//   consigneeID: 1234,
//   dateIn: "2022-01-11",
//   fee: 25,
//   image: "https://images.stockx.com/images/adidas-Yeezy-Boost-350-V2-Beluga-Reflective-Product.jpg?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1638996919",
//   name: "Newww",
//   payoutPrice: 150,
//   size: 6,
//   sku: "GW1229",
// })

// createProduct("P5QC31S2nuLXYwRSTTvl", "CT0978-005");
// deleteProduct("P5QC31S2nuLXYwRSTTvl", "FX4348");
// createInventory("P5QC31S2nuLXYwRSTTvl", "FX4348", {
//   condition: "new",
//   category: "qw",
//   platform: "stockx",
//   dateIn: "2022-01-11",
//   markUp: 9,
//   image: "https://images.stockx.com/images/adidas-Yeezy-Boost-350-V2-Beluga-Reflective-Product.jpg?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1638996919",
//   name: "Newww",
//   purchaseDate: "2022-01-19",
//   purchasePrice: "121",
//   size: 6,
//   sku: "GW1229",
// })
// deleteInventory("P5QC31S2nuLXYwRSTTvl", "NW06yQElQKlNpeVRJQoX");
// createConsignment("P5QC31S2nuLXYwRSTTvl", "FX4348", {
//   category: "qw",
//   condition: "new",
//   consigneeID: 1234,
//   dateIn: "2022-01-11",
//   fee: 25,
//   image: "https://images.stockx.com/images/adidas-Yeezy-Boost-350-V2-Beluga-Reflective-Product.jpg?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1638996919",
//   name: "Newww",
//   payoutPrice: 150,
//   size: 7.5,
//   sku: "GW1229",
// });
// deleteConsignment("P5QC31S2nuLXYwRSTTvl", "NFU7TsFXKnz5RPjkGALy")
// deleteConsignment("P5QC31S2nuLXYwRSTTvl", "NFU7TsFXKnz5RPjkGALy")

// updateNormalCount("P5QC31S2nuLXYwRSTTvl");
// updateConsignmentCount("P5QC31S2nuLXYwRSTTvl");
// checkDiscount("P5QC31S2nuLXYwRSTTvl", "FIRSTBUY").then(e => console.log(e))
// addTocart("P5QC31S2nuLXYwRSTTvl", [{ "id": 1, "asd": "sdfg" }, { "id": 2, "asd": "sdfg" }])
// removeFromCart("P5QC31S2nuLXYwRSTTvl", "9JssGPvODljYQiNsUyQz", "5")
// editCart("P5QC31S2nuLXYwRSTTvl", "9JssGPvODljYQiNsUyQz", { "id": 7, "asd": "sdfg" })
