import React, { useEffect, useState } from "react";
import DeleteConfirm from "../general/DeleteConfirm";
import "../css/TicketGrid.scoped.css";
import { Collapse, Tooltip, Typography } from "@mui/material";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import axios from "axios";
import { doc, onSnapshot } from "firebase/firestore";
import { StoreId, TicketSplittingOptions, TicketStatus } from "../../Constants";
import { db } from "../../firebase";
import deleteTicket from "../../functions/tickets/deleteTicket";
import getEnvValue from "../utils/getEnvValue";
import ArrowDownward from "../../assets/arrowDown.svg";
import ArrowDownwardDark from "../../assets/arrowDownDark.svg";
import CustomizedSwitches from "../general/Switch";
import close from "../../assets/red-close.svg";
import placeTicketAsk from "../../functions/tickets/placeTicketAsk";
import deleteTicketAsk from "../../functions/tickets/deleteTicketAsk";
import Loader from "../general/Loader";
import useMultiPriceInput from "../../functions/utils/useMultiPriceInput";
import UploadedPDFViewer from "./UploadedPDFViewer";
import infoSvg from "../../assets/info.svg";
// import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import copy from "../../assets/copy.svg";
import blackCopy from "../../assets/blackCopy.svg";

const BUYER_FEES = 10;

const TicketInventoryContainer = ({
  dark,
  setTotal,
  selectedData,
  searchString,
  soldFilter,
  refetch,
  setRefetch,
  addModal,
  setAddModal,
}) => {
  const [ticketsData, setTicketsData] = useState({});
  const [katchStatus, setKatchStatus] = useState(false);
  const toggleRefetch = () => {
    setRefetch(!refetch);
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.post(
          `${getEnvValue("REACT_APP_TICKETS_API")}/tickets/inventory`,
          // "http://localhost:8080/tickets/inventory",
          {
            token,
          }
        );

        // console.log(resp.data);
        if (resp.data.error) {
          throw new Error(resp.data.error);
        }

        let totalValue = resp.data.reduce((acc, next) => {
          return next.status === TicketStatus.SOLD
            ? acc
            : acc +
                next.price *
                  next.quantity *
                  (1 - getEnvValue("REACT_APP_SELLER_FEES") / 100);
        }, 0);

        let totalUnrealizedProfit = resp.data.reduce((acc, next) => {
          return next.status === TicketStatus.LISTED
            ? acc
            : acc +
                (next.price * (1 - getEnvValue("REACT_APP_SELLER_FEES") / 100) -
                  next.purchasedPrice) *
                  next.quantity;
        }, 0);

        setTotal((prev) => ({
          ...prev,
          ti:
            // .filter((e) => e.status !== TicketStatus.SOLD)
            resp.data.length,
          tv: totalValue,
          tup: totalUnrealizedProfit,
        }));
        // console.log(_.groupBy(resp.data, "showId"));

        let filterData = resp.data;

        if (searchString) {
          const regex = new RegExp(searchString, "i");

          const dataText = resp.data.map(
            (d) =>
              `${d.eventName}  row ${d.row} ${d.venueLocation} ${d.venueName} ${
                d._id
              } ${d.showId}`
          );
          filterData = resp.data.filter((str, i) => {
            return regex.test(dataText[i]);
          });
        }

        // const FilterData = resp.data.filter(d => )
        setTicketsData(
          _.groupBy(
            filterData.filter((e) =>
              soldFilter
                ? e.status === TicketStatus.SOLD
                : e.status !== TicketStatus.SOLD
            ),
            "showId"
          )
        );

        // console.log(resp.data.filter((e) => e.status !== TicketStatus.SOLD));
      } catch (e) {
        console.log(e);
        ErrorToast("Error", "Some error occurred while fetching tickets");
      }
    })();
  }, [refetch, searchString, soldFilter]);

  useEffect(() => {
    const storeListener = onSnapshot(
      doc(db, "Stores", StoreId),
      async (snapShot) => {
        setKatchStatus(snapShot.data().katch ?? false);
      }
    );
    return () => {
      storeListener();
    };
  }, []);

  const [askMode, updateAskMode] = useState({});
  const [isAskModalOpen, updateIsAskModalOpen] = useState(false);
  const [askModalData, updateAskModalData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [pdfData, setPdfData] = useState([]);
  const [pdfOpen, togglePdfOpen] = useState(null);
  const [seatNumbers, setSeatNumbers] = useState([]);
  const [mode, setMode] = useState("add");

  function toggleAddModal() {
    setAddModal(!addModal);
  }
  return (
    <div>
      <DeleteConfirm
        dark={dark}
        isOpen={deleteModal}
        toggleOpen={setDeleteModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        func={async (storeId, deleteId) => {
          await deleteTicket(token, deleteId);
          toggleRefetch();
        }}
        msg={`ticket`}
      />
      <UploadedPDFViewer
        dark={dark}
        isOpen={pdfOpen}
        toggleState={togglePdfOpen}
        handleClose={() => {
          togglePdfOpen(false);
          setPdfData(null);
          setSeatNumbers([]);
        }}
        data={pdfData}
        seatNumbers={seatNumbers}
      />

      <div className="items-container ticket-container">
        <div className="items">
          {Object.keys(ticketsData)
            ?.sort()
            ?.map((showId, i) => (
              <ItemGrid
                key={showId}
                dark={dark}
                setDeleteModal={setDeleteModal}
                setDeleteId={setDeleteId}
                setSeatNumbers={setSeatNumbers}
                data={ticketsData[showId]}
                soldFilter={soldFilter}
                toggleRefetch={toggleRefetch}
                setPdfData={setPdfData}
                togglePdfOpen={togglePdfOpen}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default TicketInventoryContainer;

const ItemGrid = ({
  data,
  dark,
  setDeleteModal,
  setDeleteId,
  setSeatNumbers,
  soldFilter,
  toggleRefetch,
  setPdfData,
  togglePdfOpen,
}) => {
  const [toggle, setToggle] = useState(false);

  let totalQuantity =
    data?.reduce(
      (acc, next) =>
        acc + (next.status !== TicketStatus.SOLD ? next.quantity : 0),
      0
    ) || 0;
  let totalSold =
    data?.reduce(
      (acc, next) =>
        acc + (next.status === TicketStatus.SOLD ? next.quantity : 0),
      0
    ) || 0;

  let pendingFullfillment =
    data?.reduce(
      (acc, next) =>
        acc + (next.status !== TicketStatus.AUTHENTICATED ? next.quantity : 0),
      0
    ) || 0;

  return (
    <div className="ticket-collapse">
      <div
        className="ticket-collapse-parent-grid"
        onClick={() => {
          setToggle(!toggle);
          // console.log(data);
        }}
      >
        <div
          style={{
            transform: !toggle ? "rotate(-90deg)" : "rotate(0deg)",
          }}
          className="flex al-ce"
        >
          <img src={dark ? ArrowDownwardDark : ArrowDownward} />
        </div>
        <div>
          <div>
            <div className="bold">{data[0]?.eventName}</div>
            <div>
              {new Date(data[0]?.dateStart).toLocaleString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            <div>
              {data[0]?.venueName}, {data[0]?.venueLocation}
            </div>
          </div>
        </div>
        <div>Ticket Available: {totalQuantity}</div>
        <div>Ticket Sold: {totalSold}</div>
        <div>Pending fulfillment: {pendingFullfillment}</div>
        <div />
      </div>
      <Collapse in={toggle}>
        <div className="ticket-collapse-child-grid">
          <div />
          <div>Ticket Details</div>
          <div>Ticket Type</div>
          <div>Ticket Separation</div>
          <div>
            Platform Price (SGD)
            <Tooltip
              placement="top"
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Platform Price -{" "}
                    <span style={{ fontWeight: 300 }}>
                      The unit price that shows on platform. Please note that
                      the platform’s fees automatically applies to this amount.
                    </span>
                  </Typography>
                </React.Fragment>
              }
            >
              <span>
                <img src={infoSvg} />
              </span>
            </Tooltip>
          </div>
          <div>
            Payout (SGD)
            <Tooltip
              placement="top"
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Payout Price -{" "}
                    <span style={{ fontWeight: 300 }}>
                      The amounts that you are looking to get paid.
                    </span>
                  </Typography>
                </React.Fragment>
              }
            >
              <span>
                <img src={infoSvg} />
              </span>
            </Tooltip>
          </div>
          <div className="center">Retail Price</div>
          <div className="center">{soldFilter ? "Sold" : "Available"}</div>
          <div>
            {!soldFilter && (
              <>
                Publish
                <Tooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        <span>Publish -</span>
                        <br />
                        <div style={{ fontWeight: 300 }}>
                          <span className="green">Green</span> = Your ticket is
                          listed and price change will not be allowed.
                          <br />
                          <span className="red">Red</span> = Your ticket is not
                          listed and price change will be allowed.
                        </div>
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <span>
                    <img src={infoSvg} />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
          <div />
          <div />
        </div>
        {data?.map((e, i) => (
          <CollapsedTicketRow
            data={e}
            key={e._id}
            setDeleteModal={setDeleteModal}
            setDeleteId={setDeleteId}
            toggleRefetch={toggleRefetch}
            setPdfData={setPdfData}
            setSeatNumbers={setSeatNumbers}
            togglePdfOpen={togglePdfOpen}
          />
        ))}
      </Collapse>
    </div>
  );
};

const CollapsedTicketRow = ({
  data,
  setDeleteId,
  setDeleteModal,
  toggleRefetch,
  setPdfData,
  setSeatNumbers,
  togglePdfOpen,
}) => {
  function formatPrice(p) {
    return `${Number((p ?? 0).toFixed(2))}`;
  }

  function round(num) {
    return Math.floor(num * 100) / 100;
  }

  const {
    platformPrice,
    payoutPrice,
    onChangePlatformPrice,
    onChangePayoutPrice,
  } = useMultiPriceInput(
    (data?.price || 0).toFixed(2),
    Number(getEnvValue("REACT_APP_SELLER_FEES")) / 100
  );

  const [isPublished, togglePublished] = useState(
    data?.status === TicketStatus.LISTED ||
      data?.status !== TicketStatus.AUTHENTICATED
  );

  const [askLoading, setAskLoading] = useState(false);

  const token = localStorage.getItem("token");

  const handleEditListing = async () => {
    try {
      if (data?.status === TicketStatus.LISTED) {
        if (data?.price !== Number(platformPrice)) {
          if (platformPrice === 0) {
            console.log("platform price cannot be negative");
            return ErrorToast("Error", "Platform price price not given");
          }

          if (payoutPrice <= 0) {
            console.log("payout price cannot be negative");
            return ErrorToast("Error", "payout price should be greater than 0");
          }

          setAskLoading(true);

          // console.log("placing ask");
          await placeTicketAsk(token, data?._id, Number(platformPrice));
          // toggleRefetch();
          SuccessToast("Success", `Listing updated for this ticket`);
        }
      } else {
        return ErrorToast(
          "Error",
          "You are editing ask price for a ticket which is not published."
        );
      }

      // setPrice("");
    } catch (e) {
      console.log(e);
      if (e.name === "AxiosError") {
        ErrorToast("Error", e?.response?.data.error);
      } else {
        ErrorToast("Error", "Some error occurred");
      }
    } finally {
      setAskLoading(false);
    }
  };

  return (
    <div className="ticket-collapse-child-values-grid">
      <div />
      <div>
        <div className="bold">Section {data?.category}</div>
        <div>
          Row {data?.row}, Seat{" "}
          {data?.section == "Standing"
            ? "-"
            : `${data?.seatNumbers[0]}-${
                data?.seatNumbers[data?.quantity - 1]
              }`}
        </div>
      </div>
      <div>
        <div className="bold">E-Tickets</div>
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setPdfData(data?.uploadedTickets?.map((e) => e.split("/")[1]));
            setSeatNumbers(data?.seatNumbers);
            togglePdfOpen(true);
          }}
        >
          View tickets
        </div>
      </div>
      <div>
        {data?.splittingOption
          ? TicketSplittingOptions[(data?.splittingOption)]
          : "Any"}
      </div>
      <div className="input-container">
        {data?.status === TicketStatus.SOLD ? (
          `$${formatPrice(data?.price || 0)}`
        ) : (
          <div className="input">
            {/* this is the platform price */}
            <input
              type="number"
              step=".01"
              value={platformPrice}
              onChange={onChangePlatformPrice}
              onBlur={async () => await handleEditListing()}
            />
          </div>
        )}
      </div>
      <div className="input-container">
        {data?.status === TicketStatus.SOLD ? (
          `$${formatPrice(
            (data?.price || 0) *
              (1 - Number(getEnvValue("REACT_APP_SELLER_FEES")) / 100)
          )}`
        ) : (
          <div className="input">
            {/* this is the payout price */}
            <input
              type="number"
              step=".01"
              value={payoutPrice}
              onChange={onChangePayoutPrice}
              onBlur={async () => await handleEditListing()}
            />
          </div>
        )}
      </div>
      <div className="center">${formatPrice(data?.purchasedPrice)}</div>
      <div className="center">
        {data?.status !== TicketStatus.SOLD ? data?.quantity : data?.purchaseId}
      </div>

      {data?.status === TicketStatus.SOLD && (
        <div className="center flex al-ce jc-ce">
          {/* <span className={"purchaseId"}>{data?.purchaseId}</span> */}
          <div
            onClick={() => {
              navigator.clipboard.writeText(data?.purchaseId);
              SuccessToast("Purchase ID copied Successfully");
            }}
            style={{
              cursor: "pointer",
              margin: "0 0.425rem",
            }}
          >
            <img src={copy} />
          </div>
        </div>
      )}

      {data?.status !== TicketStatus.SOLD && (
        <div>
          <div className="publish-checkbox-container">
            <CustomizedSwitches
              checked={isPublished}
              onChange={async () => {
                try {
                  // console.log(
                  //   askLoading,
                  //   platformPrice,
                  //   payoutPrice,
                  //   data?._id,
                  //   data?.status
                  // );
                  if (!askLoading) {
                    setAskLoading(true);
                    if (!isPublished) {
                      if (payoutPrice <= 0) {
                        console.log("payout price cannot be negative");
                        return ErrorToast(
                          "Error",
                          "payout price cannot be negative"
                        );
                      }

                      if (platformPrice === 0) {
                        console.log("platform price cannot be negative");
                        return ErrorToast(
                          "Error",
                          "Platform price price not given"
                        );
                      }

                      await placeTicketAsk(
                        token,
                        data?._id,
                        Number(platformPrice)
                      );

                      togglePublished(!isPublished);
                      SuccessToast(
                        "Success",
                        "This ticket has been successfully published"
                      );
                      toggleRefetch();
                    } else if (isPublished) {
                      await deleteTicketAsk(token, data?._id);

                      togglePublished(!isPublished);
                      SuccessToast(
                        "Successfully",
                        "Ticket has been unpublished"
                      );
                      toggleRefetch();
                    }
                  }
                } catch (e) {
                  console.log(e);
                  if (e.name === "AxiosError") {
                    ErrorToast("Error", e?.response?.data.error);
                  } else {
                    ErrorToast("Error", "Some error occurred");
                  }
                } finally {
                  setAskLoading(false);
                }
              }}
            />
            {askLoading && (
              <div style={{ marginRight: "0.425rem" }}>
                <Loader loading={askLoading} />
              </div>
            )}
          </div>
        </div>
      )}

      {data?.status === TicketStatus.SOLD ||
      data?.status === TicketStatus.LISTED ? (
        <div className="flex al-ce">
          <Tooltip title="Copy Ticket ID">
            <div
              onClick={() => {
                navigator.clipboard.writeText(data?._id);
                SuccessToast("Ticket ID copied Successfully");
              }}
              style={{
                cursor: "pointer",
                margin: "0 0.425rem 0 0",
              }}
            >
              <img src={copy} />
            </div>
          </Tooltip>
          {data?.status === TicketStatus.LISTED && (
            <Tooltip title="Copy share link">
              <div
                onClick={() => {
                  navigator.clipboard.writeText(
                    `Check out ${data.eventName} in ${data.venueName}, ${
                      data.venueLocation
                    } on ${new Date(data.dateStart).toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}, available at $${parseFloat(
                      data.price * (1 + BUYER_FEES / 100)
                    ).toFixed(
                      2
                    )} on Ingenium+\nhttps://ingenium.biz/plus/ticket/?id=${
                      data._id
                    }`
                  );
                  SuccessToast("Success", "Share link copied to clipboard");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={blackCopy} />
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        <div className="flex al-ce">
          <div
            onClick={() => {
              navigator.clipboard.writeText(data?._id);
              SuccessToast("Ticket ID copied Successfully");
            }}
            style={{
              cursor: "pointer",
              margin: "0 0.425rem 0 0",
            }}
          >
            <img src={copy} />
          </div>
          <div
            onClick={() => {
              setDeleteId(data?._id);
              setDeleteModal(true);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={close} />
          </div>
        </div>
      )}
    </div>
  );
};
