import React, { useState } from "react";
import deleteBankAccount from "../../firebase/wallet/deleteBankAccount";
import DeleteConfirm from "../general/DeleteConfirm";

export default function DeleteBankAccountModal({
  dark,
  deleteModal,
  toggleOpen,
  deleteId,
  setDeleteId,
}) {
  return (
    <DeleteConfirm
      dark={dark}
      isOpen={deleteModal}
      toggleOpen={toggleOpen}
      deleteId={deleteId}
      setDeleteId={setDeleteId}
      func={deleteBankAccount}
      msg={`Bank Account`}
    />
  );
}
