import { getAuth, onIdTokenChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddExpenseModal from "../components/expenses/AddExpenseModal";
import ExpensesContainer from "../components/expenses/ExpensesContainer";
import SearchBtn from "../components/general/SearchBtn";
import Topbar from "../components/general/Topbar";
import translate from "../i18n/translate";
const Users = ({ user, dark }) => {
  const [search, setSearch] = useState("");
  const [searchString, updateSearchString] = useState("");
  const isOwner = localStorage.getItem("owner");

  const navigate = useNavigate();

  const auth = getAuth();
  // console.log(auth);
  useEffect(() => {
    const authListener = onIdTokenChanged(auth, async (user) => {
      // console.log(auth)
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
      }
    });
    return () => {
      authListener();
    };
  }, []);

  useEffect(() => {
    let hidden =
      localStorage.getItem("isExpenseHidden") ||
      localStorage.getItem("hideExpensePage");
    if (!isOwner && hidden) {
      navigate("/dash");
    }
  }, [isOwner, navigate]);

  return (
    <div className="page">
      <Topbar dark={dark} pagename={"Expenses (Tickets)"} user={user} />
      <div>
        <div className="add-btns px">
          <div className="mr-14">
            <SearchBtn
              dark={dark}
              value={search}
              updateValue={setSearch}
              onEnter={async () => {
                updateSearchString(search);
              }}
            />
          </div>

          <AddExpenseModal dark={dark} />
        </div>
        <div className="content px">
          <ExpensesContainer searchString={searchString} dark={dark} />
        </div>
      </div>
    </div>
  );
};

export default Users;
