import React from "react";
import CheckboxWithLabel from "./CheckboxWithLabel";
import "../css/Modal.scoped.css";

export default function TicketChecks({ dark, data, setData }) {
  return (
    <div className="twoGrid gap-0">
      {data.map((item, index) => {
        return (
          <CheckboxWithLabel
            key={index}
            checked={item.value}
            handleChange={() => {
              let temp = [...data];
              temp[index].value = !temp[index].value;
              setData(temp);
            }}
            checkedIcon={
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.0401 10.2904C16.4306 9.89983 16.4306 9.26667 16.0401 8.87615C15.6496 8.48562 15.0164 8.48562 14.6259 8.87615L10.5413 12.9607L8.85261 11.272C8.46209 10.8815 7.82893 10.8815 7.4384 11.272C7.04788 11.6625 7.04788 12.2957 7.4384 12.6862L9.83423 15.082C9.88305 15.1308 9.93566 15.1736 9.99111 15.2102C10.3793 15.4664 10.9067 15.4237 11.2484 15.082C11.2486 15.0818 11.2488 15.0816 11.249 15.0814L16.0401 10.2904Z"
                  fill={dark ? "#767BFA" : "#323A52"}
                />
                <path
                  d="M1 11.5C1 13.8447 1.12694 15.6607 1.4413 17.0752C1.75314 18.4783 2.23595 19.4244 2.90576 20.0942C3.57558 20.764 4.52166 21.2469 5.92481 21.5587C7.33932 21.8731 9.15526 22 11.5 22C13.8447 22 15.6607 21.8731 17.0752 21.5587C18.4783 21.2469 19.4244 20.764 20.0942 20.0942C20.764 19.4244 21.2469 18.4783 21.5587 17.0752C21.8731 15.6607 22 13.8447 22 11.5C22 9.15526 21.8731 7.33932 21.5587 5.92481C21.2469 4.52166 20.764 3.57558 20.0942 2.90576C19.4244 2.23595 18.4783 1.75314 17.0752 1.4413C15.6607 1.12694 13.8447 1 11.5 1C9.15526 1 7.33932 1.12694 5.92481 1.4413C4.52166 1.75314 3.57558 2.23595 2.90576 2.90576C2.23595 3.57558 1.75314 4.52166 1.4413 5.92481C1.12694 7.33932 1 9.15526 1 11.5Z"
                  stroke={dark ? "#5A5D74" : "#323A52"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            icon={
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.5C1 13.8447 1.12694 15.6607 1.4413 17.0752C1.75314 18.4783 2.23595 19.4244 2.90576 20.0942C3.57558 20.764 4.52166 21.2469 5.92481 21.5587C7.33932 21.8731 9.15526 22 11.5 22C13.8447 22 15.6607 21.8731 17.0752 21.5587C18.4783 21.2469 19.4244 20.764 20.0942 20.0942C20.764 19.4244 21.2469 18.4783 21.5587 17.0752C21.8731 15.6607 22 13.8447 22 11.5C22 9.15526 21.8731 7.33932 21.5587 5.92481C21.2469 4.52166 20.764 3.57558 20.0942 2.90576C19.4244 2.23595 18.4783 1.75314 17.0752 1.4413C15.6607 1.12694 13.8447 1 11.5 1C9.15526 1 7.33932 1.12694 5.92481 1.4413C4.52166 1.75314 3.57558 2.23595 2.90576 2.90576C2.23595 3.57558 1.75314 4.52166 1.4413 5.92481C1.12694 7.33932 1 9.15526 1 11.5Z"
                  stroke={dark ? "#5A5D74" : "#323A52"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            label={item.label}
          />
        );
      })}
    </div>
  );
}
