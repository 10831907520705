import { LOCALES } from "../locales";

let es = {
  [LOCALES.SPANISH]: {
    hello: "Hola",
    owner: "Propietario",
    country: "País",
    storeName: "Nombre de la tienda",
    storeId: "ID de la tienda",
    email: "Envíe un correo electrónico a",
    dateJoined: "Fecha de incorporación",
    categories: "Categorías",
    profitsLosses: "Beneficios/Pérdidas",
    totalExpenses: "Gastos totales",
    totalInventory: "Inventario total",
    discountSummary: "Resumen de descuentos",
    staffSummary: "Resumen del personal",
    staffDetails: "Detalles del personal",
    workingPerformance: "Rendimiento del trabajo",
    clockInAndOutDetails: "Detalles de la entrada y salida del reloj",
    storeInformations: "Información de la tienda",
    staffs: "Staffs",
    dashboard: "Tablero de mandos",
    marketLookUp: "Mirada al mercado",
    inventory: "Inventario",
    productLabels: "Etiquetas de productos",
    consignment: "Consignación",
    sales: "Ventas",
    expenses: "Gastos",
    settings: "Ajustes",
    marketSales: "Ventas en el mercado",
    total: "Total",
    name: "Nombre",
    enterName: "Introduzca el nombre",
    enterId: "Introduzca el ID",
    enterDate: "Introduzca la fecha",
    enterAddress: "Introduzca la dirección",
    enterCountry: "Introduzca el país",
    enterCity: "Introduzca la ciudad",
    enterEmail: "Introduzca el correo electrónico",
    enterContactNo: "Introduzca el número de contacto.",
    enterStyleCode: "Introduzca el código de estilo",
    enterAmount: "Introduzca el importe",
    enterStocks: "Introducir acciones",
    enterPercentage: "Entre en %",
    enterReq: "Introduzca los requisitos",
    enterStartDate: "Introduzca la fecha de inicio",
    enterEndDate: "Introduzca la fecha de finalización",
    enterCode: "Introduzca el código",
    enterStartTime: "Introduzca la hora de inicio",
    enterEndTime: "Introduzca la hora de finalización",
    autoDetect: "Detección automática",
    enterLast4Digits: "Introduzca los últimos 4 dígitos",
    enterNumber: "Introduzca el número",
    enterIfInstore: "Entrar - si es en la tienda",
    shippingInfo: "Información de envío",
    enterInfo: "Introduzca la información",
    enterNotes: "Introducir notas",
    enterTitle: "Introduzca el título",
    enterUrl: "Introducir Url",
    id: "ID",
    totalSales: "Ventas totales",
    transactionHistory: "Historial de transacciones",
    date: "Fecha",
    delete: "Borrar",
    items: "Artículos",
    price: "Precio",
    requirements: "Requisitos",
    eligibility: "Elegibilidad",
    status: "Estatus",
    used: "Usado",
    date: "Fecha",
    code: "Código",
    value: "Valor",
    clockedInTime: "Hora de entrada",
    clockedOutTime: "Hora de salida",
    totalHours: "Total de horas",
    numberOfSales: "Número de ventas",
    figures: "Cifras",
    timeIn: "Tiempo en",
    timeOut: "Tiempo de espera",
    staffName: "Nombre del personal",
    staffId: "ID del personal",
    totalFigures: "Cifras totales",
    totalNumberOfSales: "Número total de ventas",
    workingPeriodAssigned: "Período de trabajo asignado",
    dailyWorkingHours: "Horario de trabajo diario",
    weeklyWorkingHours: "Horas de trabajo semanales",
    monthlyWorkingHours: "Horas de trabajo mensuales",
    yearlyWorkingHours: "Horas de trabajo anuales",
    website: "Página web",
    priceHistory: "Historial de precios",
    productDetails: "Detalles del producto",
    viewMarketSales: "Ver las ventas del mercado",
    market: "Mercado",
    comparison: "Comparación",
    productInfo: "Información del producto",
    newSiteComingSoon: "Nuevo sitio web en breve",
    comparing: "Comparando",
    style: "Estilo",
    size: "Tamaño",
    condition: "Condición",
    purchasedPrice: "Precio de compra",
    purchasedDate: "Fecha de compra",
    platform: "Plataforma",
    lastSale: "Última venta",
    markUp: "Marcar",
    finalPrice: "Precio final",
    unrealized: "No realizado",
    totalValue: "Valor total",
    summary: "Resumen",
    totalItems: "Total de artículos",
    totalUnrealizedProfit: "Total de beneficios no realizados",
    inventoryTrackingCode: "Código de seguimiento del inventario",
    qrCode: "Código QR",
    qrCodeSettings: "Configuración del código QR",
    footerBackground: "Fondo del pie de página",
    logo: "Logotipo",
    background: "Antecedentes",
    accentColour: "Color de acento",
    boxBackgroundColor: "Color de fondo de la caja",
    fontColor: "Color de la fuente",
    sizing: "Dimensionamiento",
    currency: "Moneda",
    save: "Guardar",
    barcodeTags: "Etiquetas de código de barras",
    lastScannedDate: "Última fecha de escaneo",
    scanned: "Escaneado",
    sold: "Se vende",
    resetScan: "Reiniciar la exploración",
    missingItem: "Elemento faltante",
    scanItem: "Escanear elemento",
    search: "Busque en",
    refresh: "Actualizar",
    printAll: "Imprimir todo",
    default: "Por defecto",
    totalConsignmentSales: "Total de ventas en consignación",
    consignmentSalesSummary: "Resumen de las ventas en consignación",
    consignmentPopularCategories: "Consignación Categorías populares",
    consigneeOverviews: "Resumen de los destinatarios",
    contactNo: "Contacto.",
    city: "Ciudad",
    payoutDate: "Fecha de pago",
    payoutPrice: "Precio de pago",
    dateIn: "Fecha de entrada",
    listingPrice: "Precio de venta",
    fee: "Tasa",
    consigneeId: "Identificación del destinatario",
    itemName: "Nombre del artículo", 
    firstNameAndLastName: "Nombre y apellidos",
    codeName: "Nombre del código",
    notes: "Notas",
    spentDate: "Fecha de la operación",
    amountSpent: "Cantidad gastada",
    duration: "Duración",
    shipping: "Envío",
    tax: "Impuesto",
    quantity: "Cantidad",
    expenseName: "Nombre del gasto",
    customerName: "Nombre del cliente",
    staffNavigationBarSettings: "Configuración de la barra de navegación del personal",
    mode: "Modo",
    specificStaffPermission: "Permiso específico del personal",
    generalStaffsPermissions: "Permisos del personal general",
    material1: "Material 1",
    material2: "Material 2",
    salesSummary: "Resumen de ventas",
    growth: "Crecimiento",
    pos: "POS",
    receiptSettings: "Ajustes de los recibos",
    purchase: "COMPRAR",
    preview: "Vista previa",
    cart: "Carrito",
    orderId: "ID de pedido",
    sku: "SKU",
    searchsku: "Buscar Sku",
    item: "Artículo",
    totalPayout: "Pago total",
    totalProfit: "Beneficio total",
    customerSideScreen: "Pantalla del lado del cliente",
    addDiscountCode: "Añadir código de descuento",
    discountAmount: "Importe del descuento",
    subTotal: "Subtotal",
    discount: "Descuento",
    saleTax: "Impuesto sobre la venta",
    thankyouForYourPurchase: "GRACIAS POR SU COMPRA",
    welcomeBack: "Bienvenido de nuevo",
    customerViewingScreenSettings: "Configuración de la pantalla de visualización del cliente",
    dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton: "Panel de control - Información de la tienda - Ocultar el botón de creación/edición/eliminación de códigos de descuento",
    inventoryHideImportButton: "Inventario - Ocultar el botón de importación",
    inventoryHideDownloadButton: "Inventario - Ocultar el botón de descarga",
    inventoryHideAddNewEditDeleteButton: "Inventario - Ocultar el botón de añadir nuevo/editar/borrar",
    productLabelsHideqrCodeSettingsPage: "Etiquetas de productos - Ocultar la página de configuración del código QR",
    consignmentsSummaryHidePayoutConfirmationButton: "Envíos - Resumen - Ocultar botón de confirmación de pago",
    consignmentsConsigneeHideAddNewEditDeleteButton: "Envíos - Destinatario - Ocultar el botón Añadir nuevo/editar/borrar",
    consignmentsInventoryHideAddNewEditDeleteButton: "Consignaciones - Inventario - Ocultar el botón de añadir nuevo/editar/borrar",
    salesHideRecieptSettingsPage: "Ventas - Ocultar la página de configuración de la recepción",
    hideExpensesPage: "Ocultar la página de gastos",
    title: "Título",
    saveItems: "Guardar artículos",
    settingOptions: "Opciones de configuración",
    addNew: "Añadir nuevo",
    enterCode: "Introduzca el código",
    pay: "Pagar",
    addToCart: "Añadir a la cesta",
    import: "Importar",
    data: "Datos",
    searchSku: "Buscar SKU",
    recommendedSize: "Tamaño recomendado",
    lastSalePrice: "Último precio de venta",
    currency: "Moneda",
    retailPrice: "Precio de venta al público",
    language: "Idioma",
    pricePremium: "Prima de precio",
    addingNewConsignmentItems: "Añadir nuevos artículos en consignación...",
    editingConsignmentItems: "Edición de artículos en consignación...",
    consignmentSalesDetails: "Detalles de la venta en consignación",
    soldDate: "Fecha de venta",
    soldBy: "Vendido por",
    sizes: "Tallas",
    bestSizes: "Las mejores tallas",
    dateJoin: "Fecha de ingreso",
    startTime: "Hora de inicio",
    endTime: "Fin de los tiempos",
    addingNewConsignee: "Añadir un nuevo destinatario......",
    totalStores: "Total de tiendas",
    staffOverviews: "Panorama del personal",
    edit: "Editar",
    addStaff: "Añadir personal",
    inLastMonth: "en el último mes",
    logOut: "Cerrar la sesión",
    itemsSold: "artículos vendidos",
    stockTags: "Etiquetas de stock",
    daily: "Diario",
    weekly: "Semanal",
    monthly: "Mensualmente",
    yearly: "Anualmente",
    active: "Activo",
    onLeave: "En licencia",
    resigned: "Dimitido",
    addingNewStaff: "Incorporación de nuevo personal...",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    creatingDiscountCode: "Creación de un código de descuento",
    type: "Tipo",
    stocks: "Acciones",
    specificproduct: "Producto específico",
    eligibility: "Elegibilidad",
    startDate: "Fecha de inicio",
    endDate: "Fecha de finalización",
    startTime: "Hora de inicio",
    endTime: "Fin de los tiempos",
    onePerCustomer: "uno por cliente",
    twoPerCustomer: "dos por cliente",
    threePerCustomer: "tres por cliente",
    onAllProducts: "En todos los productos",
    onSpecificCategories: "En categorías específicas",
    onSpecificProducts: "Sobre productos específicos",
    carousell: "Carousell",
    goat: "Cabra",
    sneakers: "Zapatillas",
    collectibles: "Coleccionables",
    arts: "Artes",
    streetwears: "Streetwears",
    addingNewInventoryItems: "Añadir nuevos artículos del inventario",
    custom: "Personalizado",
    editingItem: "Edición del artículo",
    address: "Dirección",
    contact: "Contacte con",
    firstAndLastName: "Nombre y apellidos",
    editingConsignee: "Edición del destinatario",
    customerPreviews: "Vista previa de los clientes",
    recommendedProducts: "Productos recomendados",
    findOutMore: "Más información",
    editingExpenseItems: "Edición de partidas de gastos",
    transactionId: "Identificación de la transacción",
    oneTime: "Una vez",
    light: "Luz",
    dark: "Oscuro",
    popularCategories: "Categorías populares",
    paymentMethod: "Forma de pago",
    payout: "Pagos",
    trending: "Tendencias",
    clearAll: "Borrar todo",
    editItem: "Editar artículo",
    editStore: "Editar la tienda",
    success: "Éxito",
    consigneeCreated: "Consignatario creado.",
    error: "Error",
    itemEdited:"Artículo Editado.",                         
    itemCreated:"Artículo creado.",
    clockedIn:"Registro de entrada.",
    clockedOut:"Desbloqueado.",
    storeLockedSuccessfully:"Tienda bloqueada con éxito.",
    storeUnlockedSuccessfully:"Tienda desbloqueada con éxito.",
    storeEdited:"Tienda editada.",
    storeCreated:"Tienda creada.",
    discountCodeCreated:"Código de descuento creado.",
    staffAdded:"Personal añadido",
    codeEdited:"Código editado.",
    staffEdited:"Editado por el personal",
    expenseCreated:"Gastos creados.",
    logoUpdated:"Logotipo actualizado",
    defaultSettings:"Ajustes por defecto.",
    updated:"Actualizado",
    customSettings:"Configuración personalizada.",
    detailsUpdated:"Detalles actualizados",
    verified:"Verificado",
    discountApplied:"Descuento aplicado.",
    failed:"Fallido",
    invalidDiscountCode:"Código de descuento inválido.",
    paymentSuccessful:"Pago realizado con éxito.",
    successfullyUpdated: "Actualizado con éxito",
    settingsSaved: "Ajustes guardados.",
    alreadyClockedIn: "Ya se ha fichado.",
    alreadyClockedOut: "Ya se ha retirado el reloj.",
    pleaseSelectAStore: "Seleccione una tienda.",
    invalidDiscountCode: "Código de descuento inválido.",
    cartCannotBeEmpty: "El carro no puede estar vacío.",
    emptyDiscountField: "Campo de descuento vacío.",
    cartempty : "Cesta vacía.",
    paymentUnsuccessful: "Pago fallido",
    addToCartFailed: "No se ha podido añadir a la cesta",
    storeDoesNotExist: "La tienda no existe",
    selectAStaff: "Seleccione una plantilla.",
    pleaseSelectAStaff: "Seleccione una plantilla.",
    pleaseTickCheckbox: "Marque la casilla de verificación.",
    vacantInput: "Entrada vacante.",
    consignee: "Consignatario",
    salesDetails: "Detalles de ventas",
    fulfilled: "Cumplido",
    editingDiscountCode: "Edición de código de descuento",
    editingStaff: "Personal de edición",
    editingStore: "Tienda de edición",
    download: "Descargar",
    inCart: "En el carrito",
    choose: "Elegir",
    chooseSize: "Elegir tamaño",
    chooseCategories: "Elige categorías",
    choosePlatform: "Elegir plataforma",
    chooseCondition: "Elija condición",
    chooseCurrency: "Elegir Moneda",
    chooseMethod: "Elegir método",
    chooseLanguage: "Elige lengua",
    chooseProducts: "Elige productos",
    chooseEligibility: "Elija elegibilidad",
    chooseDuration: "Elige duración",
    chooseMode: "elegir modo",
    selectColour: "Seleccionar color",
    all: "Todos",
    expired: "Caducado",
    scheduled: "Programado",
    createCode: "Crear código",
    highestBid: "Apuesta más alta",
    lowestAsk: "Pregunta más baja",
    missingItems: "Objetos perdidos",
    print: "Imprimir",
    consigneesSummary: "Resumen de consignatarios",
    dateSold: "Fecha de venta",
    addingNewExpense: "Adición de nuevos gastos",
    details: "Detalles",
    verify: "Verificar",
    staff: "Staff",
    time: "Tiempo",
    oneMonth: "1 mes",
    threeMonths: "3 meses",
    sixMonths: "6 meses",
    marketIdentifier: "Identificador de mercado",
    new: "Nuevo",
    confirmingPayment: "Confirmación de pago",
    customerEmail: "Correo electrónico del cliente",
    cash: "Dinero en efectivo",
    cheque: "Cheque",
    creditCard: "Tarjeta de crédito",
    payNow: "Pagar ahora",
    ccLast4Digits: "CC últimos 4 dígitos",
    amountReceived: "Cantidad recibida",
    change: "cambio",
    inStore: "En el almacén",
    delivery: "Entrega",
    shippingAddress: "Dirección de envío",
    ofTotal: "del total",
    deleting: "Borrando",
    confirmToDeleteThis: "Confirmar para eliminar esto",
    yesConfirm: "SI, Confirmar",
    noThinkAgain: "No, piénsalo de nuevo",
    purchasedBy: "Comprado por",
  },
};

export default es;