export default function discountStatus(data) {
  const currDate = new Date(Date.now());

  let endDate = new Date(data.endDate.replace("-", ","));
  endDate = endDate.getTime() + hoursToMills(data.endTime);

  let startDate = new Date(data.startDate.replace("-", ","));
  startDate = startDate.getTime() + hoursToMills(data.startTime);

  if (endDate < currDate.getTime()) {
    return "Expired";
  } else if (startDate > currDate.getTime()) {
    return "Scheduled";
  } else {
    return "Active";
  }
}

export function convertTime12To24(time) {
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === "PM" && hours < 12) hours = hours + 12;
  if (AMPM === "AM" && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
}

export function hoursToMills(time) {
  let timeParts = time.split(":");
  return +timeParts[0] * (60000 * 60) + +timeParts[1] * 60000;
}
