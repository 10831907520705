import { collection, onSnapshot } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { searchExpenses } from "../../algolia/expense";
import { StoreId } from "../../Constants";
import { CurrencyContext } from "../../context/CurrencyContext";
import deleteExpense from "../../firebase/expense/deleteExpense";
import { db } from "../../firebase/index";
import "../css/InventoryGrid.scoped.css";
import DeleteConfirm from "../general/DeleteConfirm";
import EditExpenseModal from "./EditExpenseModal";
import _ from "lodash";
import translate from "../../i18n/translate";
const ExpensesGrid = ({ searchString, dark }) => {
  const [data, updateData] = useState([]);
  const [isEditModalOpen, updateIsEditModalOpen] = useState(false);
  const [editModalData, updateEditModalData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Expenses"),
      async (snapShot) => {
        let invData = [];
        if (searchString == "") {
          invData = snapShot.docs.map((e) => {
            return {
              ...e.data(),
              id: e.id,
            };
          });
        } else {
          let hits = await searchExpenses(searchString);

          invData = hits.map((e) => ({
            ...e,
            id: e.objectID.split("/")[1],
          }));

          snapShot.docChanges().forEach((change) => {
            const changedDoc = change.doc.data();
            const id = change.doc.id;

            if (change.type === "removed") {
              //let us remove from INVdata if this exists
              _.remove(invData, (k) => k.id === id);
            }

            if (change.type === "modified") {
              const exists = _.findIndex(invData, (k) => k.id === id);
              if (exists !== -1) {
                //This element was modified
                invData[exists] = {
                  ...changedDoc,
                  id: id,
                };
              }
            }
          });
        }

        updateData(invData);
      }
    );

    return () => {
      inventoryListener();
    };
  }, [searchString]);

  return (
    <div>
      <DeleteConfirm
        dark={dark}
        isOpen={deleteModal}
        toggleOpen={setDeleteModal}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        func={deleteExpense}
        msg={`item`}
      />

      <EditExpenseModal
        dark={dark}
        isOpen={isEditModalOpen}
        toggleState={updateIsEditModalOpen}
        data={editModalData}
      />
      <div className="headers-grid expense-grid">
        <div>{translate("name")}</div>
        <div>{translate("quantity")}</div>
        <div>Seat</div>
        <div>Date</div>
        <div>{translate("amountSpent")}</div>
        <div>{translate("spentDate")}</div>
        <div>Transaction ID</div>
        <div>{translate("notes")}</div>
        <div />
      </div>
      <div className="items-container">
        {data && (
          <div className="items">
            {data.map((e, i) => (
              <ItemGrid
                dark={dark}
                key={i}
                item={e}
                updateIsEditModalOpen={updateIsEditModalOpen}
                updateEditModalData={updateEditModalData}
                setDeleteModal={setDeleteModal}
                setDeleteId={setDeleteId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpensesGrid;

let ItemGrid = ({
  item,
  updateIsEditModalOpen,
  updateEditModalData,
  setDeleteModal,
  setDeleteId,
  dark,
}) => {
  const { rates, sign } = useContext(CurrencyContext);
  let localCurrency = localStorage.getItem("currency");
  const name =
    item._highlightResult && item._highlightResult.name
      ? item._highlightResult.name.value
      : item.name;
  return (
    <div className="item-grid expense-grid">
      <div className="name" dangerouslySetInnerHTML={{ __html: name }} />

      <div>{item.quantity}</div>
      <div>{item.seat ? item.seat : "-"}</div>
      <div>{item.date}</div>
      <div>
        {sign[localCurrency]}
        {item.amountSpent.toFixed(2)}
      </div>
      <div>{item.spentDate}</div>
      <div>{item.transactionId ? item.transactionId : "-"}</div>
      <div>{item.notes ? item.notes : "-"}</div>
      <div className="flex al-ce jc-ce">
        <svg
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateIsEditModalOpen(true);
            updateEditModalData({
              id: item.id,
              name: item.name.trim(),
              spentDate: item.spentDate,
              amountSpent: parseFloat(item.amountSpent.toFixed(2)),
              seat: item.seat,
              date: item.date,
              quantity: item.quantity,
              transactionId: item.transactionId.trim(),
              notes: item.notes.trim(),
            });
          }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.20229 0.390596C6.50512 0.101051 8.09379 0 10 0C11.9062 0 13.4949 0.101051 14.7977 0.390596C16.11 0.682244 17.1828 1.1761 18.0034 1.99663C18.8239 2.81716 19.3178 3.88999 19.6094 5.20229C19.8989 6.50512 20 8.09379 20 10C20 11.9062 19.8989 13.4949 19.6094 14.7977C19.3178 16.11 18.8239 17.1828 18.0034 18.0034C17.1828 18.8239 16.11 19.3178 14.7977 19.6094C13.4949 19.8989 11.9062 20 10 20C8.09379 20 6.50512 19.8989 5.20229 19.6094C3.88999 19.3178 2.81716 18.8239 1.99663 18.0034C1.1761 17.1828 0.682244 16.11 0.390596 14.7977C0.101051 13.4949 0 11.9062 0 10C0 8.09379 0.101051 6.50512 0.390596 5.20229C0.682244 3.88999 1.1761 2.81716 1.99663 1.99663C2.81716 1.1761 3.88999 0.682244 5.20229 0.390596ZM2.01757 5.56387C1.76785 6.68748 1.66667 8.13183 1.66667 10C1.66667 11.8682 1.76785 13.3125 2.01757 14.4361C2.26518 15.5503 2.6476 16.2973 3.17514 16.8249C3.70268 17.3524 4.44972 17.7348 5.56387 17.9824C6.68748 18.2321 8.13183 18.3333 10 18.3333C11.8682 18.3333 13.3125 18.2321 14.4361 17.9824C15.5503 17.7348 16.2973 17.3524 16.8249 16.8249C17.3524 16.2973 17.7348 15.5503 17.9824 14.4361C18.2321 13.3125 18.3333 11.8682 18.3333 10C18.3333 8.13183 18.2321 6.68748 17.9824 5.56387C17.7348 4.44972 17.3524 3.70268 16.8249 3.17514C16.2973 2.6476 15.5503 2.26518 14.4361 2.01757C13.3125 1.76785 11.8682 1.66667 10 1.66667C8.13183 1.66667 6.68748 1.76785 5.56387 2.01757C4.44972 2.26518 3.70268 2.6476 3.17514 3.17514C2.6476 3.70268 2.26518 4.44972 2.01757 5.56387Z"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0917 5.47408C10.6664 4.8714 11.6235 4.83977 12.2378 5.39966L14.5078 7.46831C15.1321 8.03731 15.1681 9.00242 14.5839 9.615L9.90829 14.518C9.61916 14.8212 9.21866 14.9905 8.80291 14.9921L6.67397 15C5.883 15.0029 5.19944 14.4009 5.14445 13.591L5.00344 11.5144C4.97478 11.0924 5.12631 10.681 5.41611 10.3771L10.0917 5.47408ZM11.2025 6.53847L10.5093 7.26544L12.7583 9.30015L13.4444 8.58069L11.2034 6.53847L11.2025 6.53847ZM11.6962 10.4139L9.4472 8.37919L6.53959 11.4282L6.67766 13.4615L8.79719 13.4536L11.6962 10.4139Z"
            fill="#767BFA"
          />
        </svg>

        <svg
          onClick={() => {
            setDeleteModal(true);
            setDeleteId(item.id);
          }}
          className="ml-10"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.15298 6.92168C7.81296 6.58166 7.26168 6.58166 6.92166 6.92168C6.58164 7.2617 6.58164 7.81298 6.92166 8.15299L8.76868 9.99999L6.92164 11.847C6.58162 12.187 6.58162 12.7383 6.92164 13.0783C7.26166 13.4183 7.81294 13.4183 8.15296 13.0783L10 11.2313L11.847 13.0782C12.187 13.4183 12.7383 13.4183 13.0783 13.0782C13.4183 12.7382 13.4183 12.1869 13.0783 11.8469L11.2313 9.99999L13.0783 8.15307C13.4183 7.81306 13.4183 7.26178 13.0783 6.92176C12.7382 6.58174 12.187 6.58174 11.8469 6.92176L10 8.76868L8.15298 6.92168Z"
            fill="#FA7676"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 10C0 18.235 1.765 20 10 20C18.235 20 20 18.235 20 10C20 1.765 18.235 0 10 0C1.765 0 0 1.765 0 10ZM1.66667 10C1.66667 12.0207 1.77712 13.5308 2.03142 14.6751C2.28153 15.8004 2.64807 16.4673 3.09039 16.9096C3.5327 17.3519 4.19958 17.7185 5.32494 17.9686C6.46923 18.2229 7.97929 18.3333 10 18.3333C12.0207 18.3333 13.5308 18.2229 14.6751 17.9686C15.8004 17.7185 16.4673 17.3519 16.9096 16.9096C17.3519 16.4673 17.7185 15.8004 17.9686 14.6751C18.2229 13.5308 18.3333 12.0207 18.3333 10C18.3333 7.97929 18.2229 6.46923 17.9686 5.32494C17.7185 4.19958 17.3519 3.5327 16.9096 3.09039C16.4673 2.64807 15.8004 2.28153 14.6751 2.03142C13.5308 1.77712 12.0207 1.66667 10 1.66667C7.97929 1.66667 6.46923 1.77712 5.32494 2.03142C4.19958 2.28153 3.5327 2.64807 3.09039 3.09039C2.64807 3.5327 2.28153 4.19958 2.03142 5.32494C1.77712 6.46923 1.66667 7.97929 1.66667 10Z"
            fill={`${!dark ? "#ABAFD1" : "#5A5D74"}`}
          />
        </svg>
      </div>
    </div>
  );
};
