import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";
import Add from "../../assets/add.svg";
import AddDark from "../../assets/addark.svg";
import Close from "../../assets/close.svg";
import { StoreId } from "../../Constants";
import editDiscount from "../../firebase/discounts/editDiscount";
import "../css/AddConsignmentModal.scoped.css";
import Button from "../general/Button";
import Input from "../general/Input";
import Loader from "../general/Loader";
import SelectLabels from "../general/Select";
import { ErrorToast, SuccessToast } from "../general/ToastNotification";
import CloseDark from "../../assets/closedark.svg";
import translate from "../../i18n/translate";
import GetTranslateString from "../../i18n/translateString";

export default function EditCode({
  isOpen,
  toggleState,
  data,
  updateEditModalData,
  dark,
}) {
  const [fields, setFields] = useState(data);
  const [isDisabled, updateDisabled] = useState(false);
  const [loading, toggleLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "32.25rem",
    width: "100%",
    border: "none",
    bgcolor: dark ? "#323A52" : "#F6F7FF",
    boxShadow: "0rem 0rem 0.625rem rgba(0, 0, 0, 0.15)",
    borderRadius: "0.9375rem",
    outline: "none",
    padding: "1.25rem 0",
    color: !dark ? "#323A52" : "#F6F7FF",
  };

  useEffect(() => {
    setFields(data);
  }, [data]);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Modal
        className={`modal ${dark ? "dark" : ""}`}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => {
          toggleState(false);
          updateEditModalData({});
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="header">
              <div>Editing Discount Code...</div>
              <img
                onClick={() => {
                  toggleState(false);
                  updateEditModalData({});
                }}
                src={dark ? CloseDark : Close}
                alt=""
              />
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">{translate("codeName")}</div>
                <Input
                  readOnly
                  name="discountCode"
                  type={"text"}
                  value={fields?.discountCode}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterName")}
                />
              </div>

              <div>
                <div className="label">Stocks</div>
                <Input
                  name="stocks"
                  type={"text"}
                  value={fields.stocks}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterStocks")}
                />
              </div>
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">Type</div>
                <SelectLabels
                  dark={dark}
                  name="type"
                  value={fields.type}
                  render={() =>
                    fields.type && fields.type.length > 18
                      ? fields.type.substring(0, 18) + "..."
                      : fields.type
                  }
                  updateValue={handleChange}
                  options={[
                    {
                      label: "On All Products",
                      value: "On All Products",
                    },
                    {
                      label: "On Specific Categories",
                      value: "On Specific Categories",
                    },
                    {
                      label: "On Specific Products",
                      value: "On Specific Products",
                    },
                  ]}
                  placeholderText={GetTranslateString("choose")}
                  none={false}
                />
              </div>

              <div>
                <div className="label">Type (Value %)</div>
                <Input
                  name="discountPercentage"
                  type={"number"}
                  value={fields.discountPercentage}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterPercentage")}
                />
              </div>
            </div>

            <div className="row">
              <div className="label">Specific Product</div>
              <SelectLabels
                dark={dark}
                name="specificProduct"
                value={fields.specificProduct}
                updateValue={handleChange}
                options={[
                  {
                    label: "N/A",
                    value: "NA",
                  },
                ]}
                placeholderText={GetTranslateString("chooseProducts")}
                none={false}
              />
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">Requirements</div>
                <Input
                  name="requirements"
                  type={"text"}
                  value={fields.requirements}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterReq")}
                />
              </div>

              <div>
                <div className="label">Requirements ($)</div>
                <Input
                  name="requirementsAmt"
                  type={"number"}
                  value={fields.requirementsAmt}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterAmount")}
                />
              </div>
            </div>

            <div className="row">
              <div>
                <div className="label">Eligibility</div>
                <SelectLabels
                  dark={dark}
                  name="eligibility"
                  value={fields.eligibility}
                  updateValue={handleChange}
                  options={[
                    {
                      label: "one per customer",
                      value: "one per customer",
                    },
                    {
                      label: "two per customer",
                      value: "two per customer",
                    },
                    {
                      label: "three per customer",
                      value: "three per customer",
                    },
                  ]}
                  placeholderText={GetTranslateString("chooseEligibility")}
                  none={false}
                />
              </div>
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">Start Date</div>
                <Input
                  name="startDate"
                  type={"date"}
                  dark={dark}
                  value={fields.startDate}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterStartDate")}
                />
              </div>

              <div>
                <div className="label">End Date</div>
                <Input
                  name="endDate"
                  type={"date"}
                  dark={dark}
                  value={fields.endDate}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEndDate")}
                />
              </div>
            </div>

            <div className="two-grid row">
              <div>
                <div className="label">{translate("startTime")}</div>
                <Input
                  name="startTime"
                  type={"time"}
                  dark={dark}
                  value={fields.startTime}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterStartTime")}
                />
              </div>

              <div>
                <div className="label">{translate("endTime")}</div>
                <Input
                  name="endTime"
                  dark={dark}
                  type={"time"}
                  value={fields.endTime}
                  updateValue={handleChange}
                  placeholder={GetTranslateString("enterEndTime")}
                />
              </div>
            </div>

            <div className="btns w-full jus-end mt-10">
              <div>
                <Loader loading={loading} />
              </div>
              <div className="ml-10">
                <Button
                  disabled={isDisabled}
                  imgSrc={<img src={dark ? AddDark : Add} alt="add" />}
                  onClick={async () => {
                    updateDisabled(true);
                    try {
                      toggleLoading(true);
                      await editDiscount(StoreId, fields.discountCode.trim(), {
                        ...fields,
                        type: fields.type.toLowerCase(),
                        discountPercentage: parseInt(fields.discountPercentage),
                        date: Date.now(),
                        eligibility: fields.eligibility.trim(),
                        requirements: fields.requirements.trim().toLowerCase(),
                        status: "Active",
                        used: 0,
                        value:
                          fields.discountPercentage.toString() +
                          "% " +
                          fields.type,
                      });
                    } catch (e) {
                      ErrorToast(translate("error"), e.message);
                      toggleLoading(false);
                      updateDisabled(false);
                      return;
                    }
                    SuccessToast(translate("success"), translate("codeEdited"));
                    toggleState(false);
                    updateDisabled(false);
                    toggleLoading(false);
                  }}
                  text={translate("save")}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
