import React, { useEffect, useState } from "react";
import uploadTicket from "../../functions/tickets/uploadTicket";
import "../css/Modal.scoped.css";
import { ErrorToast } from "../general/ToastNotification";
import PDFViewer from "../inventory/PDFViewer";
import edit from "../../assets/edit.svg";

export default function AddTicketDetailsGrid({
  dark,
  data,
  uploads,
  setUploads,
  max,
  loading,
  toggleLoading,
}) {
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  // console.log("in rows", data);
  let token = localStorage.getItem("token");
  const handleUpload = async (index, seatNumber) => {
    try {
      if (!loading) {
        let newUploads = uploads;
        toggleLoading(true);
        let [fileHandle] = await window.showOpenFilePicker({
          types: [
            {
              description: "Ticket PDF",
              accept: {
                "application/pdf": [".pdf"],
              },
            },
          ],
          excludeAcceptAllOption: true,
          multiple: false,
        });
        toggleLoading(false);
        const fileData = await fileHandle.getFile();

        if (fileData.size > 800 * 1024) {
          console.log("large file");
          return ErrorToast(
            "Upload Error",
            "Size of the pdf should be under 800KB"
          );
        }

        toggleLoading(true);
        const path = await uploadTicket(token, fileData, seatNumber);

        newUploads[index] = {
          file: fileData,
          path,
        };
        setUploads([...newUploads]);
        toggleLoading(false);
      }
    } catch (e) {
      console.log(e);
      toggleLoading(false);
      if (e.name !== "AbortError") {
        return ErrorToast(
          "Upload Error",
          "Some error occurred while uploading"
        );
      }
    }
  };

  return (
    <div>
      <PDFViewer
        dark={dark}
        isOpen={pdfOpen}
        toggleState={setPdfOpen}
        setData={setPdfData}
        data={pdfData}
      />
      <div className="border" />
      <div className="add-ticket-details-grid">
        <div className="headersGrid">
          <div>No.</div>
          <div>Section</div>
          <div>Row</div>
          <div>Seat/Queue No.</div>
          {/* <div>Platform Price (USD)</div>
        <div>Payout (USD)</div> */}
          <div />
        </div>

        <div className="bodyGrid">
          {data.slice(0, max).map((e, i) => (
            <div className="values-grid" key={i}>
              <div>{i + 1}</div>
              <div>{e?.section}</div>
              <div>{e?.row}</div>
              <div>
                {e?.seatType == "Standing"
                  ? "-"
                  : parseInt(e?.startSeat || 0) + i}
              </div>
              {/* <div>{e?.platformPrice}</div>
            <div>{e?.payoutPrice}</div> */}
              <div className="uploaded">
                <div
                  onClick={async () => {
                    try {
                      if (uploads[i]?.file?.name) {
                        console.log(uploads[i].file);
                        setPdfData(uploads[i].file);
                        setPdfOpen(true);
                        return;
                      }
                      handleUpload(i, parseInt(e?.startSeat || 0) + i);
                    } catch (e) {
                      console.log(e);
                      toggleLoading(false);
                      ErrorToast("Error", e.message);
                    } finally {
                      toggleLoading(false);
                    }
                  }}
                  className={"blue-btn"}
                >
                  {uploads[i]?.file
                    ? uploads[i].file.name
                    : `Upload Ticket ${i + 1}`}
                </div>
                {uploads[i]?.file && (
                  <img
                    src={edit}
                    alt={"edit"}
                    onClick={async () => {
                      try {
                        handleUpload(i, parseInt(e?.startSeat || 0) + i);
                      } catch (e) {
                        console.log(e);
                        toggleLoading(false);
                        ErrorToast("Error", e.message);
                      } finally {
                        toggleLoading(false);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
