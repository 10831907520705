import {
  deleteDoc,
  doc,
  getDoc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";

export default async function deleteExpense(storeId, expenseId) {
  const docRef = doc(db, "Stores", storeId, "Expenses", expenseId);
  const docSnap = await getDoc(docRef);
  const docData = docSnap.data();

  const counterRef = doc(db, "Stores", storeId);

  const incrementCount = increment(-1);
  const incrementValue = increment(
    (docData.amountSpent * (docData.tax / 100) +
      docData.amountSpent +
      docData.shipping) *
      -1
  );

  await deleteDoc(docRef);
  await updateDoc(counterRef, {
    expenseItems: incrementCount,
    expenseValue: incrementValue,
  });
}
