import React from "react";
import CustomCheckbox from "../general/Checkbox";

export default function CheckboxWithLabel({
  checked,
  handleChange,
  label,
  checkedIcon,
  icon,
}) {
  return (
    <div
      className="flex al-ce"
      style={{ cursor: "pointer", width: "max-content" }}
      onClick={handleChange}
    >
      <CustomCheckbox checkedIcon={checkedIcon} icon={icon} checked={checked} />
      <div>{label}</div>
    </div>
  );
}
