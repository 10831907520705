import { setDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../index";

export default async function clockingIn(storeId, staffId, email, date) {
  const staffRef = doc(
    db,
    "Stores",
    storeId,
    "Staff",
    "Staffs",
    "Accounts",
    staffId
  );
  const staffSnap = await getDoc(staffRef);

  if (!staffId || !staffSnap.exists()) {
    throw new Error("Invalid Staff Id");
  }

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!email.match(validRegex)) {
    throw new Error("Email is not a valid value.");
  }

  const key = date + staffId;
  await setDoc(doc(db, "Stores", storeId, "Staff", "Shifts", "Logs", key), {
    staffId: staffId,
    email: email,
    date: Date.now(),
    clockingIn: Date.now(),
    totalTime: 0,
  });

  //update last clock in clock out
  const updater = {
    clockingIn: Date.now(),
    clockingOut: "",
  };

  await updateDoc(
    doc(db, "Stores", storeId, "Staff", "Staffs", "Accounts", staffId),
    updater,
    { merge: true }
  );
}
