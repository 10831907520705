import {
  addDoc,
  collection,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../index";
export default async function createExpense(storeId, data) {
  //data.name
  if (!data.name || data.name.length < 3) {
    throw new Error("Name is not a valid value.");
  }

  if (!data.spentDate) {
    throw new Error("Spent Date is not a valid value.");
  }

  if (!data.amountSpent || isNaN(data.amountSpent) || data.amountSpent < 0) {
    throw new Error("Amount spend is not valid");
  }

  if (!data.quantity) {
    throw new Error("Please provide quantity");
  }
  if (data.quantity) {
    if (isNaN(data.quantity) || data.quantity < 0) {
      throw new Error("Invalid quantity value.");
    }
  }

  if (!data.date) {
    throw new Error("Spent Date is not a valid value.");
  }

  if (isNaN(Date.parse(data.date))) {
    throw new Error("Not a valid date");
  }

  if (new Date(data.date).getTime() < new Date("1999-12-31").getTime()) {
    throw new Error("Not a valid date");
  }

  if (isNaN(Date.parse(data.spentDate))) {
    throw new Error("Not a valid spent date");
  }

  if (new Date(data.spentDate).getTime() < new Date("1999-12-31").getTime()) {
    throw new Error("Not a valid spent date");
  }

  await addDoc(collection(db, "Stores", storeId, "Expenses"), {
    ...data,
    storeId: storeId,
  });

  const counterRef = doc(db, "Stores", storeId);

  const incrementCount = increment(1);
  const incrementValue = increment(data.amountSpent);
  await updateDoc(counterRef, {
    expenseItems: incrementCount,
    expenseValue: incrementValue,
  });
}

// const expense = {
//     name: "abc",
//     spentDate: "",
//     amountSpent: 125,
//     tax: "",
//     shipping: "",
//     duration: "",
//     quantity: 1,
//     transactionId: 123456789,
//     notes: "",
// }
