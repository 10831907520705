import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { StoreId } from "../../Constants";
import { db } from "../../firebase/index";
import "../css/ConsignmentSalesTable.scoped.css";
import redClose from "../../assets/red-close.svg";
import editImg from "../../assets/edit.svg";
import deleteDiscount from "../../firebase/discounts/deleteDiscount";
import DeleteConfirm from "../general/DeleteConfirm";
import EditCode from "../dashOwner/EditCode";
import { getTotalMillis, timeToMillis } from "../utils/checkStatus";
import discountStatus from "../utils/discountStatus";
import translate from "../../i18n/translate";

const DiscountSummary = ({ type, del, search, edit, dark }) => {
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editData, setEditData] = useState({});

  useEffect(() => {
    const inventoryListener = onSnapshot(
      collection(db, "Stores", StoreId, "Discounts"),
      async (snapShot) => {
        const conData = snapShot.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        });
        setData(conData);
        setDisplayData(conData);
      }
    );
    return () => {
      inventoryListener();
    };
  }, []);

  useEffect(() => {
    if (type === "All") {
      setDisplayData(data);
    } else if (type === "Expired") {
      setDisplayData(data.filter((e) => discountStatus(e) === "Expired"));
    } else if (type === "Scheduled") {
      setDisplayData(data.filter((e) => discountStatus(e) === "Scheduled"));
    } else {
      setDisplayData(data.filter((e) => discountStatus(e) === "Active"));
    }
  }, [type]);

  useEffect(() => {
    setDisplayData(
      data?.filter(
        (e) => e.id.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    );
  }, [search]);

  return (
    <>
      <DeleteConfirm
        dark={dark}
        isOpen={deleteOpen}
        toggleOpen={setDeleteOpen}
        msg={"Discount Code"}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        func={deleteDiscount}
      />
      {editData !== {} && (
        <EditCode
          dark={dark}
          isOpen={editOpen}
          toggleState={setEditOpen}
          data={editData ? editData : {}}
          updateEditModalData={setEditData}
        />
      )}
      <div
        className={`headersgrid discount-summary-header ${
          del ? "del-headers" : ""
        }`}
      >
        <div>{translate("code")}</div>
        <div>{translate("value")}</div>
        <div>{translate("requirements")}</div>
        <div>{translate("eligibility")}</div>
        <div>{translate("date")}</div>
        <div>{translate("used")}</div>
        <div>{translate("status")}</div>
        {del && <div />}
      </div>
      <>
        <div className="items-container discount-summary">
          <div className={`items ${del ? "del-items" : ""}`}>
            {displayData.map((e, i) => {
              let getDate = (date) => {
                let newDate = new Date(date);

                let dateString = newDate
                  .toDateString()
                  .split(" ")
                  .slice(1, 4)
                  .join(" ");

                var hours = newDate.getHours();
                var minutes = newDate.getMinutes();
                var ampm = hours >= 12 ? "pm" : "am";
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? "0" + minutes : minutes;
                var strTime = hours + ":" + minutes + " " + ampm;
                return `${dateString}, ${strTime}`;
              };

              let status = discountStatus(e);

              return (
                <div className="item" key={i}>
                  <div>{e.id}</div>
                  <div>
                    {e.discountPercentage}% {e.type?.toLowerCase()}
                  </div>
                  <div>
                    {e.requirements}{" "}
                    {e.requirementsAmt ? e.requirementsAmt + " USD" : ""}
                  </div>
                  <div>{e.eligibility}</div>
                  <div className="cap">{getDate(e.date)}</div>
                  <div>{e.used}</div>
                  <div className={status}>{status}</div>
                  {edit && (
                    <img
                      onClick={() => {
                        setEditData(e);
                        setEditOpen(true);
                      }}
                      src={editImg}
                      className="del-icon"
                      alt="red-close"
                    />
                  )}
                  {del && (
                    <img
                      onClick={() => {
                        setDeleteId(e.id);
                        setDeleteOpen(true);
                      }}
                      src={redClose}
                      className="del-icon"
                      alt="red-close"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </>
    </>
  );
};

export default DiscountSummary;
