import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../index";

export default async function updateExpenseCount(storeId) {
  const q = collection(db, "Stores", storeId, "Expenses");
  const querySnapshot = await getDocs(q);
  let expenseItems = 0;
  let expenseValue = 0;
  querySnapshot.forEach((doc) => {
    const docData = doc.data();
    expenseItems++;
    expenseValue += (docData.amountSpent * (docData.tax / 100) + docData.amountSpent + docData.shipping);
  });

  const docRef = doc(db, "Stores", storeId);
  const result = {
    expenseItems: expenseItems,
    expenseValue: expenseValue,
  };
  updateDoc(docRef, result);
  return result;
}
