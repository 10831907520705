import "../css/Loader.scoped.css";
import LoaderSvg from "../../assets/loader.svg";
const Loader = ({ loading }) => {
  return (
    loading && (
      <div className={`loader ${loading ? "loading" : ""}`}>
        <img src={LoaderSvg} alt="" />
      </div>
    )
  );
};

export default Loader;
