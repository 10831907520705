import { LOCALES } from "../locales";

let zh = {
  [LOCALES.CHINESE]: {
    hello: "你好",
    owner: "业主",
    country: "国家",
    storeName: "商店名称",
    storeId: "商店ID",
    email: "电子邮件",
    dateJoined: "加入日期",
    categories: "类别",
    profitsLosses: "利润/亏损",
    totalExpenses: "总支出",
    totalInventory: "库存总量",
    discountSummary: "折扣摘要",
    staffSummary: "工作人员摘要",
    staffDetails: "工作人员摘要",
    workingPerformance: "工作业绩",
    clockInAndOutDetails: "时钟输入和输出细节",
    storeInformations: "商店信息",
    staffs: "职员",
    dashboard: "仪表板",
    marketLookUp: "市场看涨",
    inventory: "库存",
    productLabels: "产品标签",
    consignment: "寄售",
    sales: "销售",
    expenses: "支出",
    settings: "设置",
    marketSales: "市场销售",
    total: "共计",
    name: "命名",
    enterName: "输入名称",
    enterId: "输入ID",
    enterDate: "输入日期",
    enterAddress: "输入地址",
    enterCountry: "输入国家",
    enterCity: "输入城市",
    enterEmail: "输入电子邮件",
    enterContactNo: "输入联系号码.",
    enterStyleCode: "输入风格代码",
    enterAmount: "输入金额",
    enterStocks: "输入股票",
    enterPercentage: "进入 %",
    enterReq: "输入要求",
    enterStartDate: "输入开始日期",
    enterEndDate: "输入结束日期",
    enterCode: "输入代码",
    enterStartTime: "输入开始时间",
    enterEndTime: "输入结束时间",
    autoDetect: "自动检测",
    enterLast4Digits: "输入最后4位数字",
    enterNumber: "输入数字",
    enterIfInstore: "进入 - 如果在店内",
    shippingInfo: "运输信息",
    enterInfo: "输入信息",
    enterNotes: "输入注释",
    enterTitle: "输入标题",
    enterUrl: "输入网址",
    id: "身份证",
    totalSales: "销售总额",
    transactionHistory: "交易历史",
    date: "日期",
    delete: "删除",
    items: "项目",
    price: "价格",
    requirements: "要求",
    eligibility: "资格认证",
    status: "状况",
    used: "二手",
    date: "日期",
    code: "编码",
    value: "价值",
    clockedInTime: "签到时间",
    clockedOutTime: "打卡时间",
    totalHours: "总小时数",
    numberOfSales: "销售数量",
    figures: "数字",
    timeIn: "时间在",
    timeOut: "暂停营业",
    staffName: "工作人员姓名",
    staffId: "工作人员身份",
    totalFigures: "总数字",
    totalNumberOfSales: "销售总数",
    workingPeriodAssigned: "分配的工作时间",
    dailyWorkingHours: "每日工作时间",
    weeklyWorkingHours: "每周工作时间",
    monthlyWorkingHours: "每月工作时间",
    yearlyWorkingHours: "每年的工作时间",
    website: "网站",
    stockx: "证券交易所",
    priceHistory: "价格历史",
    productDetails: "产品细节",
    viewMarketSales: "查看市场销售情况",
    market: "市场",
    comparison: "比较",
    productInfo: "产品信息",
    oxStreet: "奥克斯街",
    newSiteComingSoon: "即将推出的新网站",
    comparing: "比较",
    style: "风格",
    size: "尺寸",
    condition: "状况",
    purchasedPrice: "购买价格",
    purchasedDate: "购买日期",
    platform: "平台",
    lastSale: "最后一次销售",
    markUp: "标记",
    finalPrice: "最终价格",
    unrealized: "未实现的",
    totalValue: "总价值",
    summary: "摘要",
    totalItems: "项目总数",
    totalUnrealizedProfit: "未实现的利润总额",
    inventoryTrackingCode: "库存跟踪代码",
    qrCode: "二维码",
    qrCodeSettings: "二维码设置",
    footerBackground: "页脚背景",
    logo: "徽标",
    background: "背景介绍",
    accentColour: "重点颜色",
    boxBackgroundColor: "箱体背景颜色",
    fontColor: "字体颜色",
    sizing: "尺寸",
    currency: "货币",
    save: "拯救",
    barcodeTags: "条形码标签",
    lastScannedDate: "最后一次扫描日期",
    scanned: "扫描的",
    sold: "已售",
    resetScan: "重置扫描",
    missingItem: "缺少的项目",
    scanItem: "扫描项目",
    search: "搜索",
    refresh: "刷新",
    printAll: "打印所有",
    default: "默认情况下",
    totalConsignmentSales: "托运销售总额",
    consignmentSalesSummary: "托运销售总结",
    consignmentPopularCategories: "寄售 热门类别",
    consigneeOverviews: "收货人概述",
    contactNo: "联系号码.",
    city: "城市",
    payoutDate: "赔付日期",
    payoutPrice: "赔付价格",
    dateIn: "日期在",
    listingPrice: "挂牌价",
    fee: "费用",
    consigneeId: "收货人ID",
    itemName: "项目名称", 
    firstNameAndLastName: "名字和姓氏",
    codeName: "代码名称",
    notes: "笔记",
    spentDate: "支出日期",
    amountSpent: "花费的金额",
    duration: "时间",
    shipping: "运输",
    tax: "税收",
    quantity: "数量",
    expenseName: "支出名称",
    customerName: "客户名称",
    staffNavigationBarSettings: "工作人员导航栏设置",
    mode: "模式",
    specificStaffPermission: "特定工作人员的许可",
    generalStaffsPermissions: "一般工作人员的权限",
    material1: "材料1",
    material2: "材料2",
    salesSummary: "销售摘要",
    growth: "增长",
    pos: "终端机",
    receiptSettings: "收据设置",
    purchase: "购买",
    preview: "预览",
    cart: "頧行",
    orderId: "订单编号",
    sku: "价值链",
    searchsku: "搜索",
    item: "项目",
    totalPayout: "赔付总额",
    totalProfit: "利润总额",
    customerSideScreen: "客户端屏幕",
    addDiscountCode: "添加折扣代码",
    discountAmount: "折扣金额",
    subTotal: "小计",
    discount: "折扣",
    saleTax: "销售税",
    thankyouForYourPurchase: "感谢你的购买",
    welcomeBack: "欢迎回来",
    customerViewingScreenSettings: "客户查看屏幕设置",
    dashboardStoreInformationHideCreateEditDeleteDiscountCodeButton: "仪表板 - 商店信息 - 隐藏创建/编辑/删除折扣代码按钮",
    inventoryHideImportButton: "库存 - 隐藏导入按钮",
    inventoryHideDownloadButton: "库存 - 隐藏下载按钮",
    inventoryHideAddNewEditDeleteButton: "库存 - 隐藏添加新的/编辑/删除的按钮",
    productLabelsHideqrCodeSettingsPage: "产品标签 - 隐藏QR码设置页面",
    consignmentsSummaryHidePayoutConfirmationButton: "托运货物 - 摘要 - 隐藏确认付款的按钮",
    consignmentsConsigneeHideAddNewEditDeleteButton: "托运 - 收货人 - 隐藏添加新的/编辑/删除的按钮",
    consignmentsInventoryHideAddNewEditDeleteButton: "托运 - 库存 - 隐藏添加新的/编辑/删除的按钮",
    salesHideRecieptSettingsPage: "销售 - 隐藏预约设置页面",
    hideExpensesPage: "隐藏支出页面",
    title: "标题",
    saveItems: "保存项目",
    settingOptions: "设置选项",
    addNew: "添加新的",
    enterCode: "输入代码",
    pay: "缴费",
    addToCart: "添加到购物车",
    import: "进口",
    data: "数据",
    searchSku: "搜索SKU",
    recommendedSize: "推荐尺寸",
    lastSalePrice: "最后销售价格",
    currency: "货币",
    retailPrice: "零售价格",
    language: "语言",
    pricePremium: "价格溢价",
    addingNewConsignmentItems: "添加新的寄售物品...",
    editingConsignmentItems: "编辑寄售物品...",
    consignmentSalesDetails: "委托销售详情",
    soldDate: "售出日期",
    soldBy: "销售方式",
    sizes: "尺寸",
    bestSizes: "最佳尺寸",
    dateJoin: "加入日期",
    startTime: "开始时间",
    endTime: "结束时间",
    addingNewConsignee: "添加新的收货人......",
    totalStores: "商店总数",
    staffOverviews: "工作人员概述",
    edit: "编辑",
    addStaff: "添加工作人员",
    inLastMonth: "在上个月",
    logOut: "登出",
    itemsSold: "出售的物品",
    stockTags: "股票标签",
    daily: "每日",
    weekly: "每周",
    monthly: "月度",
    yearly: "每年一次",
    active: "活跃",
    onLeave: "休假",
    resigned: "辞职了",
    addingNewStaff: "增加新员工...",
    monday: "星期一",
    tuesday: "星期二",
    wednesday: "星期三",
    thursday: "星期四",
    friday: "星期五",
    saturday: "星期六",
    sunday: "星期日",
    creatingDiscountCode: "创建折扣代码",
    type: "类型",
    stocks: "股票",
    specificproduct: "具体产品",
    eligibility: "资格认证",
    startDate: "开始日期",
    endDate: "结束日期",
    startTime: "开始时间",
    endTime: "结束时间",
    onePerCustomer: "每个客户一个",
    twoPerCustomer: "每个客户两个",
    threePerCustomer: "每个客户三个",
    onAllProducts: "关于所有产品",
    onSpecificCategories: "关于具体类别",
    onSpecificProducts: "关于具体产品",
    carousell: "淘宝网",
    goat: "山羊",
    sneakers: "运动鞋",
    collectibles: "收藏品",
    arts: "艺术",
    streetwears: "街头服饰",
    addingNewInventoryItems: "添加新的存货项目",
    custom: "定制",
    editingItem: "编辑项目",
    address: "地址",
    contact: "联系我们",
    firstAndLastName: "名字和姓氏",
    editingConsignee: "编辑收货人",
    customerPreviews: "客户预览",
    recommendedProducts: "推荐产品",
    findOutMore: "了解更多",
    editingExpenseItems: "编辑开支项目",
    transactionId: "交易ID",
    oneTime: "一次性",
    light: "亮度",
    dark: "黑暗",
    popularCategories: "热门类别",
    paymentMethod: "支付方式",
    payout: "赔款",
    trending: "趋势",
    clearAll: "清除所有",
    editItem: "编辑项目",
    editStore: "编辑商店",
    success: "成功",
    consigneeCreated: "收货人创建.",
    error: "误差",
    itemEdited:"已编辑的项目.",                         
    itemCreated:"项目创建.",
    clockedIn:"签到.",
    clockedOut:"打烊了.",
    storeLockedSuccessfully:"商店成功锁定.",
    storeUnlockedSuccessfully:"商店成功解锁.",
    storeEdited:"已编辑的商店.",
    storeCreated:"商店创建.",
    discountCodeCreated:"折扣代码创建.",
    staffAdded:"工作人员增加",
    codeEdited:"编辑过的代码.",
    staffEdited:"编辑的工作人员",
    expenseCreated:"创建的费用.",
    logoUpdated:"徽标更新",
    defaultSettings:"默认设置.",
    updated:"更新",
    customSettings:"自定义设置.",
    detailsUpdated:"细节更新",
    verified:"已验证",
    discountApplied:"适用的折扣.",
    failed:"失败",
    invalidDiscountCode:"无效的折扣代码.",
    paymentSuccessful:"支付成功.",
    successfullyUpdated: "已成功更新",
    settingsSaved: "保存的设置.",
    alreadyClockedIn: "已经打卡了.",
    alreadyClockedOut: "已经打卡下班了.",
    pleaseSelectAStore: "请选择一个商店.",
    invalidDiscountCode: "无效的折扣代码.",
    cartCannotBeEmpty: "购物车不能是空的.",
    emptyDiscountField: "空的折扣字段.",
    cartempty : "购物车为空.",
    paymentUnsuccessful: "付款不成功",
    addToCartFailed: "添加到购物车失败",
    storeDoesNotExist: "商店不存在",
    selectAStaff: "选择一个工作人员.",
    pleaseSelectAStaff: "请选择一个工作人员.",
    pleaseTickCheckbox: "请勾选复选框.",
    vacantInput: "空缺的输入.",
    consignee: "收货人",
    salesDetails: "销售详情",
    fulfilled: "已完成",
    editingDiscountCode: "编辑折扣码",
    editingStaff: "编辑人员",
    editingStore: "编辑商店",
    download: "下载",
    inCart: "在购物车中",
    choose: "选择",
    chooseSize: "选择尺寸",
    chooseCategories: "选择类别",
    choosePlatform: "选择平台",
    chooseCondition: "选择条件",
    chooseCurrency: "选择货币",
    chooseMethod: "选择方法",
    chooseLanguage: "选择语言",
    chooseProducts: "选择产品",
    chooseEligibility: "选择资格",
    chooseDuration: "选择持续时间",
    chooseMode: "选择模式",
    selectColour: "选择颜色",
    all: "全部",
    expired: "已到期",
    scheduled: "预定的",
    createCode: "创建代码",
    highestBid: "最高出价",
    lowestAsk: "最低要价",
    missingItems: "丢失物品",
    print: "打印",
    consigneesSummary: "收货人汇总",
    editingConsignee : "编辑收货人",
    dateSold: "出售日期",
    addingNewExpense: "添加新费用",
    details: "细节",
    verify: "证实",
    staff: "职员",
    time: "时间",
    oneMonth: "1个月",
    threeMonths: "3个月",
    sixMonths: "6个月",
    marketIdentifier: "市场标识符",
    new: "新的",
    confirmingPayment: "Confirming Payment",
    customerEmail: "确认付款",
    cash: "现金",
    cheque: "支票",
    creditCard: "信用卡",
    payNow: "现在付款",
    ccLast4Digits: "抄送最后 4 位数字",
    amountReceived: "收到的金额",
    change: "改变",
    inStore: "有存货",
    delivery: "交货",
    shippingAddress: "收件地址",
    ofTotal: "总计",
    deleting: "删除",
    confirmToDeleteThis: "确认删除此",
    yesConfirm: "是的，确认",
    noThinkAgain: "不，再想一想",
    puchasedBy: "购买者",
  },
};

export default zh;
