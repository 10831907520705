import { doc, updateDoc } from "firebase/firestore";
import { db } from "../index";

export default async function editStaffStatus(storeId, staffId, status) {

  return updateDoc(
    doc(db, "Stores", storeId, "Staff", "Staffs", "Accounts", staffId),
    {
      status: status,
    },
    { merge: true }
  );
}
