import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDQeL7-jgMpwOzC-yK3PzRgzsH6HCNduJA",
  authDomain: "ingenium-337101.firebaseapp.com",
  projectId: "ingenium-337101",
  storageBucket: "ingenium-337101.appspot.com",
  messagingSenderId: "345079285560",
  appId: "1:345079285560:web:eadd1b96cf4c500055a3cd",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export async function test() {
  //await createProduct("zrtaw5PD9yuOv7AVXXPk", "555088-063")
  // await deleteInventory("zrtaw5PD9yuOv7AVXXPk", "4SL65Qro6mXuu3gjbAGX")
  // await createInventory("zrtaw5PD9yuOv7AVXXPk", "4SL65Qro6mXuu3gRITA", "555088-070")
  // await batchCreateInventory("zrtaw5PD9yuOv7AVXXPk", "4SL65Qro6mXuu3gRITA", data)
  //await deleteProduct("zrtaw5PD9yuOv7AVXXPk", "555088-069")
}
